import React from "react";
import styled from "styled-components";
import ArrowBack from "../../assets/iconography/Back-arrow.svg";

const StepTabs = styled.article`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  max-width: 250px;

  .back-button {
    cursor: pointer;
  }

  .tab {
    border-radius: 4px;
    background-color: lightgray;
    cursor: pointer;
    margin: 1rem 0;
    height: 5px;
    width: 30px;

    &.active {
      background-color: black;
    }
  }
`;

const StepDisplay = ({ step, totalSteps, setStep }) => {
  const tab = () => {
    const tabs = [];
    for (let i = 0; i <= totalSteps; i++) {
      tabs.push(
        <div
          key={i}
          className={step === i ? "tab active" : "tab"}
          onClick={step >= i ? () => setStep(i) : null}
        />
      );
    }
    return tabs;
  };

  return (
    <StepTabs>
      {step ? (
        <img
          className="back-button"
          src={ArrowBack}
          alt="back"
          onClick={() => setStep(step - 1)}
        />
      ) : null}
      {tab()}
    </StepTabs>
  );
};

export default StepDisplay;
