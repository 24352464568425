import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
// layout
import { colors } from "../layout/constants";
import ActionButton from "../layout/shared/actionButton";

const ChangePassword = ({ changeHandler, loading, error, removeError }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    const newPassword = password.trim();
    const passwordConfirm = confirmPassword.trim();
    changeHandler(newPassword, passwordConfirm);
  };

  return (
    <FormStyled>
      <TextField
        className="input"
        variant="outlined"
        label="Nueva contraseña"
        type="password"
        error={error ? true : false}
        value={password}
        onFocus={() => removeError()}
        onChange={(event) => setPassword(event.target.value)}
      />
      <TextField
        className="input"
        variant="outlined"
        label="Confirmar contraseña"
        type="password"
        error={error ? true : false}
        value={confirmPassword}
        onFocus={() => removeError()}
        onChange={(event) => setConfirmPassword(event.target.value)}
      />
      <ActionButton
        text={loading ? "Guardando..." : "Guardar"}
        onClick={() => handleChangePassword()}
        disabled={loading}
        error={error ? true : false}
        errorMessage={error}
      />
      <div className="reset-password">
        <Link to="/login">Iniciar sesión</Link>
      </div>
    </FormStyled>
  );
};

const FormStyled = styled.section`
  display: flex;
  flex-direction: column;

  .section-title {
    color: ${colors.darkText};
  }

  .input {
    margin: 10px 0;
  }

  .reset-password {
    text-align: center;

    a {
      color: ${(props) => (props.light ? colors.white : colors.darkText)};
      cursor: pointer;
      :hover {
        color: ${colors.highlightColor};
      }
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }
`;

export default ChangePassword;
