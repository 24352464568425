import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "./constants"
// views
import Navbar from "./navbar"
import Footer from "./footer"

const Layout = (props) => {
  const { children, userData, simple, lightLogo, dashboard, handleModalClick } =
    props

  return (
    <>
      <Navbar
        handleModalClick={handleModalClick}
        userData={userData}
        simple={simple}
        lightLogo={lightLogo}
        dashboard={dashboard}
      />
      <DefaultLayoutStyled>{children}</DefaultLayoutStyled>
      <Footer />
    </>
  )
}

const DefaultLayoutStyled = styled.section`
  background-color: ${colors.backgroundWhite};
  box-sizing: border-box;
  font-family: "calibre";
  overflow-x: hidden;
  position: relative;
  /* padding-bottom: 160px; */
  min-height: 80vh;

  .banner {
    background-color: #5281c8;
    color: white;
    padding: 1rem;
    text-align: center;
  }
`
export default Layout
