import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import moment from "moment"
import {
  BarChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Bar,
  LineChart
} from "recharts"
// utils
import { colors, months } from "../../layout/constants"
import currency from "currency.js"

const BuildingChart = ({ type, record, getYearAverage }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const formatRecords = () => {
      const currentYearNumber = moment().format("YYYY")
      const lastYearNumber = moment().subtract(1, "year").format("YYYY")
      const actualYear = record.filter((row) =>
        row.period.includes(currentYearNumber)
      )
      const pastYear = record.filter((row) =>
        row.period.includes(lastYearNumber)
      )

      return pastYear.map((row, index) => {
        const value =
          type === "token-value"
            ? "value_a"
            : type === "token-noi"
            ? "noi_a"
            : "occupancy"
        return {
          period: months[moment(row.period).format("M") - 1],
          past: Number(row[value]).toFixed(2),
          present:
            actualYear.length > index
              ? Number(actualYear[index][value]).toFixed(2)
              : null
        }
      })
    }

    const formatedRecord = formatRecords()
    setData(formatedRecord)
  }, [record, type])

  const tooltipFormatter = (value, name, props) => {
    if (type === "occupancy") {
      return `${(value * 100).toFixed(0)}%`
    }

    return `${currency(value, { precision: 0 }).format()}`
  }

  const renderLineChart = (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        {type !== "token-value" ? (
          <BarChart width={730} height={250} data={data} barGap="0">
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="period" />
            <YAxis
              axisLine={false}
              tickFormatter={(value) => tooltipFormatter(value)}
            />
            <Tooltip formatter={(value) => tooltipFormatter(value)} />
            <Legend />
            <Bar dataKey="present" fill={colors.secondaryDark} name="2024" />
            <Bar dataKey="past" fill={colors.helperGray} name="2023" />
          </BarChart>
        ) : (
          <LineChart data={data}>
            <CartesianGrid vertical={false} />
            <Line
              type="monotone"
              dataKey="present"
              stroke={colors.secondaryDark}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="past"
              stroke={colors.helperGray}
              strokeWidth={3}
            />
            <XAxis dataKey="period" />
            <YAxis
              axisLine={false}
              tickFormatter={(value) => tooltipFormatter(value)}
            />
            <Tooltip formatter={(value) => tooltipFormatter(value)} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  )

  const getPresentValue = () => {
    return data.find(
      (value) => value.period === months[moment().format("M") - 2]
    )
  }

  return (
    <ChartContainer>
      <h3>
        <span>
          {type === "token-value"
            ? "Valor histórico x m2"
            : type === "token-noi"
            ? "Ganancias históricas x m2"
            : "Ocupación histórica"}
        </span>
        <NumberFormat
          className="green"
          value={
            type === "occupancy"
              ? `${getYearAverage().averageOccupancy}%`
              : type === "token-noi"
              ? `$${getYearAverage().averageNoi.toFixed(2)}`
              : getPresentValue()?.present
          }
          displayType={"text"}
          prefix={type === "occupancy" ? "" : "$"}
          suffix={type !== "occupancy" ? "" : "%"}
          thousandSeparator={true}
        />
      </h3>
      {renderLineChart}
    </ChartContainer>
  )
}

const ChartContainer = styled.section`
  h3 {
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .green {
    color: #00d098;
  }
`

export default BuildingChart
