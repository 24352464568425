import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { Route, Redirect, Switch } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"
// Routes
import PrivateRoute from "./privateRoute"
import PublicRoute from "./publicRoute"
// services
import { verifyUserToken, getUser } from "../services/users"
import { getOrders } from "../services/orders"
// Utils
import {
  getToken,
  removeUserSession,
  setUserSession
} from "../utils/sesionManagment"
// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  setUserToken,
  setUserId,
  setUserData,
  setReport,
  setAddToCart
} from "../state/actions"
// Layout
import ScrollToTop from "../components/layout/scrollToTop"
// Views
import Reports from "../components/reports"
import Login from "../components/login"
import Signup from "../components/signup"
import Home from "../components/home"
import Simulator from "../components/simulator"
import ShowDetailsCheckout from "../components/simulator/checkout"
import Buildings from "../components/buildings"
import ChangePassword from "../components/login/changePassword"
import NotFoundPage from "../components/layout/notFoundPage"
import Terms from "../components/terms"
import Privacy from "../components/terms/privacy"
import PublicTerms from "../components/terms/publicTerms"
import ConfigureSubscription from "../components/payments/configureSubscription"
import SummarySimulator from "../components/simulator/resumen"
import PaySimulator from "../components/simulator/pay"
import SelectFractions from "../components/simulator/SelectFractions"
import SpeiPayment from "../components/simulator/Spei"
import { getUserReport } from "../services"
import CheckoutError from "../components/simulator/checkout/CheckoutError"
import SpeiRFC from "../components/simulator/SpeiRFC"
import Loader from "../components/reports/loader"
import Ambassador from "../components/ambassador"

const App = () => {
  // Redux hooks
  const query = useQuery()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData)
  // const userReport = useSelector((state) => state.user.report)

  const [authLoading, setAuthLoading] = useState(true)

  useEffect(() => {
    const token = getToken()
    if (!token) {
      setAuthLoading(false)
      return
    }

    const setUserOrders = (order) => {
      const mappedOrders = order.reduce((finalOrder, order) => {
        finalOrder[order.phase_id] = {
          quantity: Number(order.total_tokens_count).toFixed(0),
          name: order.phase_name,
          value: order.single_token_value
        }

        return finalOrder
      }, {})

      setAddToCart(dispatch, mappedOrders)
    }

    const verificateToken = async (token) => {
      try {
        const verification = await verifyUserToken(token)
        const user = await getUser(token, verification.user.userId)
        const report = await getUserReport(token, verification.user.userId)
        const orders = await getOrders(token, verification.user.userId)
        // Save in session storage
        setUserSession(verification.token, verification.user.userId)
        // Save in redux state
        setUserToken(dispatch, verification.token)
        setUserId(dispatch, verification.user.userId)
        setReport(dispatch, report)
        setUserOrders(orders)
        setUserData(dispatch, user)
        setAuthLoading(false)
      } catch (error) {
        removeUserSession()
        setAuthLoading(false)
      }
    }

    verificateToken(token)
    ReactGA.initialize("G-76XT4V57HR")
  }, [dispatch])

  useEffect(() => {
    // analytics
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "otyuzn6u",
      email: userData ? userData.email : null,
      user_hash: userData ? userData.hash : null
    })
    window.Intercom("update")
  }, [userData])

  if (authLoading && getToken()) {
    return <Loader message="Checking Authorization" />
  }

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <Redirect 
            to={{
            pathname: "/tablero",
            search: `via=${query.get("via")}`
             }} 
          />
        </Route>
        {/* Registro y login */}
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/registro" component={Signup} />
        <PublicRoute path="/recuperar-contraseña" component={ChangePassword} />
        {/* Secciones */}
        <PrivateRoute path="/bienvenido" component={Home} />
        <PrivateRoute path="/tablero" component={Reports} />
        <PublicRoute path="/simulador" component={Simulator} />
        <PrivateRoute path="/edificios" component={Buildings} />
        <PrivateRoute
          path="/configurar-suscripcion"
          component={ConfigureSubscription}
        />
        <PrivateRoute path="/embajador" component={Ambassador} />
        {/* Proceso de pagos */}
        <PrivateRoute
          path="/resumen"
          component={SummarySimulator}
        ></PrivateRoute>
        <PrivateRoute path="/pay" component={PaySimulator}></PrivateRoute>
        <PrivateRoute path="/spei-rfc" component={SpeiRFC}></PrivateRoute>
        <PrivateRoute
          path="/pago-con-spei"
          component={SpeiPayment}
        ></PrivateRoute>
        <PrivateRoute
          path="/selecciona-fracciones"
          component={SelectFractions}
        ></PrivateRoute>
        <PrivateRoute path="/checkout" component={ShowDetailsCheckout} />
        <PrivateRoute path="/checkout-error" component={CheckoutError} />

        {/* Términos y condiciones */}
        <PrivateRoute path="/terminos" component={Terms} />
        <PublicRoute path="/aviso-de-privacidad" component={Privacy} />
        <PublicRoute path="/terminos-y-condiciones" component={PublicTerms} />

        <Route path="*" transactionType={NotFoundPage} />
      </Switch>
    </>
  )
}

export default App
