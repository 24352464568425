import React, { useState, useEffect } from "react"
import styled from "styled-components"

export const BuildingsModal = () => {
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const updateCounter = () => {
      setCounter((prevCounter) => (prevCounter + 1) % 3)
      setTimeout(updateCounter, 6000)
    }
    updateCounter()
  }, [])
  return (
    <Container>
      <div className="slider">
        <div className={`container-info ${counter === 0 ? "row" : undefined}`}>
          {counter === 0 && (
            <>
              <img src="/value.png" alt="metric" />
              <div>
                <h3>¿Quieres saber tu mejor opción?</h3>
                <p>Mensaje para invitar a usar la herramienta de comparar.</p>
              </div>
            </>
          )}

          {counter === 1 && (
            <>
              <h3>Descuentos</h3>
              <p>Descuentos en hospedajes Urvita.</p>
            </>
          )}

          {counter === 2 && (
            <>
              <h3>Eventos gratis</h3>
              <p>Acceso gratis a eventos Urvita.</p>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}

const Container = styled.section`
  background-color: #fff;
  padding: 2rem 0rem;

  .slider {
    box-shadow: 4px 2px 6px 0px #0000001f;
    border-radius: 5px;
    padding: 30px 20px;
    display: flex;
    background-color: #fff8f3;
    align-items: center;
    position: relative;
    justify-content: center;
    max-width: 70%;
    min-height: 75px;
    margin: 0 auto;

    h3 {
      font-family: "calibre";
      margin: 0;
      font-size: 1.6rem;
    }

    p {
      margin: 0;
      margin-top: 7px;
      font-size: 1.2rem;
    }

    .container-info {
      width: 230px;

      &.row {
        width: auto;
        display: flex;
        gap: 1rem;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }

        p {
          font-size: 1.1rem;
        }
      }
    }

    @media (min-width: 768px) {
      width: 400px;

      .container-info {
        width: 300px;

        &.row {
          width: auto;
        }
      }
    }

    @media (min-width: 1024px) {
      width: 480px;
    }
  }
`
