async function newOrder(orderData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/new`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(orderData)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

async function getOrders(userToken, user_id) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/lead/${user_id}/open`,
      {
        method: "GET",
        headers: myHeaders
      }
    )

    const { data } = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function deleteOrderByBuilding(userToken, user_id, phase_Status) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/building/${user_id}/${phase_Status}`,
      {
        method: "DELETE",
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error("order, error", error)
  }
}

async function updateOrder(userToken, order_id, newValues) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders//${order_id}`,
      {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(newValues)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error("order, error", error)
  }
}

export { newOrder, deleteOrderByBuilding, getOrders, updateOrder }
