import React from "react";
import styled from "styled-components";
import { colors } from "../../layout/constants";

const ButtonStyled = styled.div`
  height: 40px;
  border-radius: ${(props) => {
    if (props.square) {
      return "0";
    } else if (props.pillLeft) {
      return "16px 0 0 16px";
    } else if (props.pillRight) {
      return "0 16px 16px 0";
    } else {
      return "30px";
    }
  }};
  align-items: center;
  border: ${(props) =>
    `1px solid ${props.active ? props.activeColor : props.activeColor}`};
  background: ${(props) => (props.active ? props.activeColor : "transparent")};
  color: ${(props) => (props.active ? colors.white : props.textColor)};
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.primaryColor};
    border-color: ${colors.primaryColor};
    color: ${colors.white};
  }

  &:active {
    background-color: ${colors.highlightColor};
  }

  .text-button {
    margin: 0 5px;
    font-style: normal;
    font-weight: normal;
  }
  .content {
    align-items: center;
    display: flex;
  }
  img {
    height: 30px;
  }
`;

const Button = (props) => {
  const {
    active,
    text,
    icon,
    activeColor,
    secondaryColor,
    textColor,
    square,
    pillLeft,
    pillRight,
    onClick,
  } = props;

  return (
    <ButtonStyled
      active={active}
      square={square}
      pillLeft={pillLeft}
      pillRight={pillRight}
      activeColor={activeColor}
      secondaryColor={secondaryColor}
      onClick={onClick}
      textColor={textColor}
    >
      <div className="content">
        {icon && <img src={icon} alt="" />}
        {props.children}
        <span className="text-button">{text}</span>
      </div>
    </ButtonStyled>
  );
};

export default Button;
