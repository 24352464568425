import React from "react";
import { colors } from "../../components/layout/constants";

const UrvitaLogoSVG = (props) => {
  const { color, className } = props;

  return (
    <svg
      className={className}
      viewBox="0 0 52 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70653 4.87062H6.6808V11.7869C6.46295 12.7367 5.66416 13.2968 4.74434 13.2968C3.55825 13.2968 3.00152 12.5175 3.00152 11.3729V4.87062H0V11.9817C0 14.3927 1.37973 16 3.80031 16C5.05901 16 6.09986 15.4399 6.70501 14.758V15.7565H9.70653V4.87062Z"
        fill={color ? color : colors.black}
      />
      <path
        d="M17.8864 4.82192C17.717 4.7245 17.257 4.62709 16.7487 4.62709C15.6837 4.62709 14.788 5.16286 14.3281 5.94216V4.87062H11.3266V15.7565H14.3281V9.01065C14.546 8.01218 15.369 7.47641 16.3856 7.47641C16.9424 7.47641 17.4749 7.59817 17.8864 7.8417V4.82192Z"
        fill={color ? color : colors.black}
      />
      <path
        d="M25.3318 15.7565L29.4467 4.87062H26.421L23.9762 12.1035L21.483 4.87062H18.3363L22.4271 15.7565H25.3318Z"
        fill={color ? color : colors.black}
      />
      <path
        d="M33.1103 4.87062H30.1088V15.7565H33.1103V4.87062ZM33.3766 1.72907C33.3766 0.730593 32.5536 0 31.6096 0C30.6413 0 29.8183 0.730593 29.8183 1.72907C29.8183 2.7519 30.6413 3.45814 31.6096 3.45814C32.5536 3.45814 33.3766 2.7519 33.3766 1.72907Z"
        fill={color ? color : colors.black}
      />
      <path
        d="M35.9042 12.4688C35.9042 14.8798 37.2839 15.9756 39.4867 15.9756C40.2855 15.9756 41.0116 15.8539 41.4231 15.6347V12.9559C41.0843 13.175 40.5759 13.3212 40.116 13.3212C39.3414 13.3212 38.8815 12.9315 38.8815 12.0791V7.30594H41.2537V4.87062H38.8815V1.9239H35.9042V4.87062H34.2582V7.30594H35.9042V12.4688Z"
        fill={color ? color : colors.black}
      />
      <path
        d="M51.5103 15.7565V8.81583C51.5103 6.21004 49.8401 4.62709 46.9596 4.62709C44.4907 4.62709 42.7236 6.13699 42.409 8.10959H45.2652C45.5073 7.47641 46.0156 7.08676 46.8628 7.08676C48.0731 7.08676 48.6299 7.8417 48.6299 8.76712V9.57078C48.1941 9.25418 47.1049 8.9376 46.1609 8.9376C43.8129 8.9376 41.9733 10.3501 41.9733 12.4201C41.9733 14.6849 43.8129 15.9513 45.9914 15.9513C47.1775 15.9513 48.2668 15.5373 48.6299 15.172V15.7565H51.5103ZM48.6299 12.7854C48.3636 13.3699 47.5406 13.7352 46.6692 13.7352C45.7252 13.7352 44.7569 13.3455 44.7569 12.3957C44.7569 11.4703 45.7252 11.0563 46.6692 11.0563C47.5406 11.0563 48.3636 11.4216 48.6299 12.0061V12.7854Z"
        fill={color ? color : colors.black}
      />
    </svg>
  );
};

export default UrvitaLogoSVG;
