import React from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { LateralMenuStyles } from "./styles"
import { colors } from "../constants"

import LogoutIcon from "../../../assets/icons/logoutIcon"
import userAvatar from "../../../assets/img/user-avatar.png"
import { useSelector } from "react-redux"
import styled from "styled-components"

const LateralMenu = ({ showMenu, userReport, logoutHandler, dashboard }) => {
  let history = useHistory()
  let location = useLocation()
  const cart = useSelector((state) => state.cart)
  const linksData = [
    { label: "Simulador", route: "/simulador" },
    { label: "Perfil", route: "/tablero/perfil" },
    // { label: "Movimiento", route: "/edificios" },
    { label: "Edificios", route: "/edificios" },
    { label: "Embajador", route: "/embajador" }
  ]

  const countOrder = () => {
    const orderArray = Object.keys(cart).map((building) => {
      return cart[building]
    })

    return orderArray.reduce((count, order) => {
      count += Number(order.quantity)

      return count
    }, 0)
  }

  return (
    <LateralMenuStyles showMenu={showMenu}>
      <div className="profile">
        <img className="profile-img" src={userAvatar} alt="Avatar de usuario" />
        {userReport && (
          <ul className="info">
            <li className="name">{userReport.userName}</li>
            <li className="email">{userReport.userEmail}</li>
          </ul>
        )}
      </div>

      {dashboard ? (
        <ul className="navegation-list">
          <li className="navegation-item">
            <Link
              to="/tablero"
              active={location.pathname === "/tablero" ? "true" : "false"}
            >
              Tablero
            </Link>
          </li>
          {linksData.map((link, index) => {
            const { label, route } = link
            return (
              <li className="navegation-item" key={index}>
                <Link
                  to={route}
                  active={
                    location.pathname.substring(0, 15) === `tablero/${route}`
                      ? "true"
                      : "false"
                  }
                >
                  {label}
                </Link>
              </li>
            )
          })}
          <ContainerIcon onClick={() => history.push("/selecciona-fracciones")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-shopping-cart cart"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M17 17h-11v-14h-2" />
              <path d="M6 5l14 1l-1 7h-13" />
            </svg>

            <div className="quantity">+{countOrder()}</div>
          </ContainerIcon>
        </ul>
      ) : null}

      <div className="logout" onClick={() => logoutHandler()}>
        <LogoutIcon className="logout-icon" color={colors.darkText} />
        <span className="logout-text">Logout</span>
      </div>
    </LateralMenuStyles>
  )
}

const ContainerIcon = styled.div`
  width: 30px;
  margin: 10px auto;
  position: relative;
  .cart {
  }
  .quantity {
    display: flex;
    border: 1px solid #000;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 20px;
    right: -12px;
    background: #fff;
    border-radius: 100%;
    /* padding: 3px; */
    font-size: 14px;
  }
`

export default LateralMenu
