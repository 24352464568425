import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// Services
import { getUserReport } from "../../services"
import { editUser } from "../../services/users"
import { removeCookie } from "../../utils/cookies"
import { newOrder } from "../../services/orders"
// layout
import DefaultLayout from "../layout"
import Wrapper from "../layout/wrapper"
import StatusBar from "./statusBar"
import Modal from "../layout/shared/modal"
import ActionButton from "../layout/shared/actionButton"
// views
import TokensView from "./tokens"
import ProfileView from "./profile"
import MovementsView from "./movements"
// import InvestmentView from "./monthlyInvestment";
import Loader from "./loader"
import anuncio from "../../assets/img/anuncio.png"
// state
import {
  setUserId,
  setUserToken,
  setUserStep,
  setReport,
  setOrder,
  setUserData
} from "../../state/actions"

const ModalStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
  }
`

const Home = () => {
  let { url } = useRouteMatch()
  let history = useHistory()

  // Redux hooks
  const userId = useSelector((state) => state.user.id)
  const userToken = useSelector((state) => state.user.token)
  const userReport = useSelector((state) => state.user.report)
  // const simulation = useSelector((state) => state.simulation);
  const dispatch = useDispatch()

  const [hasError, setHasError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  // Get report and user info
  useEffect(() => {
    // Redux actions
    const setUserReport = (report) => {
      setReport(dispatch, report)
      setUserData(dispatch, report.userData)
      setUserStep(dispatch, report.userTrailStep ? report.userTrailStep : 0)
    }

    const setUser = (token, id, step) => {
      setUserId(dispatch, id)
      setUserToken(dispatch, token)
      setUserStep(dispatch, step)
    }

    const getReport = async (userToken, userId) => {
      try {
        const report = await getUserReport(userToken, userId)

        if (report.error) {
          removeCookie("userToken")
          setUser(null, null, null)
          setHasError(true)
          return
        }

        setUserReport(report)
      } catch (error) {
        removeCookie("userToken")
        setUser(null, null, null)
        setHasError(true)
      }
    }

    if (userId) {
      getReport(userToken, userId)
    }
  }, [dispatch, userId, userToken])

  useEffect(() => {
    // setModalOpen(true)
  }, [userReport])

  const handleModalClick = (route) => {
    // if (contractStatus !== "signed") {
    //   setIsKycOpen(true)
    // } else {
    history.push(route)
    // }
  }

  return (
    <DefaultLayout
      userData={userReport}
      lightLogo
      dashboard
      handleModalClick={handleModalClick}
    >
      {userReport ? (
        <>
          <Switch>
            <Route exact path={`${url}`}>
              <Redirect to={`${url}/tokens`} />
            </Route>

            <Route path={`${url}/tokens`}>
              <>
                <StatusBar
                  userReport={userReport}
                  handleModalClick={handleModalClick}
                />
                <Wrapper>
                  <TokensView
                    userReport={userReport}
                    handleModalClick={handleModalClick}
                  />
                </Wrapper>
              </>
            </Route>

            <Route path={`${url}/perfil`}>
              <Wrapper>
                <ProfileView />
              </Wrapper>
            </Route>

            <Route path={`${url}/movimientos`}>
              <Wrapper>
                <MovementsView
                  userReport={userReport}
                  handleModalClick={handleModalClick}
                />
              </Wrapper>
            </Route>
          </Switch>

          {modalOpen && (
            <Modal setModal={setModalOpen}>
              <ModalStyled>
                <a
                  href="https://my.demio.com/ref/g6GA4LIOzej6Vj04?utm_source=Leon&utm_medium=App&utm_id=Sesión+Informativa"
                  target="_blank rel=noopener noreferrer"
                >
                  <img src={anuncio} alt="anuncio para entrar" />
                </a>
              </ModalStyled>
            </Modal>
          )}
        </>
      ) : (
        <Loader message="Estamos preparando tu tablero..." />
      )}
    </DefaultLayout>
  )
}

export default Home
