import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
// Components
import ChangePasswordForm from "./changePasswordForm";
// layout
import DefaultLayout from "../layout";
import Footer from "../layout/footer";
import Wrapper from "../layout/wrapper";
import { colors } from "../layout/constants";
// assets
import buildingImg from "../../assets/img/buildings/hernan.jpeg";
// services
import { changePassword } from "../../services/auth";

const ChangePasswordView = ({ history }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  // Get url param hook
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const changeHandler = async (newPassword, passwordConfirm) => {
    const userId = query.get("userId");
    const token = query.get("token");
    if (newPassword === passwordConfirm && newPassword !== "") {
      setLoading(true);
      try {
        const changeOutcome = await changePassword(token, userId, newPassword);
        setLoading(false);
        if (changeOutcome.error) {
          setError(
            "No se puede cambiar tu contraseña en este momento, intenta más tarde"
          );
        }
        setDone(true);
      } catch (error) {
        setLoading(false);
      }
    } else if (newPassword === "") {
      setError("La contraseña no puede quedar vacía");
    } else {
      setError("Las constraseñas no coinciden");
    }
  };

  const removeError = () => {
    setError(null);
  };

  return (
    <DefaultLayout simple>
      <Wrapper>
        <ViewStyled>
          <section className="background-content">
            <img src={buildingImg} alt="Edificio de Urvita" />
          </section>

          <section className="input-container">
            <div className="info">
              <h3 className="title">Elige una nueva contraseña</h3>
              <p className="title">Tu contraseña no puede tener:</p>
              <ul>
                <li>Tu nombre o fecha de nacimiento</li>
                <li>La frase Urvita o conceptos relacionados</li>
                <li>Más de 3 caracteres identicos de forma consecutiva</li>
                <li>
                  Más de 3 caracteres numericos y/o alfabeticos de forma
                  secuencial
                </li>
              </ul>
            </div>
            {done ? (
              <div>
                <h3>¡Tu constraseña se actualizó exitosamente!</h3>
                <Link className="login-link" to="/login">
                  Iniciar sesión
                </Link>
              </div>
            ) : (
              <ChangePasswordForm
                changeHandler={changeHandler}
                error={error}
                loading={loading}
                removeError={removeError}
              />
            )}
          </section>
        </ViewStyled>
        <Footer />
      </Wrapper>
    </DefaultLayout>
  );
};

const ViewStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  padding-bottom: 2em;

  .background-content {
    overflow: hidden;
    height: 85vh;
    & > img {
      height: 100%;
      transform: translateX(-12%);
    }
  }

  .login-link {
    color: ${colors.darkText};
    cursor: pointer;
    :hover {
      color: ${colors.highlightColor};
    }
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    min-height: 60vh;

    .background-content {
      display: none;
    }
  }
`;

export default ChangePasswordView;
