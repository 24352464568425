import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import TextField from "@material-ui/core/TextField"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Checkbox from "@material-ui/core/Checkbox"
import { useLocation } from "react-router-dom"
// layout
import { colors } from "../layout/constants"
import SectionTitle from "../layout/shared/sectionTitle"
import ActionButton from "../layout/shared/actionButton"
// services
import { createUser } from "../../services/users"
import { sendVerificationMail } from "../../services/auth"
import { getCountries } from "../../services/codeCountries"
// analytics
import ReactGA from "react-ga4"

const SignupForm = ({ light }) => {
  // Get url param hook
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()

  const getCountriesFunction = async () => {
    const codeContries = await getCountries()
    setCodesContries(codeContries)
  }

  // state
  const [codesContries, setCodesContries] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [userCreated, setUserCreated] = useState(false)
  // inputs
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(false)
  const [lastName, setLastName] = useState("")
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [code, setCode] = useState("+52")
  const [codeError, setCodeError] = useState(false)
  // checkbox
  const [agree, setAgree] = useState(false)
  const [newsletter, setNewsletter] = useState(true)
  // error message
  const [emptyErrorMessage, setEmptyErrorMessage] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

  // Redux hooks
  const simulationValues = useSelector((state) => state.simulation)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/signup" })
    getCountriesFunction()
  }, [])

  useEffect(() => {
    setNameError(false)
    setLastNameError(false)
    setEmptyErrorMessage("")
    setErrorMessage("")
  }, [name, lastName])

  useEffect(() => {
    setEmailError(false)
    setErrorMessage("")
  }, [email])

  useEffect(() => {
    setPhoneError(false)
    setErrorMessage("")
  }, [phone])

  useEffect(() => {
    setPasswordError(false)
    setPasswordErrorMessage("")
    setErrorMessage("")
  }, [password, confirmPassword])

  const validateForm = () => {
    let isValid = true
    if (name === "") {
      setNameError(true)
      setEmptyErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (lastName === "") {
      setLastNameError(true)
      setEmptyErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (email === "") {
      setEmailError(true)
      setEmptyErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (phone === "") {
      setPhoneError(true)
      setEmptyErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (code === "") {
      setCodeError(true)
      setEmptyErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (password === "") {
      setPasswordError(true)
      setPasswordErrorMessage("No puede quedar vacio")
      isValid = false
    }
    if (password !== confirmPassword) {
      setPasswordError(true)
      setPasswordErrorMessage("Las contraseñas no coinciden")
      isValid = false
    }

    return isValid
  }

  const saveForm = async () => {
    const refCode = query.get("ref")
    const isValid = validateForm()
    if (!isValid) {
      return
    }

    const userData = {
      name,
      last_name: lastName,
      email,
      phone: code + phone,
      verificated: false,
      password,
      to_change_password: false,
      user_type: "investor",
      newsletter: newsletter,
      ref_code: refCode
    }
    setLoading(true)
    try {
      const newUser = await createUser(userData)
      setLoading(false)
      if (newUser.error) {
        setError(true)
        if (newUser.error.code === 409) {
          setEmailError(true)
          setErrorMessage("Ya existe una cuenta con ese correo electrónico.")
        } else {
          setErrorMessage("Algo salió mal.")
        }
        return
      }
      setUserCreated(true)
      ReactGA.event({
        category: "User",
        action: "cuenta creada"
      })
      window.fbq("trackCustom", "CUENTA CREADA", {
        email: userData.email
      })
      window.fbq("track", "CompleteRegistration")
    } catch (error) {
      console.error(error)
      setLoading(false)
      setError(true)
    }
  }

  return (
    <FormStyled light={light}>
      {userCreated ? (
        <section className="created-section">
          <SectionTitle title="Confirma tu correo" color={colors.lightText} />
          <p>
            Revisa tu bandeja de entrada en el correo que nos proporcionaste y
            da clic en la liga para confirmar tu cuenta.
          </p>
          <p>¿No recibiste ningún correo?</p>
          <ActionButton
            text="Reenviar correo"
            left
            onClick={() => sendVerificationMail(email)}
          />
        </section>
      ) : (
        <form>
          <section>
            <div className="input-row">
              <TextField
                autoComplete="name"
                className="input"
                label="Nombre"
                variant="outlined"
                error={nameError}
                helperText={emptyErrorMessage}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                autoComplete="family-name"
                className="input"
                label="Apellidos"
                variant="outlined"
                error={lastNameError}
                helperText={emptyErrorMessage}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
            <div className="input-row">
              <TextField
                autoComplete="username"
                className="input"
                label="Correo"
                variant="outlined"
                error={emailError}
                helperText={emptyErrorMessage}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="input-row">
              <div className="container-codigo input">
                <FormControl>
                  <InputLabel id="code">Código</InputLabel>
                  <Select
                    labelId="code"
                    error={codeError}
                    helperText={emptyErrorMessage}
                    value={code}
                    label="Código"
                    onChange={(event) => setCode(event.target.value)}
                  >
                    {codesContries.map((code) => {
                      if (code.idd.suffixes) {
                        return (
                          <MenuItem
                            value={`${code.idd.root}${code.idd?.suffixes[0]}`}
                            key={code.name.common}
                          >
                            <img
                              className="flag"
                              src={code.flags.svg}
                              alt={code.name.common}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px"
                              }}
                            />
                            {code.idd.root}
                            {code.idd.suffixes[0]}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </FormControl>
              </div>
              <TextField
                autoComplete="phone"
                className="input"
                label="Teléfono"
                variant="outlined"
                error={phoneError}
                helperText={emptyErrorMessage}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
            <div className="input-row">
              <TextField
                autoComplete="new-password"
                className="input"
                type="password"
                label="Contraseña"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <TextField
                autoComplete="new-password"
                className="input"
                type="password"
                label="Confirmar Contraseña"
                variant="outlined"
                error={passwordError}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </div>

            <div className="rules">
              <span className="title">Tu contraseña no puede tener:</span>
              <span>- Tu nombre o fecha de nacimiento</span>
              <span>- La frase Urvita o conceptos relacionados</span>
              <span>- Más de 3 caracteres identicos de forma consecutiva</span>
              <span>
                - Más de 3 caracteres numericos y/o alfabeticos de forma
                secuencial
              </span>
              <span></span>
              <span></span>
            </div>

            <div className="terms">
              <div className="check-container">
                <Checkbox
                  checked={agree}
                  onChange={(event) => {
                    setAgree(event.target.checked)
                  }}
                />
                <label htmlFor="">
                  Estoy de acuerdo con los{" "}
                  <Link to="/terminos-y-condiciones" target="_blank">
                    Términos y condicions
                  </Link>
                  y con el tratamiento de mis datos según el{" "}
                  <Link to="/aviso-de-privacidad" target="_blank">
                    Aviso de Privacidad.
                  </Link>
                  .
                </label>
              </div>
              {/* <div className="check-container">
                <Checkbox
                  checked={newsletter}
                  onChange={(event) => {
                    setNewsletter(event.target.checked)
                  }}
                />
                <label htmlFor="">
                  Quiero recibir, de vez en cuando, correos con contenido y
                  actualizaciones (opcional).
                </label>
              </div> */}
            </div>

            <ActionButton
              left
              text={loading ? "Creando..." : "Crear cuenta"}
              onClick={() => saveForm()}
              disabled={loading || !agree}
              error={error}
              errorMessage={errorMessage}
            />
          </section>
          <Link className="login-link" to="/login">
            Ya tengo cuenta (iniciar sesión)
          </Link>
        </form>
      )}
    </FormStyled>
  )
}

const FormStyled = styled.section`
  margin-bottom: 1em;
  .row-title {
    font-weight: 200;
  }

  .login-link {
    color: ${colors.white};
    text-decoration: underline;

    :hover {
      color: ${colors.lightText};
    }
  }

  .input-row {
    display: flex;
    margin: 0 0 20px 0;
  }

  .input {
    margin-right: 10px;
  }

  .container-codigo {
    width: 120px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root {
    color: ${(props) => (props.light ? colors.disabled : colors.primaryColor)};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.light ? colors.disabled : colors.primaryColor};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.light ? colors.white : colors.primaryColor};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) =>
      props.light ? colors.white : colors.black};
    transition: background-color 5000s ease-in-out 0s;
  }

  .MuiCheckbox-root {
    color: ${(props) => (props.light ? colors.disabled : colors.primaryColor)};
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${colors.secondaryColor};
  }

  .rules {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    & > .title {
      font-size: 18px;
      padding: 15px 0;
    }
  }

  .link {
    color: ${colors.highlightColor};
    :hover {
      color: ${colors.secondaryColor};
    }
  }

  .terms {
    padding: 10px 0;
    .check-container {
      display: flex;
      padding: 10px 0;
    }
  }

  .created-section {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    min-height: calc(100vh - 230px);
  }
`

export default SignupForm
