import React, { Children, useEffect } from "react";
import styled from "styled-components";
// layout
import Wrapper from "../../layout/wrapper";
import SectionTitle from "../../layout/shared/sectionTitle";
import { colors, mobileBreakpoint } from "../../layout/constants";

const Modal = ({ modal, setModal, title, children, height, width }) => {
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    }
    return () => {
      document.body.style.overflow = "inherit";
      document.body.style.paddingRight = "0";
    };
  }, [modal]);

  return (
    <ModalStyled height={height} width={width}>
      <div
        className="modal-background"
        onClick={() => {
          setModal(false);
        }}
      />

      <section className="card">
        <Wrapper>
          <div className="title-row">
            <SectionTitle title={title} color={colors.darkText} />
            <span
              className="close-button"
              onClick={() => {
                setModal(false);
              }}
            >
              {" "}
              Cerrar X
            </span>
          </div>

          <div className="section-wrapper">{Children.only(children)}</div>
        </Wrapper>
      </section>
    </ModalStyled>
  );
};

const ModalStyled = styled.section`
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-shrink: 0;
  z-index: 2;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .card {
    background-color: white;
    box-shadow: 1px 2px 4px 1px lightgrey;
    color: ${colors.darkText};
    padding: 2rem;
    transition: all 0.2s;
    min-width: 300px;
    min-height: ${(props) => (props.height ? props.height + "vh" : "none")};
    max-width: ${(props) => (props.width ? props.width + "px" : "500px")};
    max-height: 75vh;
    margin-bottom: 3rem;
    overflow-y: scroll;
    position: relative;
    animation: openAnimation 0.4s;

    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .title-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .close-button {
    color: ${colors.darkText};
    cursor: pointer;

    :hover {
      transform: scale(1.05);
    }
  }

  p {
    color: ${colors.darkText};
  }

  @keyframes openAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (max-width: ${mobileBreakpoint}) {
    .card {
      max-width: 250px;
    }
  }
`;

export default Modal;
