import cookie from 'js-cookie'

export const setCookie = (key, value) => {
  cookie.set(key, value, {
      expires: 1
  });
};

export const getCookie = (key, req) => {
  return cookie.get(key);
};

export const removeCookie = (key) => {
  return cookie.remove(key);
}
