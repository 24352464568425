import React, {
  useEffect,
  useRef,
  Children,
  useState,
  useCallback,
} from "react";
import styled from "styled-components";
// layout
import { colors } from "../constants";

const Carousel = ({ children, height, auto }) => {
  let activeSlide = 0;
  const scrollContainer = useRef(null);
  const [totalSlides, setTotalSlides] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);

  useEffect(() => {
    const count = Children.count(children);
    const scrollWidth = scrollContainer.current.scrollWidth;
    setSlideWidth(scrollWidth / count);
    setTotalSlides(count);
  }, [scrollContainer, children]);

  const toNext = useCallback(() => {
    if (activeSlide >= totalSlides - 1) {
      scrollContainer.current.scrollLeft = slideWidth * 0;
      activeSlide = 0;
    } else {
      scrollContainer.current.scrollLeft = slideWidth * (activeSlide + 1);
      activeSlide += 1;
    }
  });

  const toLast = () => {
    if (activeSlide <= 0) {
      scrollContainer.current.scrollLeft = slideWidth * (totalSlides - 1);
      activeSlide = totalSlides - 1;
    } else {
      scrollContainer.current.scrollLeft = slideWidth * (activeSlide - 1);
      activeSlide -= 1;
    }
  };

  useEffect(() => {
    if (auto) {
      const intervalId = setInterval(toNext, 10000);
      return () => clearInterval(intervalId);
    }
  }, [auto, toNext, totalSlides]);

  return (
    <CarouselContainer ref={scrollContainer} height={height}>
      <Control left onClick={() => toLast()}>
        {"<"}
      </Control>
      {children}
      <Control right onClick={() => toNext()}>
        {">"}
      </Control>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.section`
  height: ${(props) => props.height};
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;

  & > article {
    flex-shrink: 0;
    scroll-snap-align: start;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.secondaryDark};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const Control = styled.div`
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  position: sticky;
  padding: 0 1rem;
  right: ${(props) => (props.right ? 0 : "auto")};
  left: ${(props) => (props.left ? 0 : "auto")};
  top: 0;
  bottom: 0;
  z-index: 1;
`;

export default Carousel;
