import styled from "styled-components";
import { colors } from "../constants";

const ProfileStyled = styled.div`
  .profile-avatar {
    align-items: center;
    display: flex;
  }

  .profile-img {
    width: 50px;
  }

  .profile-info {
    color: ${(props) => (props.lightLogo ? colors.lightText : colors.darkText)};
    padding: 0 10px;
  }

  .profile-name {
    font-weight: 600;
  }

  .logout {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  .logout-text {
    margin: 0 5px;
  }

  .logout-icon {
    height: 16px;
    width: 16px;
  }

  .hamburger-menu {
    display: none;
  }

  .menu-icon {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 968px) {
    .profile-avatar {
      display: none;
    }

    .profile-img {
      width: 40px;
    }

    .hamburger-menu {
      display: block;
    }
  }
`;

const LateralMenuStyles = styled.aside`
  background: ${colors.backgroundWhite};
  box-sizing: border-box;
  height: 100vh;
  padding: 20px;
  position: fixed;
  right: ${(props) => (props.showMenu ? "0" : "-280px")};
  top: 0;
  transition: 0.4s;
  width: 280px;
  z-index: 3;

  .profile {
    text-align: center;
  }

  .profile-img {
    width: 50px;
    border-radius: 50%;
  }

  .info {
    list-style: none;
    padding: 0;
  }

  .name {
    font-size: 24px;
    font-weight: 600;
    padding: 10px 0;
  }
  .email {
    font-size: 16px;
    color: #a2a2a2;
  }

  .logout {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }

  .logout-icon {
    height: 30px;
    width: 30px;
  }

  .navegation-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0 auto;
  }

  .navegation-item a {
    border-bottom: 1px solid ${colors.darkText};
    color: ${(props) => (props.lightLogo ? colors.lightText : colors.darkText)};
    display: block;
    padding: 15px 0;
    text-align: center;
    width: 100%;

    :hover {
      color: ${(props) =>
        props.lightLogo ? colors.highlightColor : colors.darkText};
    }

    &[active="true"] {
      color: ${(props) =>
        props.lightLogo ? colors.highlightColor : colors.darkText};
    }
  }
`;

const Background = styled.div`
  display: ${(props) => (props.showMenu ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(8px);
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
`;

export { ProfileStyled, LateralMenuStyles, Background };
