import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
// layout
import Button from "../../styled/forms/button"
import { colors, transactionName, dateFormat } from "../../layout/constants"
import Tooltip from "../../layout/shared/tooltip"
import { getOrders, updateOrder } from "../../../services/orders"
import { cancelPaymentIntent } from "../../../services/stripe"
import { useSelector } from "react-redux"
import currency from "currency.js"

const Movements = ({
  userType,
  userTransactions,
  filter,
  handleModalClick
}) => {
  const user = useSelector((state) => state.user)
  const [transactions, setTransactions] = useState([...userTransactions])
  const [orderUser, setOrderUser] = useState()
  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    let filteredTransactions
    if (filter === "rents") {
      filteredTransactions = transactions.filter((transaction) => {
        if (
          transaction.type === "CXP_REINVERTIR_DE_BALANCE" ||
          transaction.type === "CXP_RECIBIR_DE_BALANCE" ||
          transaction.type === "CXP_REINVERTIR" ||
          transaction.type === "CXP_RECIBIR"
        ) {
          return true
        }
      })
      setTransactions(filteredTransactions)
    }
  }, [filter, transactions])

  // Sum token and balance rents
  const sumRents = (transaction) => {
    if (
      transaction.type === "CXP_REINVERTIR_DE_BALANCE" ||
      transaction.type === "CXP_RECIBIR_DE_BALANCE"
    ) {
      transaction = null
    } else if (
      transaction.type === "CXP_REINVERTIR" ||
      transaction.type === "CXP_RECIBIR"
    ) {
      const balanceList = transactions.filter(
        (item) =>
          item.type === "CXP_REINVERTIR_DE_BALANCE" ||
          item.type === "CXP_RECIBIR_DE_BALANCE"
      )
      balanceList.forEach((newItem) => {
        if (transaction.transaction_date === newItem.transaction_date) {
          const netAmount =
            Number(transaction.net_amount) + Number(newItem.net_amount)
          transaction.final_net_amount = Number(netAmount)
          transaction.final_net_cents = (transaction.final_net_amount % 1) * 100
        }
      })
    } else {
      transaction.net_cents = (transaction.net_amount % 1) * 100
    }
    return transaction
  }

  useEffect(() => {
    const order = async () => {
      let newValue = {
        total: 0,
        date: "",
        tokens: 0
      }

      try {
        const orderList = await getOrders(user.token, user.id)
        setOrderList(orderList)
        orderList.forEach((order) => {
          newValue = {
            total: (newValue.total += Number(order.total_tokens_value)),
            date: order.order_date,
            tokens: (newValue.tokens += Number(
              Number(order.total_tokens_count).toFixed(0)
            ))
          }
        })
      } catch (error) {
        console.error(error)
      }
      setOrderUser(newValue)
    }

    order()
  }, [user])

  const cancelOrder = () => {
    orderList.forEach(async (order) => {
      await updateOrder(user.token, order.id, {
        order_status: "cancelada"
      })
    })
    setOrderUser(null)
  }

  return (
    <MovementsStyles>
      {orderUser?.total ? (
        <section className="section-title order">
          <div>
            <span>
              Ordenes abiertas{" "}
              <Tooltip content="Las ordenes que tienes sin completar" dark />
            </span>

            <button className="helper" onClick={() => cancelOrder()}>
              Cancelar orden
            </button>
          </div>

          <div className="order-info">
            <div className="container">
              <div>
                <span className="date">
                  m<sup>2</sup> x{orderUser?.tokens}
                </span>
                <span className="tokens">{dateFormat(orderUser?.date)}</span>
              </div>
              <div>
                <span>
                  {currency(orderUser?.total, { precision: 0 }).format()}
                </span>
              </div>
            </div>
            <Link
              to="/selecciona-fracciones?resumen=true"
              className="container-button"
            >
              <button>Ver Orden</button>
            </Link>
          </div>
        </section>
      ) : null}

      <h3 className="section-title">
        <span>
          Movimientos{" "}
          <Tooltip
            content="Historial de abonos, retiros y ganancias generadas."
            dark
          />
        </span>

        <Link to="/tablero/movimientos">
          <span className="helper">Ver más</span>
        </Link>
      </h3>

      {userType === "trail" ? (
        <p className="empty-text">
          Historial de pagos , retiros y reinversiones.
        </p>
      ) : (
        <section className="history">
          {transactions.map((transaction, index) => {
            transaction = sumRents(transaction)

            return transaction && index > transactions.length - 9 ? (
              <article key={transaction.id}>
                {/* {monthTitle(transaction.transaction_date, index) ? (
                  <p className="month-title">
                    {monthTitle(transaction.transaction_date, index)}
                  </p>
                ) : null} */}
                <article className="history-item">
                  <div className="description">
                    <span className="text">
                      {transactionName(transaction.type)}
                    </span>
                    <span className="date">
                      {dateFormat(transaction.transaction_date)}
                    </span>
                  </div>

                  {transaction.status === "PENDING" && (
                    <div className="verification">
                      Verificando{" "}
                      <Tooltip
                        content="Estamos verificando tu transacción. Veras reflejado el incremento de tu balance en cuanto recibamos la confirmación del banco."
                        dark
                      />
                    </div>
                  )}

                  <div className="movement-value">
                    <span className="movement-helper">$</span>
                    <span className="movement-number">
                      {transaction.indicator === "withdrawal" ? "-" : null}
                      <NumberFormat
                        value={
                          transaction.final_net_amount
                            ? Math.trunc(Number(transaction.final_net_amount))
                            : Math.trunc(Number(transaction.net_amount))
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </span>
                    <span className="movement-helper">
                      .
                      {transaction.final_net_amount
                        ? Math.trunc(Number(transaction.final_net_cents))
                        : Math.trunc(Number(transaction.net_cents))}
                    </span>
                  </div>
                </article>
              </article>
            ) : null
          })}
        </section>
      )}
      {filter ? (
        <div className="movements-buttons">
          <Link to="/tablero/movimientos">
            <Button
              text="Ver todos"
              active
              square
              textColor={colors.darkText}
              activeColor={colors.secondaryColor}
              secondaryColor={colors.secondaryColor}
            />
          </Link>
        </div>
      ) : null}
    </MovementsStyles>
  )
}

const MovementsStyles = styled.section`
  .section-title {
    display: flex;
    justify-content: space-between;

    .helper {
      color: ${colors.highlightColor};
      font-size: 0.8rem;
      font-weight: 200;
      border: none;
      background-color: transparent;
    }
  }

  .order {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* align-items: center; */
    padding: 10px;
    border: 2px solid orange;
    border-radius: 8px;

    div:nth-child(1) {
      display: flex;
      justify-content: space-between;
    }

    .helper {
      color: gray;
      cursor: pointer;
    }

    .helper:hover {
      text-decoration: underline;
      color: red;
    }
  }

  .order-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .container {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;
        text-align: right;
      }

      span {
        font-weight: 100;
      }

      .tokens {
        font-size: 15px;
      }
    }

    .container-button {
      width: min-content;
      margin: 0 auto;

      button {
        padding: 9px 14px;
        border-radius: 10px;
        border: none;
        background-color: #fbcfa4;
        cursor: pointer;
      }

      button:hover {
        background-color: #fab16b;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .order_id {
        font-size: 14px;
      }
    }

    .container-button {
      width: min-content;
      margin: 0 auto;

      button {
        padding: 9px 14px;
        border-radius: 10px;
        border: none;
        background-color: #fbcfa4;
        cursor: pointer;
      }

      button:hover {
        background-color: #fab16b;
      }
    }

    span {
      font-size: 1.2rem;
    }
  }

  .empty-text {
    font-size: 1.5em;
  }

  .history {
    display: flex;
    flex-direction: column-reverse;
  }

  .history-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .verification {
    border-radius: 8px;
    font-size: 0.8em;
    background-color: #f4e2b5;
    padding: 2px 5px;
  }

  .month-title {
    text-align: center;
    font-size: 1.2em;
  }

  .description {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
  }

  .text {
    padding-bottom: 4px;
    font-size: 1.5em;
  }

  .date {
    color: grey;
    font-size: 1em;
  }

  .movement-value {
    display: flex;
    font-size: 0.8em;
    align-items: center;
  }

  .movement-helper {
    font-size: 1em;
  }

  .movement-number {
    font-size: 1.5em;
  }

  .movements-buttons {
    max-width: 200px;
    margin: 0 auto;
  }

  @media (max-width: 968px) {
    width: auto;
    .amount .numbers,
    .text {
      font-size: 1em;
    }
  }
`

export default Movements
