import React from "react"
import styled from "styled-components"
// layout
import { colors } from "../../layout/constants"

const DetailButton = ({
  id,
  text,
  darkStyle,
  onClick,
  disabled,
  center,
  inactive
}) => {
  return (
    <ButtonStyles darkStyle={darkStyle} center={center} inactive={inactive}>
      <button
        id={id}
        onClick={() => (onClick ? onClick() : null)}
        disabled={disabled}
      >
        <span className="main-text">{text}</span>
      </button>
    </ButtonStyles>
  )
}

const ButtonStyles = styled.article`
  button {
    align-items: center;
    background-color: ${(props) =>
      props.inactive
        ? "transparent"
        : props.darkStyle
        ? colors.backgroundDark
        : colors.secondaryColor};
    border-radius: 30px;
    border: 2px solid
      ${(props) => (props.inactive ? colors.backgroundDark : "transparent")};
    color: ${(props) => (props.darkStyle ? colors.white : colors.darkText)};
    cursor: pointer;
    display: flex;
    justify-content: ${(props) => (props.center ? "center" : "space-between")};
    padding: 0.8em 1.5em;
    margin-right: 1em;
    transition: 0.4s background-color;

    :hover {
      background-color: ${(props) =>
        props.darkStyle ? colors.primaryLight : colors.secondaryDark};
    }

    :focus {
      outline: none;
    }

    :disabled {
      background-color: ${colors.disabled};
      color: darkgray;

      .detail,
      .highlight {
        color: darkgray;
      }
    }
  }

  .main-text {
    font-size: 1.1em;
    font-weight: 400;
  }

  .highlight {
    color: ${colors.highlightColor};
  }

  .detail {
    font-weight: 200;
    color: ${(props) => (props.darkStyle ? colors.lightText : colors.darkText)};
  }
`

export default DetailButton
