import React from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"

export default function InputSimulation({ value, updateState, type }) {
  return (
    <Container>
      {/* <Dollar>$</Dollar> */}

      <NumberFormat
        className="input"
        thousandSeparator
        thousandsGroupStyle="thousand"
        decimalScale={0}
        prefix="$"
        value={value !== undefined ? value : 0}
        onValueChange={(e) => updateState(e.floatValue)}
      />

      <Type>{type ? "USD" : "MXN"}</Type>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;

  .input {
    background: transparent;
    border: 0px;
    width: 11rem;
    font-size: 22px;
    outline: none;
    color: #38493c;
    font-family: "calibre";
    font-weight: 400;
    border-bottom: 1px solid #38493c;

    &::placeholder {
      font-size: 22px;
      color: black;
    }

    @media (min-width: 1024px) {
      color: #616b63;
    }

    @media print {
      width: 9rem;
    }
  }
`

const Type = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: #616b63;

  /* @media (min-width: 1024px) {
    color: #616b63;
  } */
`
