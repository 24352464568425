import currency from "currency.js"
import React from "react"
import styled from "styled-components"
import { buildingImage } from "../../../utils/format"
import { useState } from "react"
import { useEffect } from "react"
import { getBuildingTokens } from "../../../services/tokens"
import { useDispatch, useSelector } from "react-redux"
import { setAddToCart } from "../../../state/actions"

export default function Building({
  building,
  updateNewPrice,
  availableTokens
}) {
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  const cart = useSelector((state) => state.cart)
  const [quantity, setQuantity] = useState(0)
  const [tokens, setTokens] = useState()

  useEffect(() => {
    setOrder()
  }, [quantity])

  const onQuantityChange = (isIncreasing) => {
    if (quantity <= 0 && !isIncreasing) return
    if (quantity >= availableTokens) return
      setQuantity((prevValue) =>
        isIncreasing ? Number(prevValue) + 1 : Number(prevValue) - 1
      )
    updateNewPrice((prevValue) =>
      isIncreasing
        ? prevValue + Number(Number(building.valueMxn).toFixed(2))
        : prevValue - Number(Number(building.valueMxn).toFixed(2))
    )
  }

  const setOrder = async () => {
    if (cart[building.building_phase_id]) {
      cart[building.building_phase_id].quantity = quantity
    } else {
      cart[building.building_phase_id] = {
        quantity: quantity,
        name: building.name,
        value: building.valueMxn
      }
    }
    setAddToCart(dispatch, cart)
  }

  useEffect(() => {
    setQuantity(building.quantity)
  }, [building])

  useEffect(() => {
    const getTokenCount = async () => {
      const tokens = await getBuildingTokens(
        userToken,
        building.building_phase_id
      )
      setTokens(tokens)
    }
    getTokenCount()
  }, [])

  const isSoldOut = () => {
    const availableTokens = tokens.filter(
      (token) => token.available && token.type === "TOKEN-A"
    ).length
    return availableTokens === 0
  }

  return (
    <>
      {tokens && (
        <ContainerBuilding
          className={`${isSoldOut() ? "sold-out" : "available"}`}
        >
          <div className="container-img-name">
            <img
              className="building"
              srcSet={`${buildingImage(building.name)} 1x`}
              src={buildingImage(building.name)}
              alt={`imagen de ${building.name}`}
            />
            <div>
              <p className="name-building">{building.name}</p>
              <div className="container-price">
                <span className="price-building">
                  {currency(building.valueMxn, { precision: 0 }).format()}
                </span>
                <span className="price-building-usd">
                  {currency(building.valueUsd, { precision: 0 }).format()} USD
                </span>
              </div>
            </div>
          </div>
          <div className="container-price">
            <ContainerQuantity>
              <button onClick={() => onQuantityChange(false)}>-</button>
              <span className="quantity">{quantity}</span>
              <button onClick={() => onQuantityChange(true)}>+</button>
            </ContainerQuantity>
            <span className="price-building-usd">
              {availableTokens} m<sup>2</sup> disponibles
            </span>
          </div>
        </ContainerBuilding>
      )}
    </>
  )
}

const ContainerBuilding = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px dashed #aaa;
  border-bottom: 1px dashed #aaa;

  &.sold-out {
    display: none;
  }

  .container-img-name {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  p {
    margin: 0;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
  }

  .name-building {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .container-price {
    display: flex;
    flex-direction: column;
    .price-building {
      font-size: 1.3rem;
      font-weight: 500;
    }
    .price-building-usd {
      color: #aaa;
    }
  }
`

const ContainerQuantity = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  .quantity {
    font-size: 1.8rem;
    font-weight: 500;
    color: #f9a14d;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 2rem;
    color: #38493c;
    cursor: pointer;
  }
`
