import React from "react"
import styled from "styled-components"
// components
import SignupForm from "./signupForm"
// layout
import { mobileBreakpoint } from "../layout/constants"
import DefaultLayout from "../layout"
import Wrapper from "../layout/wrapper"
import { colors } from "../layout/constants"

const SignupView = () => {
  return (
    <DefaultLayout simple lightLogo>
      <SignupContainer>
        <Wrapper>
          <SignupGrid>
            <section>
              <h2 className="highlight-text">
                Crea tu cuenta para empezar a construir tu patrimonio.
              </h2>
            </section>
            <SignupForm light />
          </SignupGrid>
        </Wrapper>
      </SignupContainer>
    </DefaultLayout>
  )
}

const SignupContainer = styled.section`
  background-color: ${colors.primaryDark};
  color: ${colors.white};
`

const SignupGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  padding: 2em 0;

  .highlight-text {
    font-weight: 400;
    font-size: 2.2em;
  }

  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 1fr;
  }
`

export default SignupView
