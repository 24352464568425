import React, { useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
// components
import TokenList from "./tokenList"
import HistoricRecord from "./historicRecord"
import Movements from "./movements"
// layout
import { colors } from "../../layout/constants"
import Modal from "../../layout/shared/modal"
import ActionButton from "../../layout/shared/actionButton"
//services
import { editUser } from "../../../services/users"

const TokensView = ({ userReport, handleModalClick }) => {
  const [modal, setModal] = useState(
    userReport.userData.new_user ? true : false
  )
  // trail simulation
  const simulation = useSelector((state) => state.simulation)

  const showTour = async () => {
    setModal(false)
  }

  return (
    <>
      <TokensViewStyles>
        <TokenList
          balance={userReport.userBalance}
          lastBuyBuilding={userReport.lastBuyBuilding}
          userBuildings={userReport.buildings}
          handleModalClick={handleModalClick}
          // tokenToBuy={userReport.userReinvestSetting}
        />

        <HistoricRecord userReport={userReport} simulationValues={simulation} />

        <Movements
          userTransactions={
            userReport.transactions ? userReport.transactions.rows : []
          }
          userType={userReport.userType}
          handleModalClick={handleModalClick}
        />
      </TokensViewStyles>

      {modal ? (
        <Modal setModal={setModal}>
          <ModalContentStyled>
            <p className="highlight-text">
              ¡Wooo! Ya eres parte de nuestra comunidad de inversión y ya estás
              generando ganancias.
            </p>
            <p>
              Hemos confirmado tu pago y la firma de tu contrato. ¡Tu inversión
              ya está activa! Da click abajo para estrenar tu Tablero de
              Inversión.
            </p>
            <ActionButton
              left
              text="Llevame allá"
              onClick={() => showTour()}
            ></ActionButton>
          </ModalContentStyled>
        </Modal>
      ) : null}
    </>
  )
}

const TokensViewStyles = styled.section`
  /* animation-name: show; */
  animation-duration: 1s;
  animation-timing-function: ease-out;
  color: ${colors.darkText};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4em;
  margin: 1em 0;

  /* Shared Styles */
  .section-title {
    color: ${colors.darkText};
    font-size: 1.2em;
    font-weight: 500;
    margin: 1em 0;
  }

  .welcome-message {
    grid-column: 1/4;
    font-weight: 400;

    span {
      color: ${colors.secondaryDark};
    }
  }

  @keyframes show {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    padding: 0 15px;

    .welcome-message {
      grid-column: 1/2;
    }
  }
`

const ModalContentStyled = styled.div`
  .highlight-text {
    font-size: 1.2em;
    font-weight: 500;
  }
`

export default TokensView
