const InterestRate = 0.06
const MonthlyInterestRate = Math.pow(1 + InterestRate, 1 / 12) - 1

export function calculateSimulation(
  monthlyPayment,
  yearlyIncrease,
  downPayment,
  startingTokens,
  currency = "MXN"
) {
  // Initial setup
  let totalInvestment = downPayment
  let pricePerToken = currency === "MXN" ? 55000 : 55000 / 17.4
  let ownedTokens = startingTokens
  let totalTokensValue = startingTokens * pricePerToken
  let monthlyTokenRent = currency === "MXN" ? 250 : 250 / 17.4
  let monthlyRentTotal = 0
  let agregatedRent = 0
  let agregateAppreciation = 0

  // Set return array
  const ListByMonth = []

  // Year Cycle
  for (let i = 0; i < 252; i++) {
    if (i !== 0) {
      // Add monthly payment
      totalInvestment += monthlyPayment
      // Add Rent
      monthlyTokenRent = monthlyTokenRent * (1 + MonthlyInterestRate)
      monthlyRentTotal = ownedTokens * monthlyTokenRent
      agregatedRent += monthlyRentTotal
      // Buy Tokens with suscription and rent
      ownedTokens =
        ownedTokens +
        monthlyPayment / pricePerToken +
        monthlyRentTotal / pricePerToken
    } else {
      // Buy Tokens with down payment
      ownedTokens = ownedTokens + downPayment / pricePerToken
    }

    totalTokensValue = ownedTokens * pricePerToken

    // add month values to Return array
    ListByMonth.push({
      month: i,
      totalInvestment: Math.round(totalInvestment),
      monthlyInterestRate: MonthlyInterestRate,
      pricePerToken: Math.round(pricePerToken),
      ownedTokens: Math.round(ownedTokens * 100) / 100,
      totalTokensValue: Math.round(totalTokensValue),
      monthlyTokenRent: Math.round(monthlyTokenRent),
      monthlyRentTotal: Math.round(monthlyRentTotal),
      agregatedRent: Math.round(agregatedRent),
      agregateAppreciation: Math.round(agregateAppreciation)
    })

    // Next Month setup
    pricePerToken = pricePerToken * (1 + MonthlyInterestRate)
    agregateAppreciation += pricePerToken * MonthlyInterestRate * ownedTokens
    // Next year setup
    monthlyPayment =
      i !== 0 && i % 12 === 0 ? monthlyPayment + yearlyIncrease : monthlyPayment
  }

  return ListByMonth
}

export function calculateSimulationByYear(
  monthlyPayment,
  yearlyIncrease,
  downPayment,
  startingTokens,
  currency
) {
  const byMonth = calculateSimulation(
    monthlyPayment,
    yearlyIncrease,
    downPayment,
    startingTokens,
    currency
  )
  const byYear = []

  for (let row of byMonth) {
    if (row.month % 12 === 0) {
      byYear.push({
        ...row,
        year: row.month / 12,
        yearRent: calculateYearRent(
          byMonth.slice(row.month + 2 - 12, row.month + 2)
        )
      })
    }
  }

  return byYear
}

function calculateYearRent(yearList) {
  // console.log(yearList)
  return yearList.reduce((yearRent, monthRent) => {
    return yearRent + monthRent.monthlyRentTotal
  }, 0)
}
