import React, { useState } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
// Material UI
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
// layout
import { colors } from "../layout/constants"
import ActionButton from "../layout/shared/actionButton"
import CountrySelect from "../layout/shared/countrySelect"
// services
import { editUser } from "../../services/users"
import { setUserData } from "../../state/actions"

const PersonForm = ({ setStep }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const userData = useSelector((state) => state.userData)
  const [country, setCountry] = useState(userData?.citizenship)

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm()

  const onSubmit = async (data) => {
    data.citizenship = country
    data.tax_regime = country !== "Mexico" ? "P_FISICA_EX_USA" : "P_FISICA"
    try {
      await editUser(user.token, user.id, data)
      setUserData(dispatch, data)
      setStep(2)
    } catch (error) {
      console.error(error)
    }
  }

  const validateAge = (date) => {
    const birthDay = moment(date)
    const age = moment().diff(birthDay, "years")
    if (age < 18) {
      return false
    }
    return true
  }

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Información personal</h3>

        <div className="input-row">
          <TextField
            fullWidth
            label="Nombre(s)"
            variant="outlined"
            error={errors.name ? true : false}
            helperText={
              errors.name?.type === "required" && "El campo es requerido"
            }
            inputProps={{ ...register("name", { required: true }) }}
            defaultValue={userData?.name}
          />
        </div>
        <div className="input-row">
          <TextField
            fullWidth
            label="Apellidos"
            variant="outlined"
            error={errors.last_name ? true : false}
            helperText={
              errors.last_name?.type === "required" && "El campo es requerido"
            }
            inputProps={{ ...register("last_name", { required: true }) }}
            defaultValue={userData?.last_name}
          />
        </div>

        <div className="input-row">
          <TextField
            id="date"
            fullWidth
            label="Fecha de nacimiento"
            type="date"
            variant="outlined"
            error={errors.birthday_date ? true : false}
            helperText={errors.birthday_date && "Debe ser mayor de 18 años"}
            inputProps={{
              ...register("birthday_date", {
                required: true,
                validate: validateAge
              })
            }}
            defaultValue={
              userData?.birthday_date
                ? userData.birthday_date
                : moment().format("YYYY-MM-DD")
            }
          />
        </div>

        <div className="input-row">
          <CountrySelect
            fullWidth
            label="Nacionalidad"
            onChange={(value) => setCountry(value.label)}
            userValue={userData?.citizenship ? userData?.citizenship : ""}
          />
        </div>

        {country && country !== "Mexico" && (
          <>
            <div className="input-row">
              <TextField
                fullWidth
                label="Número de pasaporte"
                variant="outlined"
                error={errors.name ? true : false}
                helperText={
                  errors.name?.type === "required" && "El campo es requerido"
                }
                inputProps={{
                  ...register("passport_number", { required: true })
                }}
              />
            </div>
            <div className="input-row">
              <TextField
                fullWidth
                label="Número identifcación fiscal de país"
                variant="outlined"
                error={errors.name ? true : false}
                helperText={
                  errors.name?.type === "required" && "El campo es requerido"
                }
                inputProps={{
                  ...register("national_id_number", { required: true })
                }}
              />
            </div>
          </>
        )}

        <div className="input-row">
          <TextField
            fullWidth
            select
            label="Estado civil"
            variant="outlined"
            error={errors.marital_status ? true : false}
            helperText={
              errors.marital_status?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{ ...register("marital_status", { required: true }) }}
            defaultValue={
              userData?.marital_status ? userData?.marital_status : ""
            }
          >
            <MenuItem value="soltero">Soltero</MenuItem>
            <MenuItem value="casado por sociedad conyugal">
              Casado por Sociedad Conyugal
            </MenuItem>
            <MenuItem value="casado por separación de bienes">
              Casado por Separación de Bienes
            </MenuItem>
          </TextField>
        </div>

        <TextField
          fullWidth
          label="Teléfono"
          variant="outlined"
          type="tel"
          error={errors.phone ? true : false}
          helperText={
            errors.phone?.type === "required" && "El campo es requerido"
          }
          inputProps={{ ...register("phone", { required: true }) }}
          defaultValue={userData?.phone}
        />

        <ActionButton text="Siguiente" type="submit" />
      </form>
    </FormWrapper>
  )
}

const FormWrapper = styled.section`
  .input-row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }

  .submit-button {
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 600px) {
    .input-row {
      flex-direction: column;
    }
  }
`

export default PersonForm
