import currency from "currency.js"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import Layout from "../../layout"
import { Link } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"
import { createTransaction } from "../../../services/transactions"
import { editUser } from "../../../services/users"
import Loader from "../../reports/loader"
import {
  openCheckoutSession,
  getCheckoutSession
} from "../../../services/stripe"
import { useSelector, useDispatch } from "react-redux"
import { setAddToCart } from "../../../state/actions"
import Tooltip from "../../layout/shared/tooltip"
import InputSimulation from "../InputSimulation"
import { buildingImage } from "../../../utils/format"

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-check"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#7bc62d"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l5 5l10 -10" />
    </svg>
  )
}

export default function PaySimulator() {
  let query = useQuery()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const cart = useSelector((state) => state.cart)
  const [initialPayment, setInitialPayment] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [spei, setSpei] = useState(false)
  const [showLoader, setShowLoader] = useState(true)

  const onHandleStripeSubscription = async () => {
    const { data } = await openCheckoutSession(
      user.token,
      initialPayment,
      subscription,
      "subscription",
      user.id
    )
    const urlStripe = data.url
    window.open(urlStripe, "_self")
  }

  const getSessionDetails = async (session_id, subscriptionAmount) => {
    const session = await getCheckoutSession(user.token, session_id)
    const rfc = user.report.userRfc
      ? user.report.userRfc
      : session.custom_fields[0].text.value
    if (!user.report.userRfc) {
      await editUser(user.token, user.id, {
        rfc: rfc
      })
    }
    await createTransaction(user.token, {
      transaction_date: moment(),
      type: "COBRANZA_DEPOSITO",
      user_rfc: rfc,
      user_id: user.id,
      subtotal: session.amount_total / 100,
      stripe_id: session.payment_intent
    })

    // Update redux state of cart
    const newCart = {}
    for (const building in cart) {
      newCart[building] = cart[building]
      newCart[building].quantity = 0
    }

    setAddToCart(dispatch, newCart)

    window.fbq("trackCustom", "PAGO", {
      currency: "MXN",
      value: session.amount_total / 100
    })

    setInitialPayment(session.amount_total / 100)
    setSubscription(subscriptionAmount)
    setShowLoader(false)
  }

  useEffect(() => {
    let initialPaymentAmount = Number(query.get("initial"))
    let subscriptionAmount = Number(query.get("monthly"))
    setSpei(query.get("spei"))
    let speiAmount = Number(query.get("amount"))
    let session_id = query.get("session_id")

    if (session_id) {
      getSessionDetails(session_id, subscriptionAmount)
    } else if (speiAmount) {
      setInitialPayment(speiAmount)
      setSubscription(subscriptionAmount)
      setShowLoader(false)
    } else {
      setInitialPayment(initialPaymentAmount)
      setSubscription(subscriptionAmount)
      setShowLoader(false)
    }
  }, [])

  return (
    <Layout dashboard lightLogo>
      <Container>
        {!showLoader ? (
          <>
            {subscription === 0 ? (
              <>
                <img src="/logo-urvita-min-light.png" alt="logo-urvita" />
                <div className="container-congrats">
                  <div>
                    <h3>¡Felicidades!</h3>
                    <p>
                      Haz completado el proceso. Podrás ver reflejados tus
                      movimientos y tus m<sup>2</sup> en tu tablero.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <h2>¡Compra procesada!</h2>
            )}
            <div className="paid">
              <p className="description">Hemos procesado tu compra por:</p>
              <div className="container-icon">
                <p className="label">Monto</p>
                {spei ? (
                  <div className="verification">
                    Verificando{" "}
                    <Tooltip
                      content="Estamos verificando tu transacción. Veras reflejado el incremento de tu balance en cuanto recibamos la confirmación del banco."
                      dark
                    />
                  </div>
                ) : (
                  <CheckIcon />
                )}
              </div>
              <p className="amount">
                {currency(initialPayment, { precision: 0 }).format()} mxn
              </p>
            </div>

            {subscription !== 0 ? (
              <div>
                <p className="description">
                  Continua para programar tu compra mensual por:
                </p>
                <p className="label">Monto:</p>
                <div className="container-input">
                  <InputSimulation
                    value={subscription}
                    updateState={setSubscription}
                  />
                  <div className="flex-container">
                    <button onClick={onHandleStripeSubscription}>
                      Continuar
                    </button>
                    <Link
                      to={`/checkout?initial=${initialPayment}&noSubscription=true`}
                    >
                      Lo haré en otro momento
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <Link className="container-button" to="/tablero/tokens">
                <button>Ir a tu Tablero</button>
              </Link>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2rem;
  background-color: #fffaf5;

  img {
    width: 100px;
  }

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 500;
    text-align: center;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 2rem;
    align-items: center;
    margin-top: 20px;

    button {
      margin-top: 0;
    }
  }

  .paid {
    border-bottom: 1px dashed #aaa;
    padding-bottom: 2rem;
    p {
      color: #aaa;
    }

    .container-icon {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;

      .verification {
        flex-direction: row;
        gap: 10px;
        border-radius: 8px;
        font-size: 0.8em;
        background-color: #f4e2b5;
        padding: 4px 5px;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;

    .description {
      font-size: 1.3rem;
      color: #aaa;
    }

    p {
      margin: 0;
    }

    .label {
      font-size: 1.3rem;
      color: #aaa;
    }

    .container-input {
      width: min-content;
      div {
        flex-direction: row;
        gap: 10px;
      }

      input {
        font-size: 1.9rem;
      }

      input::placeholder {
        font-size: 1.9rem;
      }
    }

    .amount {
      font-size: 1.9rem;
      font-weight: 500;
    }

    .button-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 1em;
    }

    button {
      background-color: #fecfa4;
      padding: 15px 28px;
      width: max-content;
      margin-top: 30px;
      font-weight: bold;
      border-radius: 30px;
      font-size: 1rem;
      font-family: "calibre";
      border: none;
      color: black;
      cursor: pointer;
    }

    button:hover {
      background-color: #f9c27a;
    }
  }

  .container-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: #435b49;
      font-family: "calibre";
      font-size: 1rem;
      border: none;
      border-radius: 20px;
      padding: 10px 2rem;
      color: white;
      font-size: 1.1rem;
      cursor: pointer;
    }

    button:hover {
      background-color: #546b5a;
    }
  }

  @media (min-width: 1023px) {
    max-width: 600px;
    margin: 0 auto;
  }
`
