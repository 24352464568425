import React from "react"
import styled from "styled-components"
// components
import BuildingCard from "./buildingCard"
import { BuildingsModal } from "./buildingsModal"
// layout
import { colors } from "../../layout/constants"
import BuildingsChart from "./buildingsChart"
import Wrapper from "../../layout/wrapper"

const BuildingsList = ({ buildings, selectBuilding, lastValues }) => {
  const filterValueByBuilding = (building) => {
    return lastValues.find(
      (value) => value.building_phase_id === building.phase_id
    )
  }

  return (
    <ListContainer>
      <Wrapper>
        <CardList>
          <header className="info-header">
            <h2 className="sub-title">Edificios</h2>
          </header>
          <ul className="list">
            {buildings &&
              lastValues &&
              buildings.map(
                (building) =>
                  building.phase_id !== "EVAP001" && (
                    <li key={building.id}>
                      <BuildingCard
                        building={building}
                        selectBuilding={selectBuilding}
                        lastValues={filterValueByBuilding(building)}
                      />
                    </li>
                  )
              )}
          </ul>
        </CardList>
      </Wrapper>
      {/* <BuildingsModal /> */}
      <BuildingsChart />
    </ListContainer>
  )
}

export default BuildingsList

const ListContainer = styled.section`
  display: grid;
  /* grid-gap: 2rem; */
  background-color: #fff8f3;
  /* grid-template-columns: 1fr 1fr 1fr; */

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }

  .title {
    font-family: calibre;
    font-weight: 200;
    font-size: 3rem;
    margin: 1rem 0;
  }

  .sub-title {
    font-family: calibre;
    font-weight: 200;
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
`

const ListDescription = styled.section``

const CardList = styled.section`
  .info-header {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 300px);
    place-content: center;

    h2 {
      margin: 0.6rem 0px;
      font-size: 2rem;
    }
    /* justify-content: space-between; */
    /* align-items: center; */

    span {
      color: ${colors.helperGray};
    }

    @media (max-width: 968px) {
      grid-template-columns: 1fr;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    place-content: center;
    grid-gap: 2rem;
    /* grid-gap: 2rem; */
    list-style: none;
    padding: 0;

    @media (max-width: 968px) {
      grid-template-columns: 1fr;

      .info-header {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`
