import React, { useState } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// components
import KycModal from "../../kyc"
// utils
import { colors } from "../../layout/constants"
import ActionButton from "../../layout/shared/actionButton"
import { setAddToCart } from "../../../state/actions"
import { useEffect } from "react"
import { getBuildingTokens } from "../../../services/tokens"

const BuildingCart = ({ building, values }) => {
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  const cart = useSelector((state) => state.cart)
  const [modal, setModal] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [tokens, setTokens] = useState()

  useEffect(() => {
    const getTokenCount = async () => {
      const tokens = await getBuildingTokens(userToken, building.phase_id)
      setTokens(tokens)
    }
    getTokenCount()
  }, [building.phase_id, userToken])

  const isSoldOut = () => {
    const availableTokens = tokens.filter(
      (token) => token.available && token.type === "TOKEN-A"
    ).length
    return availableTokens === 0
  }

  useEffect(() => {
    if (cart[building.phase_id]) {
      const quantityBuilding = cart[building.phase_id].quantity
      setCartCount(quantityBuilding)
    }
  }, [building, cart])

  const onQuantityChange = (isIncreasing) => {
    if (cartCount <= 0 && !isIncreasing) return
    setCartCount((prevValue) =>
      isIncreasing ? Number(prevValue) + 1 : Number(prevValue) - 1
    )
  }

  const setOrder = async () => {
    // TO DO CONNECT WITH BACKEND - UPDATE ORDER

    if (cart[building.phase_id]) {
      cart[building.phase_id].quantity = cartCount
    } else {
      cart[building.phase_id] = {
        quantity: cartCount,
        name: building.name,
        value: values.value_a
      }
    }
    setAddToCart(dispatch, cart)
  }

  return (
    <CartContainer>
      {modal && <KycModal setModal={setModal}></KycModal>}
      {/* <NumberFormat
        className="token-cost"
        value={getPrice()}
        displayType={"text"}
        prefix="$"
        thousandSeparator={true}
      /> */}
      {/* <div className="controls">
        <button
          onClick={() => {
            updateCart(false);
          }}
        >
          -
        </button>
        <p>{totalTokens} Tokens</p>
        <button
          onClick={() => {
            updateCart(true);
          }}
        >
          +
        </button>
      </div> */}

      {tokens && !isSoldOut() && (
        <div className="container-cart">
          <div className="container-buttons">
            <button onClick={() => onQuantityChange(false)}>-</button>
            <span className="quantity">{cartCount}</span>
            <button onClick={() => onQuantityChange(true)}>+</button>
          </div>
          <ActionButton
            id="cart"
            onClick={() => setOrder()}
            text="Agregar al carrito"
          ></ActionButton>
        </div>
      )}
       <Link to="/selecciona-fracciones">
        <ActionButton
          id="chatbot"
          onClick={() => setOrder()}
          text="Ir al carrito"
        ></ActionButton>
      </Link>
    </CartContainer>
  )
}

const CartContainer = styled.section`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
  margin-top: 2rem;
  justify-content: space-between;

  .container-cart {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    .container-buttons {
      padding: 5px 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border: 1px solid #aaa;

      .quantity {
        font-size: 1.8rem;
        font-weight: 500;
        color: #f9a14d;
      }

      button {
        border: none;
        background-color: transparent;
        font-size: 2rem;
        color: #38493c;
        cursor: pointer;
      }
    }
  }

  .token-cost {
    font-size: 2.2rem;
    text-align: center;
    display: block;
  }

  .controls {
    align-items: center;
    display: flex;
    justify-content: space-around;

    p {
      font-size: 1.2rem;
      color: ${colors.helperGray};
      margin: 0;
    }

    button {
      border-radius: 100%;
      border: 2px solid ${colors.gridGray};
      background-color: ${colors.backgroundWhite};
      cursor: pointer;
      font-size: 1rem;
      height: 2rem;
      width: 2rem;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 0px;
  }
`

export default BuildingCart
