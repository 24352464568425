import React, { useState } from "react"
import styled from "styled-components"
import moment from "moment"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
// layout
import { colors, months, countries } from "../../layout/constants"
import ActionButton from "../../layout/shared/actionButton"
// services
import { editUser } from "../../../services/users"

const ProfileForm = ({ userInfo }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updated, setUpdated] = useState(false)
  // inputs
  const [name, setName] = useState(userInfo.name ? userInfo.name : "")
  const [nameError, setNameError] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState("")
  const [lastName, setLastName] = useState(
    userInfo.last_name ? userInfo.last_name : ""
  )
  const [lastNameError, setLastNameError] = useState(false)
  const [phone, setPhone] = useState(userInfo.phone ? userInfo.phone : "")
  const [idType, setIdType] = useState(userInfo.id_type || "")
  const [idNumber, setIdNumber] = useState(userInfo.id_number || "")
  const [martialStatus, setMartialStatus] = useState(
    userInfo.marital_status || ""
  )
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("")

  const [birthday, setBirthday] = useState(
    userInfo.birthday_date
      ? moment(userInfo.birthday_date).date()
      : moment().date()
  )
  const [birthMonth, setBirthMonth] = useState(
    userInfo.birthday_date
      ? moment(userInfo.birthday_date).month() + 1
      : moment().month()
  )
  const [birthYear, setBirthYear] = useState(
    userInfo.birthday_date
      ? moment(userInfo.birthday_date).year()
      : moment().year()
  )
  const [birthdayError, setBirthdayError] = useState(false)
  const [birthdayErrorMessage, setBirthdayErrorMessage] = useState("")

  const [citizenship, setCitizenship] = useState(
    userInfo.citizenship ? userInfo.citizenship : ""
  )

  const getBirthdayDate = () => {
    const birthdayDate = `${birthYear}-${birthMonth}-${birthday}`
    return moment(birthdayDate)
  }

  const validateForm = () => {
    let isValid = true
    if (name === "") {
      setNameError(true)
      setNameErrorMessage("No puede quedar vacío")
      isValid = false
    }
    if (lastName === "") {
      setLastNameError(true)
      setLastNameErrorMessage("No puede quedar vacío")
      isValid = false
    }

    if (getBirthdayDate().isAfter(moment()) || !getBirthdayDate()._isValid) {
      setBirthdayError(true)
      setBirthdayErrorMessage("Fecha invalida")
      isValid = false
    }
    return isValid
  }

  const saveForm = async () => {
    const isValid = validateForm()
    if (!isValid) {
      return
    }

    const newData = {
      name,
      last_name: lastName,
      birthday_date: getBirthdayDate().format("YYYY-MM-DD"),
      citizenship,
      trail_step: userInfo.trail_step === 0 ? 1 : userInfo.trail_step,
      phone: phone,
      martial_status: martialStatus,
      id_type: idType,
      id_number: idNumber
    }

    setLoading(true)
    try {
      const editedUser = await editUser(userToken, userInfo.id, newData)
      setLoading(false)
      if (editedUser.error) {
        setError(true)
        return
      }
      setUpdated(true)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setError(true)
    }
  }

  return (
    <FormStyled>
      {userInfo.user_type === "trail" && updated ? (
        <Redirect to="/tablero/perfil/fiscales" />
      ) : null}
      <h2 className="section-title">
        Información{" "}
        {userInfo.tax_regime === "P_MORAL"
          ? "del Representante Legal"
          : "Personal"}
      </h2>
      <section>
        <h3 className="row-title">Identificación</h3>
        <div className="input-row">
          <TextField
            className="input"
            label="Nombre o razón social"
            variant="outlined"
            error={nameError}
            helperText={nameErrorMessage}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            className="input"
            label="Apellidos"
            variant="outlined"
            error={lastNameError}
            helperText={lastNameErrorMessage}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>

        <div className="input-row">
          <TextField
            className="input"
            label="Teléfono"
            variant="outlined"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
          <TextField
            select
            label="Estado civil"
            variant="outlined"
            onChange={(event) => setMartialStatus(event.target.value)}
            defaultValue={
              userInfo?.marital_status ? userInfo?.marital_status : ""
            }
          >
            <MenuItem value="soltero">Soltero</MenuItem>
            <MenuItem value="casado por sociedad conyugal">
              Casado por Sociedad Conyugal
            </MenuItem>
            <MenuItem value="casado por separación de bienes">
              Casado por Separación de Bienes
            </MenuItem>
          </TextField>
        </div>

        <div className="input-row">
          <TextField
            select
            label="Tipo de ID"
            variant="outlined"
            onChange={(event) => setIdType(event.target.value)}
            defaultValue={userInfo?.id_type ? userInfo?.id_type : ""}
          >
            <MenuItem value="INE">INE</MenuItem>
            <MenuItem value="PASSPORT">Pasaporte</MenuItem>
          </TextField>

          <TextField
            label={`Número de ${idType}`}
            variant="outlined"
            onChange={(event) => setIdNumber(event.target.value)}
            defaultValue={userInfo?.id_number || ""}
          />
        </div>

        <h3 className="row-title">Fecha de nacimiento</h3>
        <div className="input-row">
          <TextField
            className="input day"
            type="number"
            label="Día"
            variant="outlined"
            error={birthdayError}
            value={birthday}
            onChange={(event) => setBirthday(event.target.value)}
          />
          <TextField
            className="input month"
            select
            label="Mes"
            variant="outlined"
            error={birthdayError}
            helperText={birthdayErrorMessage}
            value={birthMonth}
            onChange={(event) => setBirthMonth(event.target.value)}
          >
            {months.map((month, index) => (
              <MenuItem key={month} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="input year"
            type="number"
            label="Año"
            variant="outlined"
            error={birthdayError}
            value={birthYear}
            onChange={(event) => setBirthYear(event.target.value)}
          />
        </div>

        <h3 className="row-title">Ubicación</h3>
        <div className="input-row">
          <TextField
            select
            className="input country"
            label="Nacionalidad"
            variant="outlined"
            value={citizenship}
            onChange={(event) => setCitizenship(event.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <ActionButton
          text={loading ? "Guardando..." : "Guardar"}
          left
          onClick={() => saveForm()}
          disabled={loading}
          helperText={updated ? "Información actualizada" : null}
          error={error}
        />
      </section>
    </FormStyled>
  )
}

const FormStyled = styled.section`
  .row-title {
    font-weight: 200;
  }

  .input-row {
    display: flex;
    margin: 0 0 20px 0;
  }

  .input {
    margin-right: 10px;
  }

  .day {
    width: 100px;
  }

  .month,
  .country {
    width: 150px;
  }

  .year {
    width: 100px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 968px) {
    .day {
      width: 60px;
    }

    .month,
    .country {
      width: 120px;
    }

    .year {
      width: 80px;
    }
  }
`

export default ProfileForm
