import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { removeUserSession } from "../../../utils/sesionManagment"
import { useSelector, useDispatch } from "react-redux"
import { Background } from "./styles"

import Navbar from "./navbar"
import LateralMenu from "./lateralMenu"

const Nav = ({ simple, lightLogo, dashboard, handleModalClick }) => {
  const history = useHistory()
  const userReport = useSelector((state) => state.user.report)
  const dispatch = useDispatch()

  // Manage Logout
  const LOGOUT = () => {
    dispatch({
      type: "LOGOUT"
    })
  }

  const logoutHandler = () => {
    history.push("/login")
    window.Intercom("shutdown")
    window.Intercom("boot", { app_id: "otyuzn6u" })
    removeUserSession()
    LOGOUT()
  }

  // Lateral menu handler
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuClick = () => {
    setShowMenu(!showMenu)
  }

  return (
    <>
      <Navbar
        userName={userReport ? userReport.userName : ""}
        simple={simple}
        lightLogo={lightLogo}
        handleMenuClick={handleMenuClick}
        handleModalClick={handleModalClick}
        logoutHandler={logoutHandler}
        dashboard={dashboard}
      />
      <LateralMenu
        handleMenuClick={handleMenuClick}
        userType={userReport ? userReport.userType : "trail"}
        userReport={userReport}
        showMenu={showMenu}
        logoutHandler={logoutHandler}
        dashboard={dashboard}
      />
      <Background showMenu={showMenu} onClick={handleMenuClick} />
    </>
  )
}

export default Nav
