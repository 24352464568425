import React from "react"
import styled from "styled-components"
// layout
import { colors } from "../../layout/constants"
import Tooltip from "../../layout/shared/tooltip"
// components
import TokenGraph from "./tokenGraph"

const HistoricRecord = ({ userReport, simulationValues }) => {
  const currentValue = () => {
    const totalValue = userReport.buildings?.reduce((count, building) => {
      return count + building.userTokensValue
    }, 0)
    return totalValue
  }

  return (
    <HistoricRecordStyles>
      <h3 className="section-title">
        <span>
          Valor histórico{" "}
          <Tooltip
            content="Esta gráfica muestra el valor histórico de tus m2 completados con Urvita."
            dark
          />
        </span>
      </h3>

      <TokenGraph
        records={
          userReport?.tokenValueRecord?.length
            ? userReport.tokenValueRecord
            : [{ period: userReport.period, totalValue: currentValue() }]
        }
        balance={userReport.userBalance}
      />
    </HistoricRecordStyles>
  )
}

const HistoricRecordStyles = styled.section`
  .month-gain {
    display: flex;
    justify-content: center;
  }

  .month-number {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .separator {
    color: ${colors.secondaryColor};
    font-size: 24px;
    font-weight: 600;
    margin: 0 10px;
  }

  img {
    border: 1px solid ${colors.primaryColor};
    box-sizing: border-box;
    width: 100%;
  }
`

export default HistoricRecord
