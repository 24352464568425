import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import moment from "moment"
import currency from "currency.js"
import { getAllBuildingsRecords } from "../../../services/trackRecord"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from "recharts"

export default function BuildingsCharts() {
  const user = useSelector((state) => state.user)
  // Chart Setup
  const [rawValues, setRawValues] = useState([])
  const [posibleYears, setPosibleYears] = useState([])
  // Chart values
  const [historicValues, setHistoricValues] = useState([])
  const [occupancy, setOccupancy] = useState([])
  const [gains, setGains] = useState([])
  // Chart Options
  const [activeChart, setActiveChart] = useState("historic")
  const [focusedLine, setFocusedLine] = useState()
  const [activeSeries, setActiveSeries] = useState([]) // To hide legends
  const [visualization, setVisualization] = useState("historic") // Time to Show

  const getRawValues = async () => {
    const values = await getAllBuildingsRecords(user.token)
    // Fixing Noe building name
    values.forEach((value) =>
      value.building_name === "NOE "
        ? (value.building_name = "NOE")
        : value.building_name
    )
    setRawValues(values)
    getPosibleYears(values)
  }

  const getPosibleYears = (values) => {
    const years = values.map((value) => {
      return moment(value.period).format("YYYY")
    })
    const uniqueYears = [...new Set(years)]
    const sortedYears = uniqueYears.sort((a, b) => b - a)
    setPosibleYears(sortedYears)
  }

  const getValuesForCharts = () => {
    // Filter not relevant buildings
    const filteredValues = (type) => {
      const validation = (value) =>
        type === "historic"
          ? value.building_name !== "EVA"
          : value.building_name !== "EVA" &&
            value.building_name !== "JUANITO" &&
            value.building_name !== "HERNAN"
      return rawValues.filter((value) => validation(value))
    }

    // Manage Total Values
    const totalValues = filteredValues("historic").map((value) => {
      return {
        name: value.building_name,
        date: value.period,
        value: Number(value.value_a)
      }
    })

    const TotalResult = setStandardFormat(totalValues)
    setHistoricValues(TotalResult)

    // Manage Occupancy
    const occupancy = filteredValues("occupancy").map((value) => {
      return {
        name: value.building_name,
        date: value.period,
        value: Number(value.occupancy)
      }
    })

    const occupationResult = setStandardFormat(occupancy)
    setOccupancy(occupationResult)

    // Manage Gains
    const gains = filteredValues("gains").map((value) => {
      return {
        name: value.building_name,
        date: value.period,
        value: Number(value.noi_a)
      }
    })

    const gainsResult = setStandardFormat(gains)
    setGains(gainsResult)
  }

  const setStandardFormat = (values) => {
    const formattedValues = values.reduce((list, record) => {
      const index = list.findIndex((item) => item.name === record.date)
      if (index === -1) {
        list.push({
          name: record.date,
          [record.name]: Number(record.value)
        })
      } else {
        list[index][record.name] = Number(record.value)
      }
      return list
    }, [])

    const sortedValues = formattedValues.sort((a, b) => {
      const dateA = new Date(a.name)
      const dateB = new Date(b.name)
      return dateA - dateB
    })

    const limitDataPoints = () => {
      if (visualization === "historic") {
        return sortedValues
      } else {
        return sortedValues.filter((record) => {
          const year = moment(record.name).format("YYYY")
          return year === visualization
        })
      }
    }

    return limitDataPoints()
  }

  useEffect(() => {
    getRawValues()
  }, [])

  useEffect(() => {
    getValuesForCharts()
  }, [rawValues, visualization])

  // Axis Formatters
  const customDateAxis = ({ payload, x, y }) => {
    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dy={payload.index % 2 === 0 ? 40 : 20}
      >{`${moment(payload.value).format("MMM-YYYY")}`}</text>
    )
  }

  const customYAxis = ({ payload, x, y }) => {
    return (
      <text x={x} y={y} textAnchor="middle" dx={-25}>{`${
        activeChart === "occupancy"
          ? payload.value * 100 + "%"
          : currency(payload.value, { precision: 0 }).format()
      }`}</text>
    )
  }

  const getSelectedValues = () => {
    if (activeChart === "historic") {
      return historicValues
    } else if (activeChart === "occupancy") {
      return occupancy
    } else if (activeChart === "gains") {
      return gains
    }
  }

  // Hide and Show legends
  const handleLegendClick = (dataKey) => {
    if (activeSeries.includes(dataKey)) {
      setActiveSeries(activeSeries.filter((el) => el !== dataKey))
    } else {
      setActiveSeries((prev) => [...prev, dataKey])
    }
  }

  const handleLegendFocus = (dataKey, active) => {
    if (active) {
      setFocusedLine(dataKey)
    } else {
      setFocusedLine()
    }
  }

  const renderLegend = ({ payload }) => {
    return (
      <ChartLegend>
        {payload.map((entry, index) => {
          return (
            <li
              key={`item-${index}`}
              className={`${activeSeries.includes(entry.dataKey) && "hide"}`}
              onClick={() => handleLegendClick(entry.dataKey)}
              onMouseOver={() => handleLegendFocus(entry.dataKey, true)}
              onMouseLeave={() => handleLegendFocus(entry.dataKey, false)}
              style={{
                background: activeSeries.includes(entry.dataKey)
                  ? "transparent"
                  : entry.color,
                borderColor: entry.color,
                color: activeSeries.includes(entry.dataKey)
                  ? entry.color
                  : "white"
              }}
            >
              {entry.value}
            </li>
          )
        })}
      </ChartLegend>
    )
  }

  return (
    <Container>
      <ContainerCharts className="container-charts">
        <h2>Comparar</h2>
        <section>
          <div className="container-options">
            <RadioOption>
              <Input
                type="radio"
                id="historic-value-option"
                name="plan"
                value="historic-value-option"
                onChange={() => {
                  setActiveChart("historic")
                  setActiveSeries([])
                }}
                defaultChecked
                required
              />
              <Label htmlFor="historic-value-option">
                <h3>Valor Histórico</h3>
                <p>
                  Gráfica{" "}
                  {activeChart === "historic" ? "activada" : "desactivada"}
                </p>
              </Label>
            </RadioOption>
            <RadioOption>
              <Input
                type="radio"
                id="occupancy-option"
                name="plan"
                value="occupancy-option"
                onChange={() => {
                  setActiveChart("occupancy")
                  setActiveSeries(["HERNAN", "JUANITO"])
                }}
                required
              />
              <Label htmlFor="occupancy-option">
                <h3>Ocupación</h3>
                <p>
                  Gráfica{" "}
                  {activeChart === "occupancy" ? "activada" : "desactivada"}
                </p>
              </Label>
            </RadioOption>
            <RadioOption>
              <Input
                type="radio"
                id="occupancy-2-option"
                name="plan"
                value="occupancy-2-option"
                onChange={() => {
                  setActiveChart("gains")
                  setActiveSeries(["HERNAN", "JUANITO"])
                }}
                required
              />
              <Label htmlFor="occupancy-2-option">
                <h3>Ganancias Mensuales</h3>
                <p>
                  Gráfica {activeChart === "gains" ? "activada" : "desactivada"}
                </p>
              </Label>
            </RadioOption>
          </div>
          <div className="chart-title">
            <p style={{ margin: 0 }}>Comparativa de Edificios</p>
            <div>
              <p style={{ margin: 0 }}></p>
              <select
                onChange={(event) => setVisualization(event.target.value)}
              >
                <option value="historic">Histórico</option>
                {posibleYears &&
                  posibleYears.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="chart">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={600} height={300} data={getSelectedValues()}>
                <CartesianGrid strokeDasharray="1" horizontal={false} />
                <XAxis axisLine={false} dataKey="name" tick={customDateAxis} />
                <YAxis axisLine={false} tick={customYAxis} />
                <Tooltip
                  formatter={(value) => {
                    return activeChart === "occupancy"
                      ? (value * 100).toFixed(0) + "%"
                      : currency(value, { precision: 0 }).format()
                  }}
                  labelFormatter={(value) => {
                    return (
                      <h3 className="label">
                        {moment(value).format("MMMM YYYY")}
                      </h3>
                    )
                  }}
                />
                <Legend
                  content={renderLegend}
                  verticalAlign="bottom"
                  wrapperStyle={{
                    lineHeight: "40px",
                    bottom: "auto"
                  }}
                />
                <Line
                  hide={activeSeries.includes("HERNAN")}
                  type="monotone"
                  dataKey="HERNAN"
                  stroke="#8884d8"
                  name="HERNAN"
                  strokeWidth={focusedLine === "HERNAN" ? 5 : 3}
                />
                <Line
                  hide={activeSeries.includes("JUANITO")}
                  type="monotone"
                  dataKey="JUANITO"
                  stroke="#82ca9d"
                  name="JUANITO"
                  strokeWidth={focusedLine === "JUANITO" ? 5 : 3}
                />
                <Line
                  hide={activeSeries.includes("NOE")}
                  type="monotone"
                  dataKey="NOE"
                  stroke="#ffc658"
                  name="NOE"
                  strokeWidth={focusedLine === "NOE" ? 5 : 3}
                />
                <Line
                  hide={activeSeries.includes("MICAELA")}
                  type="monotone"
                  dataKey="MICAELA"
                  stroke="#3f4658"
                  name="MICAELA"
                  strokeWidth={focusedLine === "MICAELA" ? 5 : 3}
                />
                <Line
                  hide={activeSeries.includes("VICTORIA")}
                  type="monotone"
                  dataKey="VICTORIA"
                  stroke="#Df76A8"
                  name="VICTORIA"
                  strokeWidth={focusedLine === "VICTORIA" ? 5 : 3}
                />
                <Line
                  hide={activeSeries.includes("PASCUAL")}
                  type="monotone"
                  dataKey="PASCUAL"
                  stroke="#5fC618"
                  name="PASCUAL"
                  strokeWidth={focusedLine === "PASCUAL" ? 5 : 3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </section>
      </ContainerCharts>
    </Container>
  )
}

const ChartLegend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;

  li {
    border: 3px solid black;
    width: 70px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 4px 2px 6px 0px #0000001f;

    &.hide {
      background-color: gray;
    }

    @media (min-width: 1024px) {
      width: 100px;
    }
  }
`
const Container = styled.section`
  background-color: #fff;
  padding: 2rem 0rem;
`

const ContainerCharts = styled.section`
  padding: 2rem 1rem;

  h2 {
    font-size: 2rem;
    font-weight: 300;
    font-family: "calibre";
  }

  .chart-title {
    display: flex;
    justify-content: space-between;
    grid-row: 1/2;
    grid-column: 2/3;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 1rem;
    padding-bottom: 10rem;

    .container-options {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      div {
        box-sizing: border-box;
        padding: 1rem 2rem;
        height: auto;
        box-shadow: 4px 2px 6px 0px #0000001f;
        border-radius: 5px;

        h3 {
          font-size: 1.3rem;
        }

        p {
          margin: 0;
          font-size: 1.1rem;
        }

        @media (min-width: 1024px) {
          height: auto;
          padding: 1rem;
        }
      }
    }

    .chart {
      height: 400px;
      display: flex;
      align-items: center;
      grid-row: 2/3;
      grid-column: 2/3;
      border-radius: 5px;
      box-shadow: 4px 2px 6px 0px #0000001f;
      padding: 0 1rem;
      .label {
        text-transform: capitalize;
      }

      .recharts-surface {
        border-radius: 4px;
        padding: 1rem 1rem 0 0;
        overflow: visible;
      }
    }

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 2rem 300px;
      gap: 0.5rem;

      .chart {
        height: 100%;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 2rem 3rem 0rem 3rem;
  }
`
const RadioOption = styled.li`
  /* Agrega tus estilos aquí */
  list-style: none;
  width: 100%;
  grid-row: 1/3;
`
const Input = styled.input`
  display: none;
`
const Label = styled.label`
  box-shadow: 4px 2px 6px 0px #0000001f;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.875rem;
  width: 90%;
  padding: 0.75rem 0.5rem;
  color: #435b49;
  background-color: #ffffff;
  border: 1px solid #435b49;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  ${Input}:checked + & {
    background-color: #435b49;
    color: #ffffff;
  }

  &:hover {
    background-color: #f7f7f7;
  }

  p {
    margin: 0;
  }
`
