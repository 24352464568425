import React from "react"
import Layout from "../../layout"
import { Link } from "react-router-dom"
import styled from "styled-components"

export default function CheckoutError() {
  return (
    <Layout dashboard lightLogo>
      <Container>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-sad-2"
          width="100"
          height="100"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="#435b49"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M14.5 16.05a3.5 3.5 0 0 0 -5 0" />
          <path d="M10 9.25c-.5 1 -2.5 1 -3 0" />
          <path d="M17 9.25c-.5 1 -2.5 1 -3 0" />
        </svg>
        <h1>
          No pudimos procesar tu pago, ¡por favor, vuelve a intentarlo de nuevo!
        </h1>
        <Link to="/simulador">
          <button>Iniciar proceso de nuevo</button>
        </Link>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-family: "calibre";
    text-align: center;
    font-weight: 500;
  }

  button {
    background-color: #fecfa4;
    padding: 15px 28px;
    font-weight: bold;
    border-radius: 30px;
    font-family: "calibre";
    font-size: 1rem;
    border: none;
    color: black;
    cursor: pointer;
  }

  button:hover {
    background-color: #f9c27a;
  }

  @media (min-width: 1023px) {
    /* gap: 2rem; */

    h1 {
      width: 500px;
    }
  }
`
