import React from "react"
import Layout from "../layout/index"
import { Route, Switch } from "react-router-dom"
import ConfigSimulator from "./configurationSimulator"

export default function Simulator() {
  return (
    <Layout dashboard lightLogo>
      <Switch>
        <Route exact path="/simulador">
          <ConfigSimulator />
        </Route>
      </Switch>
    </Layout>
  )
}
