import React, { useState } from "react"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import { Link } from "react-router-dom"
// components
import NewPassword from "./newPassword"
// layout
import { colors } from "../layout/constants"
import ActionButton from "../layout/shared/actionButton"
import Modal from "../layout/shared/modal"

const LoginForm = ({ light, loading, error, removeError, loginHandler }) => {
  const [modal, setModal] = useState(false)
  const [mail, setMail] = useState("")
  const [password, setPassword] = useState("")

  const submitHandler = async () => {
    // Send user credentials to service
    const userCredentials = {
      email: mail.trim(),
      password: password.trim()
    }

    loginHandler(userCredentials)
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await submitHandler()
    }
  }

  return (
    <FormStyled light={light}>
      <h2 className="section-title">Iniciar sesión</h2>

      <TextField
        className="input"
        variant="outlined"
        label="Correo electrónico"
        type="email"
        error={error ? true : false}
        value={mail}
        onFocus={() => removeError()}
        onChange={(event) => setMail(event.target.value)}
        onKeyDown={handleKeyDown}
      />

      <TextField
        className="input"
        variant="outlined"
        label="Contraseña"
        type="password"
        error={error ? true : false}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={handleKeyDown}
      />

      <ActionButton
        text={loading ? "Entrando..." : "Entrar"}
        onClick={submitHandler}
        disabled={loading}
        error={error ? true : false}
        errorMessage={error}
      />
      
      <div>
        <p className="create-account">¿No tienes una cuenta aún? <Link to='/registro'>crea una aquí</Link></p>
      </div>

      <div className="reset-password">
        <p onClick={() => setModal(true)}>Olvidé mi contraseña</p>
      </div>

      {modal ? (
        <Modal setModal={setModal} title="Genera una nueva contraseña">
          <NewPassword />
        </Modal>
      ) : null}
    </FormStyled>
  )
}

const FormStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .signup-link {
    color: ${colors.white};
    text-decoration: underline;

    :hover {
      color: ${colors.lightText};
    }
  }

  .input {
    margin: 10px 0;
  }

  .create-account {
    text-align: center;
  }

  .reset-password {
    text-align: center;

    p {
      color: ${(props) => (props.light ? colors.white : colors.darkText)};
      cursor: pointer;
      :hover {
        color: ${colors.highlightColor};
      }
    }
  }

  .MuiFormLabel-root,
  .MuiInputBase-root {
    color: ${(props) => (props.light ? colors.disabled : colors.primaryColor)};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.light ? colors.disabled : colors.primaryColor};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.light ? colors.white : colors.primaryColor};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) =>
      props.light ? colors.white : colors.black};
    transition: background-color 5000s ease-in-out 0s;
  }

  .MuiCheckbox-root {
    color: ${(props) => (props.light ? colors.disabled : colors.primaryColor)};
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${colors.secondaryColor};
  }
`

export default LoginForm
