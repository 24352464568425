const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_REPORT":
      state.user.report = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          report: action.payload
        }
      }
    case "SET_USER_RFC":
      return {
        ...state,
        user: {
          ...state.user,
          rfc: action.payload
        }
      }
    case "SET_USER_ID":
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload
        }
      }
    case "SET_USER_TOKEN":
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload
        }
      }
    case "SET_USER_STEP":
      return {
        ...state,
        user: {
          ...state.user,
          step: action.payload
        }
      }
    case "SET_SIMULATION":
      return {
        ...state,
        simulation: action.payload
      }
    case "SET_SIMULATION_YEAR":
      return {
        ...state,
        simulationYear: action.payload
      }
    case "SET_SIMULATION_REINVEST":
      return {
        ...state,
        simulationReinvest: action.payload
      }
    case "SET_SIMULATION_APORT":
      return {
        ...state,
        simulationAport: action.payload
      }
    case "SET_ORDER":
      return {
        ...state,
        order: action.payload
      }
    case "SET_USER_DATA":
      return {
        ...state,
        userData: { ...state.userData, ...action.payload }
      }
    case "SET_ADD_TO_CART":
      return {
        ...state,
        cart: { ...state.cart, ...action.payload }
      }
    case "LOGOUT":
      return {
        user: {},
        cart: {}
      }
    default:
      return state
  }
}

export default reducer
