import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getUserReport } from "../../services"
// components
import LoginForm from "./loginForm"
// layout
import DefaultLayout from "../layout"
import { mobileBreakpoint } from "../layout/constants"
import { colors } from "../layout/constants"
import Wrapper from "../layout/wrapper"
// state
import {
  setUserId,
  setUserToken,
  setUserStep,
  setReport,
  setUserData,
  setAddToCart
} from "../../state/actions"
// utils
import { setUserSession } from "../../utils/sesionManagment"
import { removeCookie } from "../../utils/cookies"
// services
import { login, validateMail, getUser } from "../../services/users"
import { getOrders } from "../../services/orders"

const Login = ({ history, prevPage }) => {
  const [hasError, setHasError] = useState(false)
  // Redux hooks
  const dispatch = useDispatch()
  // state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Get url param hook
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()

  useEffect(() => {
    const userId = query.get("userId")
    if (userId) {
      validateMail(userId)
    }
  }, [query])

  const loginHandler = async (userCredentials) => {
    setLoading(true)
    setError(null)

    try {
      const credentials = await login(userCredentials)
      setLoading(false)
      // Handle error
      if (credentials.error) {
        setError("Email o contraseña incorrectos")
        return credentials
      }

      const user = await getUser(credentials.token, credentials.user.userId)

      // Save in session storage
      setUserSession(
        credentials.token,
        credentials.user.userId,
        credentials.user.userType
      )

      // window.Intercom("update", {
      //   user_id: credentials.user.userId,
      //   user_hash: user ? user.hash : null
      // })

      const setUser = (token, id, step) => {
        setUserId(dispatch, id)
        setUserToken(dispatch, token)
        setUserStep(dispatch, step)
      }

      const setUserReport = (report) => {
        setReport(dispatch, report)
        setUserData(dispatch, report.userData)
        setUserStep(dispatch, report.userTrailStep ? report.userTrailStep : 0)
      }

      const setUserOrders = (order) => {
        const mappedOrders = order.reduce((finalOrder, order) => {
          finalOrder[order.phase_id] = {
            quantity: Number(order.total_tokens_count).toFixed(0),
            name: order.phase_name,
            value: order.single_token_value
          }

          return finalOrder
        }, {})

        setAddToCart(dispatch, mappedOrders)
      }

      const getReport = async (userToken, userId) => {
        try {
          const report = await getUserReport(userToken, userId)

          if (report.error) {
            removeCookie("userToken")
            setUser(null, null, null)
            setHasError(true)
            return
          }

          setUserReport(report)
        } catch (error) {
          removeCookie("userToken")
          setUser(null, null, null)
          setHasError(true)
        }
      }

      const getUserOrders = async (userToken, userId) => {
        try {
          const orders = await getOrders(userToken, userId)
          setUserOrders(orders)
        } catch (error) {
          console.error(error)
        }
      }

      if (user) {
        getReport(credentials.token, credentials.user.userId)
        getUserOrders(credentials.token, credentials.user.userId)
      }

      // Save in redux state
      setUserToken(dispatch, credentials.token)
      setUserId(dispatch, credentials.user.userId)
      setUserData(dispatch, user)
      if (prevPage) {
        history.push(prevPage)
      } else {
        if (user.rfc) {
          history.push("/tablero")
        } else {
          history.push("/bienvenido")
        }
      }
    } catch (error) {
      console.error("Catch Error", error)
      setLoading(false)
      setError("Algo salió mal, revisa tu conexión a internet")
    }
  }

  const removeError = () => {
    setError(null)
  }

  return (
    <DefaultLayout simple lightLogo>
      <LoginContainer>
        <Wrapper>
          <LoginGrid>
            <section>
              <h3 className="highlight-text">Entra a tu Tablero</h3>
              <p>
                En tu tablero online podras gestionar tus servicios de Urvita.
              </p>
            </section>
            <LoginForm
              loading={loading}
              error={error}
              removeError={removeError}
              loginHandler={loginHandler}
              light
            />
          </LoginGrid>
        </Wrapper>
      </LoginContainer>
    </DefaultLayout>
  )
}

const LoginContainer = styled.section`
  background-color: ${colors.primaryDark};
  color: ${colors.white};
`

const LoginGrid = styled.section`
  min-height: 80vh;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  .highlight-text {
    font-weight: 400;
    font-size: 2.2em;
  }

  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 1fr;
  }
`

export default Login
