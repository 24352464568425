import React, { useState } from "react"
import styled from "styled-components"
import currency from "currency.js"
import moment from "moment"
import "moment/locale/es"

export default function BuildingHistoricTable({ record }) {
  const [showMore, setShowMore] = useState(false)
  let reversedRecord = [...record].reverse()

  return (
    <Container>
      <h2>Historial</h2>
      <Table className={showMore ? "showMore" : null}>
        <ul className="titles">
          <li>Periodo</li>
          {reversedRecord[0]?.building_phase_status !== "OPERATING" ? null : <li>Ganancia x Token</li> }
          <li>Valor de Token</li>
        </ul>

        {reversedRecord.map((item) => (
          <ul key={crypto.randomUUID()}>
            <li className="date">
              {moment(item.period).locale("es").format("MMM-YYYY")}
            </li>
            {item.building_phase_status !== 'OPERATING' ? null : <li>{currency(item.noi_a, { precision: 2 }).format()}</li>}
            <li>{currency(item.value_a, { precision: 0 }).format()}</li>
          </ul>
        ))}
      </Table>
      <button onClick={() => setShowMore(!showMore)}>
        {showMore ? "Ver menos" : "Ver más"}
      </button>
    </Container>
  )
}

const Container = styled.section`
  padding: 1rem;
  margin: 1rem 0rem;

  h2 {
    margin-bottom: 10px;
    text-align: center;
    font-family: "calibre";
  }

  button {
    width: 150px;
    margin: 0 auto;
    margin-top: 20px;
    display: inherit;
    padding: 8px 0px;
    background-color: #fbcfa4;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    font-family: "calibre";
    cursor: pointer;
  }
`

const Table = styled.section`
  letter-spacing: 1px;
  font-size: 0.8rem;
  width: 100%;
  height: 565px;
  overflow-y: hidden;

  &.showMore {
    height: auto;
  }

  ul {
    display: flex;
    padding: 0;
    border-bottom: 1px solid;
    padding-bottom: 10px;

    &.titles {
      li {
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    .date {
      text-transform: capitalize;
    }

    &:last-child {
      border-bottom: none;
    }

    li {
      list-style: none;
      text-align: center;
      width: 33.33%;
    }
  }
`
