import React, { useEffect, useState } from "react"
import styled from "styled-components"
// uitls
import { buildingImage } from "../../../utils/format"
import { colors } from "../../layout/constants"
import Carousel from "../../layout/shared/carousel"
// services
import { getBuildingRecords } from "../../../services/trackRecord"
// Components
import Info from "./buildingInfo"
import Description from "./buildingDescription"
import Gallery from "./buildingGallery"
import Cart from "./buildingCart"
import Chart from "./buildingChart"
import Tokens from "./buildingTokens"
import BuildingHistoricTable from "./buildingHistoricTable"

const BuildingDetail = ({ userToken, building, lastValues }) => {
  const [record, setRecord] = useState([])
  const [activeTab, setActiveTab] = useState("values")
  const [viewport, setViewport] = useState()

  useEffect(() => {
    setViewport(window.visualViewport.width)
    const getRecord = async () => {
      const response = await getBuildingRecords(userToken, building.phase_id)
      setRecord(response.reverse())
    }

    getRecord()
  }, [building.phase_id, userToken])

  const chengeTab = (active) => {
    setActiveTab(active)
  }

  // Get avergare from the last 12 months
  const getYearAverage = () => {
    const lastYearValues = record.slice(Math.max(record.length - 11, 0))

    const averageOccupancyRaw =
      lastYearValues.reduce((a, b) => {
        return Number(a) + Number(b.occupancy)
      }, 0) / lastYearValues.length

    const averageOccupancy = (averageOccupancyRaw * 100).toFixed(0)

    const averageNoi =
      lastYearValues.reduce((a, b) => {
        return Number(a) + Number(b.noi_a)
      }, 0) / lastYearValues.length

    return { averageOccupancy, averageNoi }
  }

  return (
    <DetailsContainer>
      <section>
        <h1 className="title">{building.name.toLowerCase()}</h1>
        <Info
          building={building}
          values={lastValues}
          getYearAverage={getYearAverage}
        />
        <Cart building={building} values={lastValues}></Cart>

        {viewport < 998 ? (
          <section>
            <h2>Información del edificio</h2>
            <div className="tabs">
              <button className="tab-chip" onClick={() => chengeTab("values")}>
                Finanzas
              </button>
              <button
                className="tab-chip"
                onClick={() => chengeTab("description")}
              >
                Descripción
              </button>
            </div>
          </section>
        ) : null}
      </section>

      <Carousel height="500px">
        <CarouselItem image={buildingImage(building.name)}></CarouselItem>
      </Carousel>

      {activeTab === "values" || viewport > 998 ? (
        <section>
          {record && building.phase_status === "OPERATING" && (
            <Chart
              type="token-noi"
              record={record}
              getYearAverage={getYearAverage}
            ></Chart>
          )}
          {record && building.phase_status === "OPERATING" && (
            <Chart
              type="occupancy"
              record={record}
              getYearAverage={getYearAverage}
            ></Chart>
          )}
          {record && (
            <Chart
              type="token-value"
              record={record}
              getYearAverage={getYearAverage}
            ></Chart>
          )}
          <Tokens building={building} userToken={userToken} />
          <BuildingHistoricTable record={record} />
        </section>
      ) : null}
      {activeTab === "description" || viewport > 998 ? (
        <section>
          <Description building={building}></Description>
          <Gallery userToken={userToken} building={building}></Gallery>
        </section>
      ) : null}
    </DetailsContainer>
  )
}

const CarouselItem = styled.article`
  background-image: ${(props) => `url("${props.image}")`};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`

const DetailsContainer = styled.section`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;

  .title {
    grid-column: 1/3;
    font-size: 3rem;
    font-weight: 200;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  .image-container {
    align-items: center;
    display: flex;
    height: 500px;
    overflow: hidden;

    @media (max-width: 968px) {
      grid-row: 1/2;
      height: 300px;
      width: 100%;
    }
  }

  .tabs {
    display: flex;
    justify-content: flex-start;
  }

  .tab-chip {
    background-color: ${colors.secondaryColor};
    border-radius: 16px;
    border: none;
    color: ${colors.darkText};
    padding: 5px 10px;
    margin-left: 1rem;
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`

export default BuildingDetail
