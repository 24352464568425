import React, { useState } from "react";
import styled from "styled-components";
//layout
import { colors } from "../../layout/constants";
// assets
import QuestionIcon from "../../../assets/icons/question";

const Tooltip = ({ content, dark }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TooltipStyled>
      <QuestionIcon
        className="tooltip-icon"
        color={dark ? colors.black : colors.white}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />

      {showTooltip && (
        <article className="tooltip">
          <p>{content}</p>
        </article>
      )}
    </TooltipStyled>
  );
};

const TooltipStyled = styled.article`
  display: inline;
  position: relative;

  .tooltip-icon {
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    width: 1rem;
  }

  .tooltip {
    background-color: ${colors.white};
    box-shadow: 2px 3px 8px 0px black;
    border-radius: 8px;
    border-top-left-radius: 0;
    color: ${colors.darkText};
    padding: 5px;
    position: absolute;
    top: 100%;
    left: 100%;
    width: 200px;
    z-index: 1;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }
`;

export default Tooltip;
