import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
// layout
import ActionButton from "../layout/shared/actionButton";
// services
import { sendNewPassword } from "../../services/auth";

const NewPassword = () => {
  const [mail, setMail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const sendMail = async () => {
    if (!mail) {
      setError("El mail no puede quedar vacio.");
      return;
    }

    try {
      setLoading(true);
      const outcome = sendNewPassword(mail);
      setLoading(false);
      setDone(true);

      if (outcome.error) {
        setError("Hubo un error al procesar tu solicitud");
      }
    } catch (error) {
      console.error(error);
      setError("Hubo un error al procesar tu solicitud");
    }
  };

  return (
    <PasswordStyled>
      {done ? (
        <p>Hemos enviado las instrucciones a tu correo electrónico.</p>
      ) : (
        <div>
          <p>
            Mandaremos a tu correo las instrucciones para elegir una nueva
            contraseña
          </p>
          <TextField
            className="input"
            variant="outlined"
            label="Correo electrónico"
            type="email"
            error={error ? true : false}
            value={mail}
            onFocus={() => setError("")}
            onChange={(event) => setMail(event.target.value)}
          />
          <ActionButton
            left
            text={loading ? "Entrando..." : "Enviar"}
            onClick={sendMail}
            disabled={loading}
            error={error ? true : false}
            errorMessage={error}
          />{" "}
        </div>
      )}
    </PasswordStyled>
  );
};

const PasswordStyled = styled.section``;

export default NewPassword;
