export async function getBuildingUnits(userToken, phase_id) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/units/building/${phase_id}`,
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
  ).catch((err) => console.error(err));
  const units = await response.json();
  return units.data;
}
