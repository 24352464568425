import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import ActionButton from "../../layout/shared/actionButton"
// services
import { getDocuments, getWeetrustDocuments } from "../../../services/contracts"

const Documents = ({ userInfo }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        setLoading(true)
        const resp = await getDocuments(userToken, userInfo.id)

        if (resp.length > 0) {
          const docs = await Promise.all(
            resp.map(async (doc) => {
              const weetrustDoc = await getWeetrustDocuments(
                userToken,
                doc.weetrust_id
              )
              return { ...doc, weetrustDoc }
            })
          )
          setDocuments(docs)
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    if (userToken && userInfo) {
      loadDocuments()
    }
  }, [userToken, userInfo])

  return (
    <ProfileStyled>
      <h1>Documentos</h1>
      {documents.length > 0 && (
        <section>
          {documents.map((doc) => (
            <article key={doc.id} className="documentArticle">
              <h3>{doc.type}</h3>
              <a
                href={doc.weetrustDoc.documentFileObj.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ActionButton text="Ver Documento" />
              </a>
            </article>
          ))}
        </section>
      )}

      {!loading && !documents.length > 0 && (
        <p>No hay documentos disponibles</p>
      )}

      {loading && <p>Cargando documentos... </p>}
    </ProfileStyled>
  )
}

export default Documents

const ProfileStyled = styled.section`
  .documentArticle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`
