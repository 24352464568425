import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { useSelector } from "react-redux"
import { Switch } from "@mui/material"
// layout
import { colors } from "../../layout/constants"
// services
import { getBuildingTokens } from "../../../services/tokens"
// utils
import {
  capitalName,
  buildingImage,
  statusTranslate
} from "../../../utils/format"

const BuildingCard = ({ building, selectBuilding, lastValues }) => {
  const userToken = useSelector((state) => state.user.token)
  const [tokens, setTokens] = useState()

  useEffect(() => {
    const getTokenCount = async () => {
      const tokens = await getBuildingTokens(userToken, building.phase_id)
      setTokens(tokens)
    }
    getTokenCount()
  }, [building.phase_id, userToken])

  const isSoldOut = () => {
    const availableTokens = tokens.filter(
      (token) => token.available && token.type === "TOKEN-A"
    ).length
    return availableTokens === 0
  }

  return (
    <Card onClick={() => selectBuilding(building.name)}>
      <CardImage>
        {tokens && (
          <div className={`banner ${isSoldOut() ? "sold-out" : "available"}`}>
            <p className="title--banner">
              {isSoldOut() ? "Sold Out" : "Disponible"}
            </p>
            <span className="quantity">
              {
                tokens.filter(
                  (token) => token.available && token.type === "TOKEN-A"
                ).length
              }{" "}
              / {tokens.length} m2
            </span>
          </div>
        )}

        <img
          className="building"
          srcSet={`${buildingImage(building.name)} 1x`}
          src={buildingImage(building.name)}
          alt="imagen de Juanito"
        />
      </CardImage>

      <CardDetails>
        {/* <li> */}
        <h3>Urvita {capitalName(building.name)}</h3>
        {/* </li> */}
        {/* <li>
          <h4>Rendimiento anual</h4>
          <NumberFormat
            className="token-rate"
            value={(Number(lastValues.period_scale) * 100).toFixed(0)}
            displayType={"text"}
            suffix="%"
            thousandSeparator={true}
          />
        </li> */}
        <h4>
          {building.address_suburb}, {building.address_city},{" "}
          {building.address_state}
        </h4>
        <NumberFormat
          className="token-cost"
          value={Number(lastValues.value_a).toFixed(2)}
          displayType={"text"}
          prefix="$"
          thousandSeparator={true}
        />
        {/* <li> */}
        {/* <span className="token-status">
            {statusTranslate(building.phase_status)}
          </span> */}
        {/* </li> */}
      </CardDetails>
    </Card>
  )
}

const Card = styled.article`
  border-radius: 4px;
  cursor: pointer;
  // overflow: hidden;
  /* height: 268px; */
  /* padding-bottom: 1rem; */
  /* background-color: white; */
  /* box-shadow: 1px 2px 4px 1px lightgrey; */
  transition: all 0.2s ease-out;

  &:hover {
    /* box-shadow: 1px 2px 6px 2px lightgrey; */
    transform: translate(0, -0.2rem);
    transition: translate 0.1s ease-out;
  }

  @media (min-width: 1024px) {
    width: 100%;
    /* margin: 0 auto; */
  }
`

const CardImage = styled.div`
  display: flex;
  align-items: center;
  height: 230px;
  /* margin: 0 auto; */
  // overflow: hidden;
  position: relative;

  .building {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
    object-position: top;
    /* border-top-left-radius: 4px; */
    /* border-top-right-radius: 4px; */
  }

  .banner {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100px;
    right: 0;
    top: 20%;
    padding: 5px 0;
    padding-right: 7px;
    padding-left: 22px;
    font-family: "kansas";
    background-color: #e0a669;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    .title--banner {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
    }

    .quantity {
      color: white;
      font-size: 0.7em;
      font-weight: thin;
    }
    &.sold-out {
      background-color: #394538;

      .title--banner {
        color: #e0a669;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`

const CardDetails = styled.ul`
  padding: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  li {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
  }

  h3 {
    font-weight: 300;
    font-size: 1.2rem;
    margin: 0;
  }

  h4 {
    margin: 0;
    font-weight: 200;
    font-size: 1rem;
  }

  .token-cost {
    /* color: ${colors.helperGray}; */
    color: #f79f4d;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .token-rate {
    color: #00d098;
  }

  .token-status {
    background-color: #e9f6fc;
    border-radius: 16px;
    color: #1775a2;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 4px 10px;
  }
`

export default BuildingCard
