async function getAllBuildingsRecords(userToken) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_values`,
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function getBuildingRecords(userToken, phase_id) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_values/building/${phase_id}`,
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function getLastValues(userToken, period) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_values/period/${period}`,
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

export { getBuildingRecords, getLastValues, getAllBuildingsRecords }
