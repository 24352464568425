import React, { useState } from "react"
import Layout from "../../layout"
import styled from "styled-components"
import { Link, useHistory } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"
import { useEffect } from "react"
import { editUser } from "../../../services/users"
import { useSelector } from "react-redux"

export default function SpeiRFC() {
  let history = useHistory()
  let query = useQuery()
  const user = useSelector((state) => state.user)
  const [rfcUser, setRfcUser] = useState("")
  const [initialPayment, setInitialPayment] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [hasFractions, setHasFractions] = useState(null)

  const handleEditUser = async () => {
    const newUser = await editUser(user.token, user.id, {
      rfc: rfcUser
    })
    history.push(
      `/pago-con-spei?initial=${initialPayment}&monthly=${subscription}&tokens=${hasFractions}`
    )
  }

  useEffect(() => {
    let initialPayment = Number(query.get("initial"))
    let subscription = Number(query.get("monthly"))
    let tokens = Number(query.get("tokens"))
    setInitialPayment(initialPayment)
    setSubscription(subscription)
    setHasFractions(tokens)
  }, [])

  return (
    <Layout dashboard lightLogo>
      <Container>
        <h1>
          Ingresa tu RFC o número de identificación para poder identificar tu pago de forma automática
        </h1>
        <input placeholder="RFC" onChange={(e) => setRfcUser(e.target.value)} />
        <button onClick={() => handleEditUser()} disabled={rfcUser === ""}>
          Continuar
        </button>
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 350px;
  gap: 1.5rem;
  padding: 2rem;
  margin: 0 auto;

  input {
    padding: 12px 15px;
    border: 1px solid #000;
    border-radius: 10px;
  }

  button {
    background-color: #fecfa4;
    padding: 15px 28px;
    width: max-content;
    font-weight: bold;
    border-radius: 30px;
    font-size: 1rem;
    font-family: "calibre";
    border: none;
    color: black;
    cursor: pointer;
  }

  button:hover {
    background-color: #f9c27a;
  }

  button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    width: 500px;
    padding: 2rem 0;
  }
`
