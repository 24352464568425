import React, { useState } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import Kyc from "../../kyc"
// components
import ReportBar from "./reportBar"

const StatusBar = ({ userReport, handleModalClick }) => {
  const [openKyc, setOpenKyc] = useState(false)
  const location = useLocation()

  return (
    <>
      {userReport.userData.contract_status !== "signed" && (
        <Banner className="banner">
          {userReport.userData.contract_status === "toSign" ? (
            <>
              <span>
                Ya te hemos enviado tu contrato, por favor revisa tu correo para
                firmarlo.
              </span>
            </>
          ) : (
            <>
              <b>¡¡Importante!!</b> Verifica tu identidad para comenzar a
              generar ganancias:{" "}
              <button onClick={() => setOpenKyc(true)}>Comenzar</button>
            </>
          )}
        </Banner>
      )}
      {openKyc && <Kyc setModal={setOpenKyc} />}

      <BarStyled userType={userReport.userType}>
        {location.pathname === "/tablero/tokens" ? (
          <ReportBar
            userReport={userReport}
            handleModalClick={handleModalClick}
          />
        ) : null}
      </BarStyled>
    </>
  )
}

const BarStyled = styled.section``

const Banner = styled.section`
  button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }

  button:hover {
    text-decoration: underline;
  }
`

export default StatusBar
