import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useQuery } from "../hooks/useQuery"
import { useSelector } from "react-redux"
import currency from "currency.js"
import Layout from "../../layout"
import { Link, useHistory } from "react-router-dom"
import {
  openPaymentIntent,
  getUserPaymentIntent,
  updatePaymentIntent,
  cancelPaymentIntent,
  getFundingInstructions
} from "../../../services/stripe"
import Loader from "../../reports/loader"

export default function SpeiPayment() {
  let history = useHistory()
  let query = useQuery()
  const user = useSelector((state) => state.user)
  const [amount, setAmount] = useState(0)
  const [bankData, setBankData] = useState()
  const [subscription, setSubscription] = useState()
  const [hasTokens, setHasTokens] = useState(false)

  useEffect(() => {
    let initialPayment = Number(query.get("initial"))
    let subscription = Number(query.get("monthly"))
    let tokens = query.get("tokens")
    managePaymentIntent(initialPayment)
    setSubscription(subscription)
    setHasTokens(tokens !== "true" ? false : true)
  }, [])

  const managePaymentIntent = async (amount) => {
    let paymentIntent

    const allIntents = await getUserPaymentIntent(user.token, user.id)

    if (allIntents[0]?.id) {
      const openIntents = allIntents.filter(
        (intent) => intent.description === "Pago por SPEI"
      )

      openIntents.forEach(async (intent, index) => {
        if (index === 0) {
          const intentAmount =
            hasTokens === true
              ? amount * 100 -
                Number(Math.trunc(user.report.userData.balance * 100))
              : amount * 100

          paymentIntent = intent
          paymentIntent = await updatePaymentIntent(
            user.token,
            paymentIntent.id,
            {
              amount: Math.trunc(intentAmount)
            }
          )

          const fundingInstructions = await getInstructions()

          setAmount(intentAmount / 100)
          setBankData(fundingInstructions)
        } else {
          paymentIntent = await cancelPaymentIntent(
            user.token,
            paymentIntent.id
          )
        }
      })
    } else {
      const intentAmount =
        hasTokens === true
          ? amount - Number(Math.trunc(user.report.userData.balance))
          : amount
      paymentIntent = await openPaymentIntent(user.token, {
        amount: Math.trunc(intentAmount),
        userId: user.id,
        type: "customer_balance"
      })

      const speiDetails =
        paymentIntent.next_action?.display_bank_transfer_instructions
      setAmount(Math.round(speiDetails.amount_remaining / 100))
      setBankData(speiDetails.financial_addresses[0].spei)
    }

    if (paymentIntent.message) {
      history.push(`/checkout-error`)
    }
    // }

    window.fbq("trackCustom", "PAGO", {
      currency: "MXN",
      value:
        hasTokens === true
          ? amount - Number(Math.trunc(user.report.userData.balance))
          : amount
    })
  }

  const getInstructions = async () => {
    const instructions = await getFundingInstructions(user.token, user.id)
    return instructions
  }

  return (
    <Layout dashboard lightLogo>
      <Container>
        <h2>Pago con Transferencia Bancaria</h2>
        {amount && bankData ? (
          <>
            <article>
              <h3 className="step-title">
                1.- Da de alta la cuenta de urvita en tu portal bancario
              </h3>
              <p>
                Esta cuenta está generada solo para ti, de esta forma podremos
                darle seguimiento a tu pago.
              </p>

              <div>
                <ul className="bank-info">
                  <li>
                    <p className="info-title">Beneficiario</p>
                    <p className="info">URVITA EVERYTHING SAPI DE CV</p>
                  </li>
                  <li>
                    <p className="info-title">RFC</p>
                    <p className="info">UEV190704AJ6</p>
                  </li>
                  <li>
                    <p className="info-title">Banco</p>
                    <p className="info">{bankData.bank_name}</p>
                  </li>
                  <li>
                    <p className="info-title">CLABE</p>
                    <p className="info">{bankData.clabe}</p>
                  </li>
                  <li>
                    <p className="info-title">Código del Banco</p>
                    <p className="info">{bankData.bank_code}</p>
                  </li>
                  <li>
                    <p className="info-title">Correo</p>
                    <p className="info">finanzas@urvita.mx</p>
                  </li>
                  {bankData.bank_name === "BANAMEX" && (
                    <>
                      <li>
                        <p className="info-title">Código Swift</p>
                        <p className="info">BNMXMXMMXXX</p>
                      </li>
                      <li>
                        <p className="info-title">Dirección del Banco</p>
                        <p className="info">
                          ACTUARIO ROBERTO MEDELLIN 800, COL. SANTA FE, CDMX,
                          México
                        </p>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </article>

            <article className="step-container">
              <h3 className="step-title">
                2.- Realiza tu pago con transferencia electrónica
              </h3>
              <p className="description">
                Tu compra inicial es de:{" "}
                <span className="info">
                  {currency(
                    hasTokens === true
                      ? amount -
                          Number(Math.trunc(user.report.userData.balance))
                      : amount
                  ).format()}
                </span>
              </p>
            </article>

            <article className="step-container">
              <h3 className="step-title">
                3.- Confirmaremos tu pago y comenzarás a generar ganancias.
              </h3>
              <div>
                <p>
                  Al validar tu pago, recibirás un correo de confirmación en de
                  1 día hábil. A partir de este momento tu inversión estará
                  activa y generando ganancias.
                </p>
                <p>
                  Si necesitas ayuda da click en nuestro chat o escríbenos a
                  info@urvita.mx
                </p>
              </div>
            </article>
            <Link
              to={`/pay?amount=${
                hasTokens === true
                  ? amount - Number(Math.trunc(user.report.userData.balance))
                  : amount
              }&monthly=${subscription}&spei=true`}
            >
              <button className="">Continuar</button>
            </Link>
          </>
        ) : (
          <Loader message="Estamos generando tu cuenta personalizada" />
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2rem;
  background-color: #fffaf5;

  .bank-info {
    display: grid;
    font-weight: 200;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    list-style: none;
    padding: 0;
  }

  .info-title {
    margin: 5px 0;
    opacity: 0.8;
  }

  .info {
    font-weight: 500;
    margin: 0;
  }

  button {
    background-color: #fecfa4;
    padding: 15px 28px;
    width: max-content;
    font-weight: bold;
    border-radius: 30px;
    font-size: 1rem;
    font-family: "calibre";
    border: none;
    color: black;
    cursor: pointer;
  }

  button:hover {
    background-color: #f9c27a;
  }

  @media (min-width: 1023px) {
    max-width: 600px;
    margin: 0 auto;
  }
`
