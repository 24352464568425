import React from "react"
import { Route, Redirect } from "react-router-dom"
import { getToken } from "../utils/sesionManagment"

// handle the private routes
const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getToken()
  const params = rest.location.search
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <Component {...props} userToken={token} />
        ) : (
          <Redirect
            to={{
              pathname: `/login/${params}`,
              state: {
                from: `${props.location.pathname}?${props.location.search}`
              }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
