import React from "react"
import ReactDOM from "react-dom"
import App from "./routes/app"
import { Provider } from "react-redux"
import { createStore } from "redux"
import reducer from "./state/reducers"
import "./index.css"
import { BrowserRouter } from "react-router-dom"

const initialState = {
  user: {},
  simulation: {},
  cart: {}
}

const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
)
