import React, { useEffect, useState } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
// layout
import { colors, transactionName, dateFormat } from "../../layout/constants"

const HistoricTable = ({ transactions }) => {
  const [movementsList, setMovementsList] = useState([])

  useEffect(() => {
    setMovementsList(sortByDate(transactions))
  }, [transactions])

  const sortByDate = (transactions) => {
    return transactions.sort((a, b) => {
      if (a.transaction_date > b.transaction_date) {
        return 1
      } else if (a.transaction_date < b.transaction_date) {
        return -1
      } else {
        return 0
      }
    })
  }

  const monthTitle = (date, index) => {
    const transactionMonth = dateFormat(date)

    if (index === movementsList.length - 1) {
      return transactionMonth
    } else {
      const nextTransactionMonth = dateFormat(
        movementsList[index + 1].transaction_date
      )

      if (transactionMonth !== nextTransactionMonth) {
        return transactionMonth
      }
    }
  }

  return (
    <HistoricStyled>
      {movementsList.map((transaction, index) => (
        <article className="month-item" key={index}>
          <p className="month-title">
            {monthTitle(transaction.transaction_date, index)}
          </p>
          <li className="transactions-item">
            <div>
              <h3 className="transaction-name">
                {transactionName(transaction.type)}
              </h3>
              <span className="transaction-date">
                {dateFormat(transaction.transaction_date)}
              </span>
            </div>

            <NumberFormat
              className={`number final ${
                transaction.indicator === "withdrawal" ? "withdrawal" : null
              }`}
              value={Number(transaction.net_amount).toFixed(2)}
              displayType={"text"}
              prefix={transaction.indicator === "withdrawal" ? "- $" : "+ $"}
              thousandSeparator={true}
            />
          </li>
        </article>
      ))}
    </HistoricStyled>
  )
}

const HistoricStyled = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;

  .month-item {
    padding: 0 0 1.5em 0;
  }

  .transactions-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }

  .title-items {
    color: ${colors.darkText};
    font-weight: 600;
    font-size: 1.2em;
  }

  .withdrawal {
    color: red;
  }

  .final {
    font-size: 1.2em;
    font-weight: 600;
  }

  .month-title {
    border-bottom: 1px solid ${colors.green};
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .transaction-name {
    font-weight: 600;
    font-size: 1.2em;
    margin: 0;
  }

  .transaction-date {
    color: grey;
    font-size: 0.8em;
  }

  .movement-value {
    display: flex;
    font-size: 0.8em;
    align-items: center;
  }

  .movement-helper {
    font-size: 1em;
  }

  .movement-number {
    font-size: 1.5em;
  }

  .movements-buttons {
    max-width: 200px;
    margin: 0 auto;
  }
`

export default HistoricTable
