import React, { useState, useEffect } from "react";
import styled from "styled-components";
// utils
import { buildingImage } from "../../../utils/format";
import Carousel from "../../layout/shared/carousel";
//services
import { getBuildingUnits } from "../../../services/units";

const BuildingGallery = ({ userToken, building }) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const getUnits = async () => {
      const resp = await getBuildingUnits(userToken, building.phase_id);
      setUnits(resp);
    };
    getUnits();
  }, [building.phase_id, userToken]);

  return (
    <GalleryContainer>
      <h2>Tipo de unidades</h2>
      <Carousel>
        {units &&
          units.map((unit, index) => (
            <article className="carrousel" key={index}>
              <img
                srcSet={`https://storage.googleapis.com/urvita-api.appspot.com/${building.name
                  .toLowerCase()
                  .trim()}-${index + 1}.jpg 1x`}
                src={buildingImage(building.name)}
                alt={`imagen de ${building.name}`}
              />
              <div className="caption">
                <h4>{unit.type}</h4>
                <p>{unit.description}</p>
              </div>
            </article>
          ))}
      </Carousel>
    </GalleryContainer>
  );
};

const GalleryContainer = styled.section`
  .carrousel {
    background-color: black;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .caption {
    color: white;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    padding: 1rem 0.5rem;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.7);

    h4,
    p {
      margin: 1px;
    }
  }
`;

export default BuildingGallery;
