import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { colors } from "../../layout/constants";
// components
import ReinvestStatus from "./reinvestStatus";
// assets
import pascualImg from "../../../assets/img/buildings/pascual.png";
import micaelaImg from "../../../assets/img/buildings/micaela.jpg";
import noeImg from "../../../assets/img/buildings/noe.jpg";
import victoriaImg from "../../../assets/img/buildings/victoria.jpg";
import juanitoImg from "../../../assets/img/buildings/juanito.jpg";
import evaImg from "../../../assets/img/buildings/eva.jpg";
import hernanImg from "../../../assets/img/buildings/hernan.jpeg";
import defaultBuilding from "../../../assets/img/buildings/default-building.png";

const TokenRow = ({
  name,
  singleTokenValue,
  totalTokensValue,
  totalTokensCount,
  fraction,
  status,
}) => {
  const nameLower = name.toLowerCase();

  let image = "";

  switch (nameLower) {
    case "micaela":
      image = micaelaImg;
      break;
    case "noe ":
      image = noeImg;
      break;
    case "pascual":
      image = pascualImg;
      break;
    case "victoria":
      image = victoriaImg;
      break;
    case "hernan":
      image = hernanImg;
      break;
    case "eva":
      image = evaImg;
      break;
    case "juanito":
      image = juanitoImg;
      break;
    case "fracción de token":
      image = defaultBuilding;
      break;
    default:
      break;
  }

  const capitalName = (name) => {
    const firstLetter = name[0].toUpperCase();
    const lastLetters = name
      .split("")
      .splice(1, name.length - 1)
      .join("")
      .toLowerCase();
    return firstLetter + lastLetters;
  };

  // Separate cents for custom UI display
  const tokenCents = (tokenValue) => {
    const decimals = (tokenValue % 1) * 100;
    return Math.trunc(decimals);
  };

  return (
    <TokenRowStyles status={status}>
      <figure className="building-img">
        {fraction ? null : <img src={image} alt="imagenes de edificios" />}
      </figure>

      {fraction ? (
        <ReinvestStatus
          balance={totalTokensValue}
          tokenPrice={singleTokenValue}
        />
      ) : (
        <>
          <div className="token-values">
            <span className="token-name">{capitalName(name)}</span>

            <article className="token-value">
              <span className="token-helper">$</span>
              <NumberFormat
                className="token-number"
                value={Math.trunc(totalTokensValue)}
                displayType={"text"}
                thousandSeparator={true}
              />
              <span className="token-helper">
                .{tokenCents(totalTokensValue)}
              </span>
            </article>
          </div>

          <div>
            <NumberFormat
              className="token-count"
              value={Number(totalTokensCount).toFixed(0)}
              displayType={"text"}
              suffix={Number(totalTokensCount) === 1 ? " Token" : " Tokens"}
            />
          </div>
        </>
      )}
    </TokenRowStyles>
  );
};

const TokenRowStyles = styled.li`
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 20px;
  padding: 10px 0;

  .building-img {
    /*background-color: ${(props) =>
      props.status === "OPERATING"
        ? colors.operationColor
        : props.status === "PRECONSTRUCTION"
        ? colors.preSaleColor
        : colors.launchColor};*/
    background-color: ${colors.secondaryColor};
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    margin: 0;
    overflow: hidden;
    width: 75px;

    img {
      height: 100%;
      min-width: 100%;
      object-fit: fill;
    }
  }

  .token-name {
    display: block;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 0 5px 0;
  }

  .token-helper {
    font-size: 14px;
    font-weight: 300;
    margin: 0 5px;
  }

  .token-number {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    vertical-align: middle;
  }

  .token-count {
    font-size: 18px;
  }

  @media (max-width: 968px) {
    align-items: flex-end;
    grid-column-gap: 10px;

    .building-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
`;

export default TokenRow;
