import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
// layout
import { colors } from "../constants";

const BreadCrumb = ({ light }) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    const locationCrumbs = location.pathname.split("/");
    setCrumbs(locationCrumbs);
  }, [location]);

  return (
    <BreadcrumbStyled light={light}>
      {crumbs.map((crumb, index) => (
        <span key={index} className="active">
          <span className="tail">{crumb ? " > " : ""}</span>
          <Link to={`/${crumb}`}>
            <span className="section"> {crumb ? crumb : "Home"}</span>
          </Link>
        </span>
      ))}
    </BreadcrumbStyled>
  );
};

const BreadcrumbStyled = styled.section`
  padding: 1em 0;
  font-weight: 200;
  text-transform: capitalize;

  .tail {
    color: ${(props) => (props.light ? colors.white : colors.helperGray)};
  }

  .section {
    color: ${(props) => (props.light ? colors.white : colors.helperGray)};
    &:hover {
      color: ${colors.secondaryDark};
    }
  }
`;

export default BreadCrumb;
