export async function createTransaction(userToken, newData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions`,
    {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(newData)
    }
  )

  const data = await response.json()
  return data
}

export async function getTransactionsByUser(userToken, user_rfc) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/user/${user_rfc}`,
    {
      method: "GET",
      headers: myHeaders
    }
  )

  const data = await response.json()
  return data
}
