import React from "react"
import styled from "styled-components"
//
import { colors } from "../layout/constants"
// assets
import UrvitaLogo from "../../assets/logos/urvita"

const Loader = ({ message }) => {
  return (
    <LoaderStyles>
      <div className="loader-spinner">
        <UrvitaLogo className="loader-image" color={colors.secondaryColor} />
      </div>
      <p className="loader-text">{message}</p>
    </LoaderStyles>
  )
}

const LoaderStyles = styled.section`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  padding: 0 15px;
  width: 100%;

  .loader-spinner {
    align-items: center;
    background-color: ${colors.black};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 200px;
  }

  .loader-image {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-fill-mode: easy-in;
    width: 100px;
  }

  .loader-text {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  @keyframes loading {
    0% {
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loader
