import React from "react";
import { colors } from "../../components/layout/constants";

const LogoutIcon = (props) => {
  const { color, className } = props;

  return (
    <svg
      className={className}
      viewBox="8 3 10 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.172 15.3425C13.8662 15.0389 13.8653 14.5447 14.17 14.24L15.6322 12.7778H8.88889C8.45933 12.7778 8.11111 12.4296 8.11111 12C8.11111 11.5704 8.45933 11.2222 8.88889 11.2222H15.6322L14.17 9.76C13.8653 9.45533 13.8662 8.9611 14.172 8.65751C14.4762 8.35543 14.9674 8.3563 15.2706 8.65945L17.904 11.2929C18.2945 11.6834 18.2945 12.3166 17.904 12.7071L15.2706 15.3406C14.9674 15.6437 14.4762 15.6446 14.172 15.3425ZM17.4444 5C17.857 5 18.2527 5.16389 18.5444 5.45561C18.8361 5.74733 19 6.143 19 6.55556V8.31006C19 9.00298 18.1622 9.35 17.6723 8.86003C17.5264 8.71417 17.4444 8.51634 17.4444 8.31006V6.55556H6.55556V17.4444H17.4444V15.6899C17.4444 15.4837 17.5264 15.2858 17.6723 15.14C18.1622 14.65 19 14.997 19 15.6899V17.4444C19 17.857 18.8361 18.2527 18.5444 18.5444C18.2527 18.8361 17.857 19 17.4444 19H6.55556C6.143 19 5.74733 18.8361 5.45561 18.5444C5.16389 18.2527 5 17.857 5 17.4444V6.55556C5 5.69222 5.69222 5 6.55556 5H17.4444Z"
        fill={color ? color : colors.black}
      />
    </svg>
  );
};

export default LogoutIcon;
