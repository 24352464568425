import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
// layout
import { colors, months, countries } from "../../layout/constants";
import ActionButton from "../../layout/shared/actionButton";
// services
import { editUser } from "../../../services/users";

const DepositForm = ({ userInfo }) => {
  // Redux hooks
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.token);
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  // inputs
  const [accountName, setAccountName] = useState(
    userInfo.account_name ? userInfo.account_name : ""
  );
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [accountBank, setAccountBank] = useState(
    userInfo.account_bank ? userInfo.account_bank : ""
  );
  const [bankError, setBankError] = useState(false);
  const [bankErrorMessage, setBankErrorMessage] = useState("");

  const [accountClabe, setAccountClabe] = useState(
    userInfo.account_clabe ? userInfo.account_clabe : ""
  );
  const [clabeError, setClabeError] = useState(false);
  const [clabeErrorMessage, setClabeErrorMessage] = useState("");

  const validateForm = () => {
    let isValid = true;
    if (accountName === "") {
      setNameError(true);
      setNameErrorMessage("No puede quedar vacio");
      isValid = false;
    }
    if (accountBank === "") {
      setBankError(true);
      setBankErrorMessage("No puede quedar vacio");
      isValid = false;
    }

    return isValid;
  };

  const saveForm = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const newData = {
      account_name: accountName,
      account_bank: accountBank,
      account_clabe: accountClabe,
      trail_step: userInfo.trail_step == 2 ? 3 : userInfo.trail_step,
    };

    setLoading(true);
    try {
      const editedUser = await editUser(userToken, userInfo.id, newData);
      setLoading(false);
      if (editedUser.error) {
        setError(true);
        return;
      }
      await updateStep();
      setUpdated(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  const updateStep = async () => {
    dispatch({
      type: "SET_USER_STEP",
      payload: userInfo.trail_step == 2 ? 3 : userInfo.trail_step,
    });
  };

  return (
    <FormStyled>
      {userInfo.user_type === "trail" && updated ? (
        <Redirect to="/tablero/simulador" />
      ) : null}
      <h2 className="section-title">Cuenta para Retiros</h2>
      <p>
        Indicanos a qué cuenta depositar las ganancias de tus tokens o la venta
        de los mismos.
      </p>
      <p>Debe ser una cuenta a tu nombre.</p>
      <section>
        <div className="input-row">
          <TextField
            className="input"
            label="Titular de la cuenta"
            variant="outlined"
            error={nameError}
            helperText={nameErrorMessage}
            value={accountName}
            onChange={(event) => setAccountName(event.target.value)}
          />
        </div>
        <div className="input-row">
          <TextField
            className="input"
            label="Banco"
            variant="outlined"
            error={bankError}
            helperText={bankErrorMessage}
            value={accountBank}
            onChange={(event) => setAccountBank(event.target.value)}
          />
        </div>
        <div className="input-row">
          <TextField
            className="input"
            label="Clabe"
            variant="outlined"
            error={clabeError}
            helperText={clabeErrorMessage}
            value={accountClabe}
            onChange={(event) => setAccountClabe(event.target.value)}
          />
        </div>

        <p>
          Es necesario confirmar tu cuenta CLABE. Haz un deposito para tu
          siguiente inversión desde esa cuenta o enviando un correo a
          info@urvita.mx con un estado de cuenta en donde sea visible el nombre
          del banco, tu nombre y la cuenta CLABE.
        </p>

        {/* <h3>Para validar la actualización, ingresa tu contraseña</h3> */}

        <ActionButton
          text={loading ? "Guardando..." : "Guardar"}
          left
          onClick={() => saveForm()}
          disabled={loading}
          helperText={updated ? "Información actualizada" : null}
          error={error}
        />
      </section>
    </FormStyled>
  );
};

const FormStyled = styled.section`
  .row-title {
    font-weight: 200;
  }

  .input-row {
    display: flex;
    margin: 0 0 20px 0;
  }

  .input {
    margin-right: 10px;
  }

  .day {
    width: 100px;
  }

  .month,
  .country {
    width: 150px;
  }

  .year {
    width: 100px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 968px) {
    .day {
      width: 60px;
    }

    .month,
    .country {
      width: 120px;
    }

    .year {
      width: 80px;
    }
  }
`;

export default DepositForm;
