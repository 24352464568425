export async function getAffiliate(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/rewardful/affiliate/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function getCommissions(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/rewardful/commisions/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}
