import React from "react";
import styled from "styled-components";
import { colors } from "../constants";

const SectionTitle = ({ title, color, small }) => {
  return (
    <TitleStyled textColor={color} small={small}>
      <h1>{title}</h1>
    </TitleStyled>
  );
};

const TitleStyled = styled.header`
  h1 {
    color: ${(props) => (props.textColor ? props.textColor : colors.darkText)};
    font-family: "calibre";
    font-size: ${(props) => (props.small ? "1.1rem" : "1.5rem")};
    font-weight: ${(props) => (props.small ? 400 : 500)};
    margin: 0.5rem 0 1rem 0;
  }
`;

export default SectionTitle;
