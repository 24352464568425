async function getUserReport(userToken, user_id) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/report/${user_id}`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    );
    const resp = await response.json();
    return resp;
  } catch (err) {
    console.error(err);
  }
}

async function login(email, password) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const user = {
    email,
    password,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/login`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(user),
      }
    );
    const resp = await response.json();
    return resp;
  } catch (err) {
    console.error(err);
  }
}

async function getUserById(id) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/getrfc/${id}`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    );
    const resp = await response.json();
    return resp;
  } catch (err) {
    console.error(err);
  }
}

// Change reinvest or recive user settings
async function changeRentSettings(userToken, userRfc, rentSetting) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const reinvestSetting = {
    rent_setting: rentSetting,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${userRfc}`,
    {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(reinvestSetting),
    }
  );

  const data = await response.json();
  return data;
}

export { getUserReport, login, getUserById, changeRentSettings };
