import React from "react"
import { ProfileStyled } from "./styles"
import { colors } from "../constants"

import LogoutIcon from "../../../assets/icons/logoutIcon"
import MenuIcon from "../../../assets/icons/menuIcon"
import userAvatar from "../../../assets/img/user-avatar.png"

const Profile = (props) => {
  const { userName, logoutHandler, handleMenuClick, lightLogo } = props

  return (
    <ProfileStyled lightLogo={lightLogo}>
      <div className="profile-avatar">
        <img className="profile-img" src={userAvatar} alt="Avatar de usuario" />
        <div className="profile-info">
          <span className="profile-name">{userName}</span>
          <div className="logout" onClick={() => logoutHandler()}>
            <LogoutIcon
              className="logout-icon"
              color={lightLogo ? colors.lightText : colors.darkText}
            />
            <span className="logout-text">
              {userName ? "Cerrar sesión" : "Iniciar Sesión"}
            </span>
          </div>
        </div>
      </div>

      <div className="hamburger-menu" onClick={handleMenuClick}>
        <MenuIcon
          className="menu-icon"
          color={lightLogo ? colors.lightText : colors.darkText}
        />
      </div>
    </ProfileStyled>
  )
}

export default Profile
