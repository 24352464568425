import React, { useState, useEffect } from "react"
import { buildingImage } from "../../utils/format"
import styled from "styled-components"
import currency from "currency.js"
import { getBuildingTokens } from "../../services/tokens"
import { useSelector } from "react-redux"

export default function Building({ building, buildingsDetails }) {
  const user = useSelector((state) => state.user)
  const [details, setDetails] = useState()
  const [tokens, setTokens] = useState()

  useEffect(() => {
    const getTokenCount = async () => {
      const tokens = await getBuildingTokens(
        user.token,
        building.building_phase_id
      )
      setTokens(tokens)
    }
    getTokenCount()
  }, [])

  const isSoldOut = () => {
    const availableTokens = tokens.filter(
      (token) => token.available && token.type === "TOKEN-A"
    ).length

    return availableTokens === 0
  }

  useEffect(() => {
    const activeBuilding = buildingsDetails.filter(
      (phase) => phase.phase_id === building.building_phase_id
    )
    setDetails(activeBuilding[0])
  }, [buildingsDetails])

  return (
    <Container>
      {details && (
        <>
          {tokens && (
            <div className={`banner ${isSoldOut() ? "sold-out" : "available"}`}>
              <p className="title--banner">
                {isSoldOut() ? "Sold Out" : "Disponible"}
              </p>
              <span className="quantity">
                {
                  tokens.filter(
                    (token) => token.available && token.type === "TOKEN-A"
                  ).length
                }{" "}
                / {tokens.length}
              </span>
            </div>
          )}
          <img
            className="building"
            srcSet={`${buildingImage(building.building_name)} 1x`}
            src={buildingImage(building.building_name)}
            alt={`imagen de ${building.building_name}`}
          />
          <p className="building-name">{building.building_name}</p>
          <p className="building-location">
            {details.address_suburb}, {details.address_city},{" "}
            {details.address_state}, MX
          </p>
          <p className="building-price">
            <span>{currency(building.value_a, { precision: 0 }).format()}</span>{" "}
            MXN por token (m2)
          </p>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 250px;
  position: relative;

  .banner {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100px;
    right: 0;
    top: 20px;
    padding: 5px 0;
    padding-right: 7px;
    padding-left: 22px;
    font-family: "kansas";
    background-color: #e0a669;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    .title--banner {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
    }

    .quantity {
      color: white;
      font-size: 0.7em;
      font-weight: thin;
    }
    &.sold-out {
      background-color: #394538;

      .title--banner {
        color: #e0a669;
      }
    }

    &.available {
      .title--banner {
        color: #fff;
      }
    }
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }

  p {
    margin: 0;
  }

  .building-name {
    font-size: 1.2rem;
    font-weight: 500;
    color: #435b49;
  }

  .building-location {
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0px;
  }

  .building-price {
    font-weight: 500;
    color: #435b49;

    span {
      color: #f79f4d;
    }
  }

  @media (min-width: 768px) {
    width: 500px;
    img {
      height: 300px;
    }
  }

  @media (min-width: 768px) {
    width: auto;
    img {
      width: 250px;
      height: 150px;
    }
  }
`
