async function getBuildingTokens(userToken, phase_id) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/building/${phase_id}`,
    {
      method: "GET",
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const tokens = await response.json()
  return tokens.data
}

async function getAvailableCountByBuilding(userToken) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/available/true`,
    {
      method: "GET",
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const tokens = await response.json()
  return tokens.data
}

async function getUserTokens(userToken, userid) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/building/${userid}`,
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    }
  ).catch((err) => console.error(err))
  const tokens = await response.json()
  return tokens.data
}

export { getBuildingTokens, getUserTokens, getAvailableCountByBuilding }
