import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
// component
import TokenRow from "./tokenRow"
// layout
import { colors } from "../../layout/constants"
import Tooltip from "../../layout/shared/tooltip"
import DetailButton from "../../layout/shared/detailButton"

const TokenList = ({
  balance,
  userBuildings,
  lastBuyBuilding,
  tokenToBuy = "OPERATING"
}) => {
  const [operationBuildings, setOperationBuildings] = useState([])
  const [constructionBuildings, setConstructionBuildings] = useState([])
  const [preConstructionBuildings, setPreConstructionBuildings] = useState([])

  useEffect(() => {
    if (userBuildings) {
      const filterBuildings = () => {
        const operation = userBuildings?.filter(
          (building) => building.phase_status === "OPERATING"
        )
        const construction = userBuildings?.filter(
          (building) => building.phase_status === "CONSTRUCTION"
        )
        const preContruction = userBuildings?.filter(
          (building) => building.phase_status === "PRECONSTRUCTION"
        )

        setOperationBuildings(operation)
        setConstructionBuildings(construction)
        setPreConstructionBuildings(preContruction)
      }

      filterBuildings()
    }
  }, [userBuildings])

  return (
    <ListStyled>
      <header className="section-header">
        <h3 className="section-title">
          Tus m<sup>2</sup>
          <Tooltip
            content="Estos son los m2 que tienes en diferentes edificios. Cada token es una acción que representa un metro cuadrado que se puede rentar de uno de nuestros inmuebles."
            dark
          />
        </h3>
      </header>

      <TokenRow
        key="0"
        name="Llevas pagado"
        singleTokenValue={
          lastBuyBuilding.price
            ? lastBuyBuilding.price
            : lastBuyBuilding.tokenValue
        }
        totalTokensValue={balance}
        fraction={true}
        status={tokenToBuy}
      />

      {operationBuildings.length > 0 ? (
        <ul className="token-list">
          <li className="status-bullet operation">Operación</li>
          {operationBuildings.map((item) => {
            return (
              <TokenRow
                key={item.phase_id}
                name={item.name ? item.name : item.phase_name}
                singleTokenValue={
                  item.tokenValue ? item.tokenValue : item.single_token_value
                }
                totalTokensValue={
                  item.userTokensValue
                    ? item.userTokensValue
                    : item.single_token_value *
                      Math.trunc(item.total_tokens_count)
                }
                totalTokensCount={
                  item.userTokensCount
                    ? item.userTokensCount
                    : Math.trunc(item.total_tokens_count)
                }
              />
            )
          })}
        </ul>
      ) : null}

      {constructionBuildings.length > 0 ? (
        <ul className="token-list">
          <li className="status-bullet pre-sale">Construcción</li>
          {constructionBuildings.map((item) => {
            return (
              <TokenRow
                key={item.phase_id}
                name={item.name ? item.name : item.phase_name}
                singleTokenValue={
                  item.tokenValue ? item.tokenValue : item.single_token_value
                }
                totalTokensValue={
                  item.userTokensValue
                    ? item.userTokensValue
                    : item.single_token_value *
                      Math.trunc(item.total_tokens_count)
                }
                totalTokensCount={
                  item.userTokensCount
                    ? item.userTokensCount
                    : Math.trunc(item.total_tokens_count)
                }
              />
            )
          })}
        </ul>
      ) : null}

      {preConstructionBuildings.length > 0 ? (
        <ul className="token-list">
          <li className="status-bullet launch">Lanzamiento</li>
          {preConstructionBuildings.map((item) => {
            return (
              <TokenRow
                key={item.phase_id}
                name={item.name ? item.name : item.phase_name}
                singleTokenValue={
                  item.tokenValue ? item.tokenValue : item.single_token_value
                }
                totalTokensValue={
                  item.userTokensValue
                    ? item.userTokensValue
                    : item.single_token_value *
                      Math.trunc(item.total_tokens_count)
                }
                totalTokensCount={
                  item.userTokensCount
                    ? item.userTokensCount
                    : Math.trunc(item.total_tokens_count)
                }
              />
            )
          })}
        </ul>
      ) : null}
    </ListStyled>
  )
}

const ListStyled = styled.section`
  .token-list {
    list-style: none;
    padding: 0;
  }

  .section-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .status-bullet {
    border-radius: 40px;
    color: ${colors.darkText};
    display: inline-block;
    font-weight: 500;
    margin: 1rem 0;
    width: 100px;

    /* &.operation {
      background-color: ${colors.operationColor};
    }

    &.pre-sale {
      background-color: ${colors.preSaleColor};
    }

    &.launch {
      background-color: ${colors.launchColor};
    } */
  }

  .token-message {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .cta-container {
    margin: 0 auto;
  }

  @media (max-width: 968px) {
    /* grid-row: 1/2; */
  }
`

export default TokenList
