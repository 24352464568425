export async function newSubscription(userToken, subscriptionData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/subscriptions`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(subscriptionData)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export async function getUserSubscription(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/subscriptions/user/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export async function updateSubscription(userToken, subscriptionId, newData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/subscriptions/${subscriptionId}`,
    {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(newData)
    }
  )

  const data = await response.json()
  return data
}
