import React, { useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
// assets
import icon from "../../assets/icons/kyc.svg"
// layout

import ActionButton from "../layout/shared/actionButton"
import Loader from "../reports/loader"
import { colors } from "../layout/constants"
//services
import { generateWeetrustContract } from "../../services/users"
import { setUserData } from "../../state/actions"
// analytics
import ReactGA from "react-ga4";

const DataConfirmation = ({ setModal }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const userData = useSelector((state) => state.userData)

  const [loading, setLoading] = useState(false)
  const [generated, setGenerated] = useState(false)
  const [error, setError] = useState(false)

  const generateContract = async () => {
    try {
      setLoading(true)
      await generateWeetrustContract(user.token, user.id)
      setUserData(dispatch, { contract_status: "toCreate" })
      setGenerated(true)
      setLoading(false)

      ReactGA.event({
        category: "User",
        action: "contrato enviado"
      })
    } catch (error) {
      setError(true)
    }
  }

  const personSummary = () => {
    return (
      <ul className='data-list'>
        <li className='data-field'>
          <span className='field-name'>Nombre completo: </span> {userData.name}{" "}
          {userData.last_name}
        </li>
        <li className='data-field'>
          <span className='field-name'>Fecha de nacimiento: </span>
          {userData.birthday_date}
        </li>
        <li className='data-field'>
          <span className='field-name'>Nacionalidad: </span>
          {userData.citizenship}
        </li>
        <li className='data-field'>
          <span className='field-name'>Estado civil: </span>
          {userData.marital_status}
        </li>
        <li className='data-field'>
          <span className='field-name'>Teléfono: </span>
          {userData.phone}
        </li>
        <li className='data-field'>
          <span className='field-name'>
            {userData.id_type
              ? userData.id_type === "PASSPORT"
                ? "Pasaporte"
                : userData.id_type
              : "ID"}
            :
          </span>{" "}
          {userData.id_number}
        </li>
        <li className='data-field'>
          <span className='field-name'>RFC: </span>
          {userData.rfc}
        </li>
        <li className='data-field'>
          <span className='field-name'>Dirección de facturación: </span>
          {userData.address_street} #{userData.address_number},{" "}
          {userData.address_interior ? `${userData.address_interior},` : null}{" "}
          {userData.address_suburb}, {userData.address_city},{" "}
          {userData.address_state}, {userData.address_country}
        </li>
        <li className='data-field'>
          <span className='field-name'>Código postal: </span>
          {userData.address_postal_code}
        </li>
      </ul>
    )
  }

  const companySumary = () => {
    return (
      <ul className='data-list'>
        <li className='data-field'>
          <span className='field-name'>Nombre completo: </span> {userData.name}{" "}
        </li>
        <li className='data-field'>
          <span className='field-name'>Teléfono: </span>
          {userData.phone}
        </li>
        <li className='data-field'>
          <span className='field-name'>
            {userData.id_type
              ? userData.id_type === "PASSPORT"
                ? "Pasaporte"
                : userData.id_type
              : "ID"}
            :
          </span>{" "}
          {userData.id_number}
        </li>
        <li className='data-field'>
          <span className='field-name'>RFC: </span>
          {userData.rfc}
        </li>

        <li className='data-field'>
          <span className='field-name'>Razón social: </span>
          {userData.company_name}
        </li>
        <li className='data-field'>
          <span className='field-name'>Número de escritura: </span>
          {userData.company_deeds}
        </li>
        <li className='data-field'>
          <span className='field-name'>Número de notaría: </span>
          {userData.company_notary_number}
        </li>
        <li className='data-field'>
          <span className='field-name'>Fecha de constitución: </span>
          {userData.company_constitution_date}
        </li>
        <li className='data-field'>
          <span className='field-name'>Folio mercantil electrónico: </span>
          {userData.company_comercial_folio}
        </li>
        <li className='data-field'>
          <span className='field-name'>Nombre del notario: </span>
          {userData.company_notary_name}
        </li>

        <li className='data-field'>
          <span className='field-name'>País de constitución: </span>
          {userData.address_country}
        </li>

        <li className='data-field'>
          <span className='field-name'>Dirección de facturación: </span>
          {userData.address_street} #{userData.address_number},{" "}
          {userData.address_interior ? `${userData.address_interior},` : null}{" "}
          {userData.address_suburb}, {userData.address_city},{" "}
          {userData.address_state}, {userData.address_country}
        </li>
        <li className='data-field'>
          <span className='field-name'>Código postal: </span>
          {userData.address_postal_code}
        </li>
      </ul>
    )
  }

  return (
    <ConfirmationWrapper>
      {loading ? (
        <div>
          <Loader message='Estamos generando tu contrato' />
        </div>
      ) : generated ? (
        <div>
          <img src={icon} alt=''></img>
          {error ? (
            <p> Ocurrio un error, cierra el navegador y vuelve a intentarlo </p>
          ) : (
            <div>
              <h3>¡Tu contrato está listo!</h3>
              <p>
                Revisa tu correo electrónico y sigue las instrucciones para
                poder completar el proceso de firma.
              </p>
              <p>
                Ten en cuenta que para validar tu identidad necesitarás acceder
                desde tu celular.
              </p>
            </div>
          )}

          <ActionButton
            text='Continuar'
            disabled={error}
            onClick={() => window.location.reload(false)}
          />
        </div>
      ) : (
        <div>
          <p>
            Valida que tu información este completa. Con estos datos se creará
            tu contrato, es muy importante que te asegures que sean correctos.
          </p>
          {userData?.tax_regime.includes("P_FISICA")
            ? personSummary()
            : companySumary()}
          <ActionButton onClick={() => generateContract()} text='Confirmar' />
          <p>
            Crearemos un contrato con tus datos y lo enviaremos a tu correo para
            que puedas leerlo y firmarlo cuando estes listo.
          </p>{" "}
        </div>
      )}
    </ConfirmationWrapper>
  )
}

const ConfirmationWrapper = styled.section`
  .data-list {
    list-style: none;
    padding: 0;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .data-field {
    color: ${colors.darkText};
    border-bottom: 1px solid gray;
    display: grid;
    grid-template-columns: 200px 1fr;
    padding: 0.5rem;
  }

  .field-name {
    font-weight: 500;
  }

  @media (max-width: 600px) {
    .data-field {
      grid-template-columns: 1fr;
    }
  }
`

export default DataConfirmation
