import React from "react"
import styled from "styled-components"
import { Link, useLocation, useHistory } from "react-router-dom"

// layout
import { colors } from "../constants"
import Wrapper from "../wrapper"
// assets
import UrvitaLogo from "../../../assets/logos/logo-urvita-light.png"

import Profile from "./profile"
import { useSelector } from "react-redux"

const Navbar = ({
  userName,
  simple,
  lightLogo,
  handleMenuClick,
  logoutHandler,
  dashboard,
  handleModalClick
}) => {
  let location = useLocation()
  let history = useHistory()
  const cart = useSelector((state) => state.cart)
  const linksData = [
    { label: "Simulador", route: "/simulador" },
    { label: "Perfil", route: "/tablero/perfil" },
    // { label: "Movimiento", route: "/edificios" },
    { label: "Edificios", route: "/edificios" },
    { label: "Embajador", route: "/embajador" }
  ]

  const countOrder = () => {
    const orderArray = Object.keys(cart).map((building) => {
      return cart[building]
    })

    return orderArray.reduce((count, order) => {
      count += Number(order.quantity)

      return count
    }, 0)
  }

  return (
    <NavStyled lightLogo={lightLogo} dashboard={dashboard}>
      <Wrapper>
        <div className="nav-container">
          <Link className="logo-container" to="/bienvenido">
            <img
              className="urvita-logo"
              src={UrvitaLogo}
              alt="logo de Urvita"
            />
          </Link>

          {dashboard ? (
            <ul className="navegation-list">
              <li className="navegation-item">
                <Link
                  to="/tablero"
                  active={location.pathname === "/tablero" ? "true" : "false"}
                >
                  Tablero
                </Link>
              </li>

              {linksData.map((link, index) => {
                const { label, route } = link
                return (
                  <li className="navegation-item" key={index}>
                    <Link
                      to={route}
                      active={
                        location.pathname.substring(0, 15) ===
                        `tablero/${route}`
                          ? "true"
                          : "false"
                      }
                    >
                      {label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          ) : null}
          {simple ? null : (
            <>
              <div
                onClick={() => history.push("/selecciona-fracciones")}
                className="cart"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-shopping-cart"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#fecfa4"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M17 17h-11v-14h-2" />
                  <path d="M6 5l14 1l-1 7h-13" />
                </svg>

                <div className="quantity">+{countOrder()}</div>
              </div>
              <Profile
                userName={userName ? userName : null}
                logoutHandler={logoutHandler}
                handleMenuClick={handleMenuClick}
                lightLogo={lightLogo}
              />
            </>
          )}
        </div>
      </Wrapper>
    </NavStyled>
  )
}

const NavStyled = styled.header`
  background-color: ${(props) =>
    props.dashboard || props.lightLogo ? colors.backgroundDark : "transaprent"};
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media print {
    display: none;
  }

  .nav-container {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;
  }

  .urvita-logo {
    width: 100px;
  }

  .navegation-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0 auto 0 25px;
  }

  .navegation-item a {
    color: ${(props) => (props.lightLogo ? colors.lightText : colors.darkText)};
    margin-right: 10px;

    :hover {
      color: ${(props) =>
        props.lightLogo ? colors.highlightColor : colors.darkText};
    }

    &[active="true"] {
      color: ${(props) =>
        props.lightLogo ? colors.highlightColor : colors.darkText};
    }
  }

  .cart {
    position: relative;
    margin: 0 1rem;
    cursor: pointer;

    .quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
      position: absolute;
      top: 20px;
      right: -12px;
      background: #fff;
      border-radius: 100%;
      font-size: 14px;
    }
  }

  @media (max-width: 968px) {
    .navegation-list {
      display: none;
    }

    .cart {
      display: none;
    }
  }
`

export default Navbar
