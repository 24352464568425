import React, { useEffect, useState } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import currency from "currency.js"
import moment from "moment"
// Layout
import Layout from "../layout"
import { colors, dateFormat } from "../layout/constants"
import EditIcon from "@mui/icons-material/Edit"
// services
import {
  getUserSubscription,
  updateSubscription
} from "../../services/subscriptions"
import { listPaymentMethods } from "../../services/stripe"
import DetailButton from "../layout/shared/detailButton"

const Background = styled.section`
  background-color: ${colors.primaryDark};
`

const Wrapper = styled.div`
  text-align: center;
  min-height: 80vh;
  padding: 1.7rem;
  color: ${colors.white};
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 650px;
  margin: 0 auto;

  .title {
    font-weight: 700;
    font-size: 1.7rem;
    color: #e7e8e2;
    margin-bottom: 2rem;
  }

  .subscription-info {
    border-radius: 8px;
    background-color: #e7e8e2dd;
    padding: 0.5rem 1rem;
    color: black;
  }

  .transparent-input {
    background-color: transparent;
    color: ${colors.primaryDark};
    border: none;
    border-bottom: 2px solid ${colors.primaryDark};
    width: 50%;
    height: 2rem;
    font-size: 1.5rem;
    padding: 0.3rem 0.5rem;
    outline: none;
    box-shadow: none;
  }

  .payment-options {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .btn {
    border: none;
    color: ${colors.white};
    background-color: transparent;
    cursor: pointer;
    margin: 0.5rem 0;
  }

  .container-buttons {
    margin-top: 1.5rem;
  }

  .section-foot {
    display: flex;
    justify-content: space-evenly;

    p {
      flex: 1 1 auto;
      text-align: center;
    }

    p + p {
      border-left: solid 1px #c2c3bd;
      text-align: center;
    }
    a,
    a:visited,
    a:link {
      font-weight: 700;
      color: inherit;
      text-decoration: none;
    }
  }

  .text-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0.5rem 0;
    flex-wrap: wrap;

    .edit-icon {
      cursor: pointer;
      &:hover {
        color: ${colors.secondaryDark};
      }
    }

    .small-button {
      background-color: ${colors.secondaryDark};
      color: ${colors.primaryDark};
      font-weight: bold;
      cursor: pointer;
      outline: none;
      border: 1px solid ${colors.secondaryDark};
      border-radius: 4px;
      padding: 0.5rem 1rem;
      margin: 0 2px;

      &:hover {
        background-color: ${colors.secondaryColor};
        border-color: ${colors.secondaryColor};
      }
    }
  }

  @media (min-width: 900px) {
    text-align: left;
    padding: 2rem 3rem 0rem;

    .payment-options {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const ConfigureSubscription = () => {
  // Redux hooks
  const userId = useSelector((state) => state.user.id)
  const userToken = useSelector((state) => state.user.token)
  const [subscription, setSubscription] = useState()
  const [paymentMethods, setPaymentMethods] = useState()
  const [amount, setAmount] = useState()
  const [date, setDate] = useState()
  const [activeForm, setActiveForm] = useState("none")
  const [loading, setLoading] = useState(false)

  const getSus = async () => {
    const { data } = await getUserSubscription(userToken, userId)
    const paymentData = await listPaymentMethods(userToken, userId)
    setSubscription(data)
    if (paymentData.statusCode !== 404 && paymentData.length) {
      console.error("error", paymentData)
      setPaymentMethods(paymentData)
    }
  }

  useEffect(() => {
    getSus()
  }, [userId, userToken])

  const updateSubscriptionSettings = async (type, value) => {
    try {
      if (type === "amount") {
        await updateSubscription(userToken, subscription.id, {
          subscription_amount: Number(amount)
        })
      } else if (type === "date") {
        await updateSubscription(userToken, subscription.id, {
          subscription_charge_date: Number(moment(date).format("DD"))
        })
      } else if (type === "card") {
        console.log(value)
      }
      getSus()
      setActiveForm("none")
    } catch (error) {
      console.error(error)
    }
  }

  const getDate = () => {
    if (
      Number(moment().format("dd")) >
      Number(subscription.subscription_charge_date)
    ) {
      return dateFormat(moment().add(1, "M"), "month")
    }
    return dateFormat(moment(), "month")
  }

  const onHandlePauseSubscription = async () => {
    setLoading(true)
    await updateSubscription(userToken, subscription.id, {
      subscription_active: !subscription.subscription_active
    })
    await getSus()
    setLoading(false)
  }
  return (
    <Layout lightLogo dashboard>
      <Background>
        <Wrapper>
          <section>
            <h1 className="title">Configura tu suscripción</h1>

            <p>
              Aquí puedes cambiar el monto de tu suscripción de manera rápida y
              sencilla, y lo mejor de todo, ¡estarás invirtiendo en tu
              patrimonio!
            </p>

            {subscription && (
              <div className="subscription-info">
                <h3>
                  Suscripción{" "}
                  {subscription.subscription_active ? "Activa" : "Pausada"}
                </h3>
                <div className="text-row">
                  Monto actual de suscripción:{" "}
                  <b>{currency(subscription.subscription_amount).format()}</b>
                  {activeForm !== "amount" && (
                    <EditIcon
                      className="edit-icon"
                      onClick={() => setActiveForm("amount")}
                    />
                  )}
                  {activeForm === "amount" && (
                    <article>
                      <NumberFormat
                        onValueChange={(value) => {
                          setAmount(value.value)
                        }}
                        variant="outlined"
                        thousandSeparator
                        label="Cantidad de Suscripción"
                        placeholder="MXN $"
                        prefix="MXN $"
                        margin="normal"
                        name="amount"
                        autoFocus
                        className="transparent-input"
                      />
                      <button
                        className="small-button"
                        onClick={() => updateSubscriptionSettings("amount")}
                      >
                        Guardar
                      </button>
                    </article>
                  )}
                </div>
                <div className="text-row">
                  Próxima fecha de cobro:{" "}
                  <b>
                    {subscription.subscription_charge_date} de {getDate()}
                  </b>
                  {activeForm !== "date" && (
                    <EditIcon
                      className="edit-icon"
                      onClick={() => setActiveForm("date")}
                    />
                  )}
                  {activeForm === "date" && (
                    <article>
                      <input
                        type="date"
                        onChange={(event) => {
                          setDate(event.target.value)
                        }}
                      />
                      <button
                        className="small-button"
                        onClick={() => updateSubscriptionSettings("date")}
                      >
                        Guardar
                      </button>
                    </article>
                  )}
                </div>
                {paymentMethods && (
                  <div className="text-row">
                    Método de Pago:{" "}
                    <b>
                      {paymentMethods && paymentMethods[0].card.brand} terminada
                      en {paymentMethods && paymentMethods[0].card.last4}
                    </b>
                    {/* {activeForm !== "card" && (
                    <EditIcon
                      className="edit-icon"
                      onClick={() => setActiveForm("card")}
                    />
                  )} */}
                    {activeForm === "card" && (
                      <select
                        onChange={(event) =>
                          updateSubscriptionSettings("card", event.target.value)
                        }
                      >
                        {paymentMethods &&
                          paymentMethods.map((paymentMethod) => (
                            <option
                              key={paymentMethod.id}
                              value={paymentMethod.id}
                            >
                              {paymentMethod.card.brand} terminada en{" "}
                              {paymentMethod.card.last4}
                            </option>
                          ))}
                        <option>Nueva tarjeta</option>
                      </select>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="container-buttons">
              <DetailButton
                onClick={onHandlePauseSubscription}
                text={
                  loading
                    ? "Cargando..."
                    : subscription?.subscription_active
                    ? "Pausar suscripción"
                    : "Activar suscripción"
                }
              />
            </div>
          </section>

          <section className="section-foot">
            <p>
              Powered by{" "}
              <a
                href="https://stripe.com/mx"
                rel="noopener noreferrer"
                target="_blank"
              >
                STRIPE &copy;
              </a>
            </p>
            <p>
              <Link to="/terminos">Términos y condicions</Link>
            </p>
          </section>
        </Wrapper>
      </Background>
    </Layout>
  )
}

export default ConfigureSubscription
