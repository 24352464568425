import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// layout
import DefaultLayout from "./";
import Footer from "./footer";
import Wrapper from "./wrapper";
import { colors } from "./constants";

const NotFoundPage = () => {
  return (
    <DefaultLayout simple>
      <Wrapper>
        <PageStyled>
          <h1>
            La página que estas buscando no existe, pero puedes visitar alguna
            de estas opciones:
          </h1>
          <ul>
            <li>
              <a href="https://urvitaeverywhere.com">
                Información general - ¿Qué es Urvita?
              </a>
            </li>
            <li>
              <Link to="/tablero">Tablero - Todo sobre tu inversión</Link>
            </li>
            <li>
              <Link to="/simulador">
                Simulador - Descubre cuanto podrías generar en unos años
              </Link>
            </li>
          </ul>
        </PageStyled>
        <Footer />
      </Wrapper>
    </DefaultLayout>
  );
};

const PageStyled = styled.section`
  a {
    color: ${colors.darkText};

    :hover {
      color: ${colors.highlightColor};
    }
  }
`;

export default NotFoundPage;
