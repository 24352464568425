export async function getCheckoutSession(userToken, sessionId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/checkout/${sessionId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function openCheckoutSession(
  userToken,
  initialPayment,
  subscription,
  modeSession,
  userId,
  referalId,
  installments
) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/checkout`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          initialPayment,
          subscription,
          modeSession,
          userId,
          referalId,
          installments
        })
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function openPaymentIntent(userToken, { amount, userId, type }) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          amount,
          type,
          userId
        })
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function getUserPaymentIntent(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent/user/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function updatePaymentIntent(userToken, intentId, newValues) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent/${intentId}`,
      {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(newValues)
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function cancelPaymentIntent(userToken, intentId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent/${intentId}`,
      {
        method: "DELETE",
        headers: myHeaders
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}

export async function getFundingInstructions(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent/instructions/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const { bank_transfer } = await response.json()
    const spei = bank_transfer.financial_addresses[0].spei

    return spei
  } catch (err) {
    console.error(err)
  }
}

export async function listPaymentMethods(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentMethods/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )
    const paymentMethods = await response.json()

    return paymentMethods
  } catch (err) {
    console.error(err)
  }
}

export async function updateStripeSubscription(
  userToken,
  subscriptionId,
  newValues
) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/subscription/${subscriptionId}`,
      {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(newValues)
      }
    )
    const paymentMethods = await response.json()

    return paymentMethods
  } catch (err) {
    console.error(err)
  }
}
