// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("urvita-user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("urvita-token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("urvita-token");
  sessionStorage.removeItem("urvita-user");
};

// set the token and user from the session storage
export const setUserSession = (token, user, type) => {
  sessionStorage.setItem("urvita-token", token);
  sessionStorage.setItem("urvita-user", JSON.stringify(user));
};
