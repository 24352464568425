import React, { useState } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
// Material UI
import TextField from "@material-ui/core/TextField"
// layout
import { colors } from "../layout/constants"
import ActionButton from "../layout/shared/actionButton"
import CountrySelect from "../layout/shared/countrySelect"
// services
import { editUser } from "../../services/users"
import { setUserData } from "../../state/actions"

const BusinessForm = ({ setStep }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const userData = useSelector((state) => state.userData)
  const [country, setCountry] = useState(userData?.citizenship)

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm()

  const onSubmit = async (data) => {
    data.citizenship = country
    data.tax_regime = country !== "Mexico" ? "P_MORAl_EX_USA" : "P_MORAL"
    try {
      const newuser = await editUser(user.token, user.id, data)
      setUserData(dispatch, data)
      setStep(2)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Información de la empresa </h3>
        <div className="input-row">
          <TextField
            fullWidth
            label="Razón Social"
            variant="outlined"
            error={errors.company_name ? true : false}
            helperText={
              errors.company_name?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{ ...register("company_name", { required: true }) }}
            defaultValue={userData?.company_name}
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            label="RFC Razon Social"
            variant="outlined"
            error={errors.rfc ? true : false}
            helperText={
              errors.rfc?.type === "required" && "El campo es requerido"
            }
            inputProps={{ ...register("rfc", { required: true }) }}
            defaultValue={userData?.rfc}
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            label="Número de escritura"
            variant="outlined"
            error={errors.company_deeds ? true : false}
            helperText={
              errors.company_deeds?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{ ...register("company_deeds", { required: true }) }}
            defaultValue={userData?.company_deeds}
          />
          <TextField
            fullWidth
            label="Número de notaría"
            variant="outlined"
            error={errors.company_notary_number ? true : false}
            helperText={
              errors.company_notary_number?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{
              ...register("company_notary_number", { required: true })
            }}
            defaultValue={userData?.company_notary_number}
          />
        </div>

        <div className="input-row">
          <TextField
            key="Confirmation Code"
            label="Fecha de constitución"
            type="date"
            variant="outlined"
            error={errors.company_constitution_date ? true : false}
            helperText={
              errors.company_constitution_date?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{
              ...register("company_constitution_date", { required: true })
            }}
            defaultValue={
              userData?.company_constitution_date
                ? userData?.company_constitution_date
                : "2000-01-01"
            }
          />
        </div>
        <div className="input-row">
          <CountrySelect
            fullWidth
            label="Nacionalidad"
            onChange={(value) => setCountry(value.label)}
            userValue={userData?.citizenship ? userData?.citizenship : ""}
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            label="Folio mercantil electrónico"
            variant="outlined"
            error={errors.company_comercial_folio ? true : false}
            helperText={
              errors.company_comercial_folio?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{
              ...register("company_comercial_folio", { required: true })
            }}
            defaultValue={userData?.company_comercial_folio}
          />
        </div>
        <div className="input-row">
          <TextField
            fullWidth
            label="Nombre del notario"
            variant="outlined"
            error={errors.company_notary_name ? true : false}
            helperText={
              errors.company_notary_name?.type === "required" &&
              "El campo es requerido"
            }
            inputProps={{
              ...register("company_notary_name", { required: true })
            }}
            defaultValue={userData?.company_notary_name}
          />
        </div>

        <ActionButton text="Siguiente" type="submit" />
      </form>
    </FormWrapper>
  )
}

const FormWrapper = styled.section`
  .input-row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 600px) {
    .input-row {
      flex-direction: column;
    }
  }
`

export default BusinessForm
