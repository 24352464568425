import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// Material UI
import TextField from "@material-ui/core/TextField";
// layout
import { colors } from "../layout/constants";
import CountrySelect from "../layout/shared/countrySelect";
import ActionButton from "../layout/shared/actionButton";
// services
import { editUser } from "../../services/users";
import { setUserData } from "../../state/actions";

const AddressForm = ({ setStep }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userData = useSelector((state) => state.userData);
  const [country, setCountry] = useState(userData?.address_country);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    data.address_country = country;
    try {
      await editUser(user.token, user.id, data);
      setUserData(dispatch, data);
      setStep(4);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Dirección de facturación</h3>

        <div className="input-row">
          <TextField
            fullWidth
            label="Calle"
            variant="outlined"
            defaultValue={userData?.address_street}
            inputProps={{ ...register("address_street", { required: true }) }}
            error={errors.address_street ? true : false}
            helperText={
              errors.address_street?.type === "required" &&
              "El campo es requerido"
            }
          />
        </div>

        <div className="input-row">
          <TextField
            label="Número"
            variant="outlined"
            defaultValue={userData?.address_number}
            inputProps={{ ...register("address_number", { required: true }) }}
            error={errors.address_number ? true : false}
            helperText={
              errors.address_number?.type === "required" &&
              "El campo es requerido"
            }
          />
          <TextField
            className="input"
            label="Interior"
            variant="outlined"
            defaultValue={userData?.address_interior}
            inputProps={{ ...register("address_interior") }}
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            className="input"
            label="Colonia, Municipio, Del. o Alc."
            variant="outlined"
            defaultValue={userData?.address_suburb}
            inputProps={{ ...register("address_suburb", { required: true }) }}
            error={errors.address_suburb ? true : false}
            helperText={
              errors.address_suburb?.type === "required" &&
              "El campo es requerido"
            }
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            className="input"
            label="Ciudad"
            variant="outlined"
            defaultValue={userData?.address_city}
            inputProps={{ ...register("address_city", { required: true }) }}
            error={errors.address_city ? true : false}
            helperText={
              errors.address_city?.type === "required" &&
              "El campo es requerido"
            }
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            className="input"
            label="Estado"
            variant="outlined"
            defaultValue={userData?.address_state}
            inputProps={{ ...register("address_state", { required: true }) }}
            error={errors.address_state ? true : false}
            helperText={
              errors.address_state?.type === "required" &&
              "El campo es requerido"
            }
          />
        </div>

        <div className="input-row">
          <CountrySelect
            fullWidth
            label="Pais"
            onChange={(value) => setCountry(value.label)}
            userValue={
              userData?.address_country ? userData?.address_country : ""
            }
          />
        </div>

        <div className="input-row">
          <TextField
            fullWidth
            className="input"
            label="Código postal"
            variant="outlined"
            defaultValue={userData?.address_postal_code}
            inputProps={{
              ...register("address_postal_code", { required: true }),
            }}
            error={errors.address_postal_code ? true : false}
            helperText={
              errors.address_postal_code?.type === "required" &&
              "El campo es requerido"
            }
          />
        </div>
        <ActionButton text="Siguiente" type="submit" />
      </form>
    </FormWrapper>
  );
};

const FormWrapper = styled.section`
  .input-row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 600px) {
    .input-row {
      flex-direction: column;
    }
  }
`;

export default AddressForm;
