import React, { useState } from "react"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
// layout
import { colors, countries } from "../../layout/constants"
import ActionButton from "../../layout/shared/actionButton"
// services
import { editUser } from "../../../services/users"

const TaxesForm = ({ userInfo, userStep }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  // state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updated, setUpdated] = useState(false)
  // inputs
  const [rfc, setRfc] = useState(userInfo.rfc ? userInfo.rfc : "")
  const [rfcError, setRfcError] = useState(false)
  const [rfcErrorMessage, setRfcErrorMessage] = useState("")

  const [taxRegime, setTaxRegime] = useState(
    userInfo.tax_regime ? userInfo.tax_regime : ""
  )
  const [taxRegimeError, setTaxRegimeError] = useState(false)
  const [taxRegimeErrorMessage, setTaxRegimeErrorMessage] = useState("")

  // Company
  const [companyName, setCompanyName] = useState(userInfo.company_name || "")
  const [companyDeeds, setCompanyDeeds] = useState(userInfo.company_deeds || "")
  const [companyNotaryNumber, setCompanyNotaryNumber] = useState(
    userInfo.company_notary_number || ""
  )
  const [companyConstitutionDate, setCompanyConstitutionDate] = useState(
    userInfo.company_constitution_date || ""
  )
  const [companyComercialFolio, setCompanyComercialFolio] = useState(
    userInfo.company_comercial_folio || ""
  )
  const [companyNotaryName, setCompanyNotaryName] = useState(
    userInfo.company_notary_name || ""
  )

  // Address
  const [street, setStreet] = useState(
    userInfo.address_street ? userInfo.address_street : ""
  )
  const [streetNumber, setStreetNumber] = useState(
    userInfo.address_number ? userInfo.address_number : ""
  )
  const [interiorNumber, setInteriorNumber] = useState(
    userInfo.address_interior ? userInfo.address_interior : ""
  )
  const [suburb, setSuburb] = useState(
    userInfo.address_suburb ? userInfo.address_suburb : ""
  )
  const [city, setCity] = useState(
    userInfo.address_city ? userInfo.address_city : ""
  )
  const [state, setState] = useState(
    userInfo.address_state ? userInfo.address_state : ""
  )
  const [country, setCountry] = useState(
    userInfo.address_country ? userInfo.address_country : "Mexico"
  )
  const [postalCode, setPostalCode] = useState(
    userInfo.address_postal_code ? userInfo.address_postal_code : ""
  )

  const validateForm = () => {
    let isValid = true
    if (rfc === "") {
      setRfcError(true)
      setRfcErrorMessage("No puede quedar vacío")
      isValid = false
    }
    if (taxRegime === "") {
      setTaxRegimeError(true)
      setTaxRegimeErrorMessage("No puede quedar vacío")
      isValid = false
    }
    return isValid
  }

  const saveForm = async () => {
    const isValid = validateForm()
    if (!isValid) {
      return
    }

    const newData = {
      rfc,
      tax_regime: country == "Mexico" ? taxRegime : `${taxRegime}_EX`,
      address_street: street,
      address_number: streetNumber,
      address_interior: interiorNumber,
      address_suburb: suburb,
      address_city: city,
      address_state: state,
      address_country: country,
      address_postal_code: postalCode,
      trail_step: userInfo.trail_step == 1 ? 2 : userInfo.trail_step
    }

    const companyData = {
      company_name: companyName,
      company_deeds: companyDeeds,
      company_notary_number: companyNotaryNumber,
      company_constitution_date: companyConstitutionDate,
      company_comercial_folio: companyComercialFolio,
      company_notary_name: companyNotaryName
    }

    setLoading(true)
    try {
      const editedUser = await editUser(userToken, userInfo.id, newData)
      if (taxRegime === "P_MORAL") {
        await editUser(userToken, userInfo.id, companyData)
      }
      setLoading(false)
      if (editedUser.error) {
        setError(true)
        return
      }
      await updateStep()
      setUpdated(true)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setError(true)
    }
  }

  const updateStep = async () => {
    dispatch({
      type: "SET_USER_STEP",
      payload: userInfo.trail_step == 1 ? 2 : userInfo.trail_step
    })
  }

  return (
    <FormStyled>
      {userInfo.user_type === "trail" && updated ? (
        <Redirect to="/tablero/perfil/retiro" />
      ) : null}
      <h2 className="section-title">Datos Fiscales</h2>
      <section>
        <div className="input-row">
          <TextField
            className="input"
            label="RFC"
            variant="outlined"
            error={rfcError}
            helperText={rfcErrorMessage}
            value={rfc}
            onChange={(event) => setRfc(event.target.value)}
          />
          <TextField
            className="input country"
            select
            label="Regimen Fiscal"
            error={taxRegimeError}
            helperText={taxRegimeErrorMessage}
            variant="outlined"
            value={taxRegime}
            onChange={(event) => setTaxRegime(event.target.value)}
            disabled
          >
            <MenuItem value="P_FISICA">Persona Física</MenuItem>
            <MenuItem value="P_MORAL">Persona Moral</MenuItem>
          </TextField>
        </div>

        {taxRegime === "P_MORAL" && (
          <>
            <h3 className="row-title">Información de la Empresa</h3>
            <div className="input-row">
              <TextField
                className="input codigo"
                label="Razón Social"
                variant="outlined"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
              <TextField
                className="input codigo"
                label="Fecha de constitución"
                type="date"
                variant="outlined"
                value={companyConstitutionDate}
                onChange={(event) =>
                  setCompanyConstitutionDate(event.target.value)
                }
              />
            </div>
            <div className="input-row">
              <TextField
                className="input codigo"
                label="Número de Escritura"
                variant="outlined"
                value={companyDeeds}
                onChange={(event) => setCompanyDeeds(event.target.value)}
              />
              <TextField
                className="input codigo"
                label="Número de Notaría"
                variant="outlined"
                value={companyNotaryNumber}
                onChange={(event) => setCompanyNotaryNumber(event.target.value)}
              />
            </div>

            <div className="input-row">
              <TextField
                className="input codigo"
                label="Folio mercantil"
                variant="outlined"
                value={companyComercialFolio}
                onChange={(event) =>
                  setCompanyComercialFolio(event.target.value)
                }
              />
              <TextField
                className="input codigo"
                label="Nombre del notario"
                variant="outlined"
                value={companyNotaryName}
                onChange={(event) => setCompanyNotaryName(event.target.value)}
              />
            </div>
          </>
        )}

        <h3 className="row-title">Dirección de Facturación</h3>
        <div className="input-row">
          <TextField
            className="input codigo"
            label="Calle"
            variant="outlined"
            value={street}
            onChange={(event) => setStreet(event.target.value)}
          />
          <TextField
            className="input numero"
            label="Número"
            variant="outlined"
            value={streetNumber}
            onChange={(event) => setStreetNumber(event.target.value)}
          />
          <TextField
            className="input numero"
            label="Interior"
            variant="outlined"
            value={interiorNumber}
            onChange={(event) => setInteriorNumber(event.target.value)}
          />
        </div>

        <div className="input-row">
          <TextField
            className="input"
            label="Colonia"
            variant="outlined"
            value={suburb}
            onChange={(event) => setSuburb(event.target.value)}
          />
          <TextField
            className="input"
            label="Ciudad"
            variant="outlined"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
        </div>

        <div className="input-row">
          <TextField
            className="input"
            label="Estado"
            variant="outlined"
            value={state}
            onChange={(event) => setState(event.target.value)}
          />
          <TextField
            className="input country"
            select
            label="Pais"
            variant="outlined"
            value={country}
            onChange={(event) => setCountry(event.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="input codigo"
            label="Código Postal"
            variant="outlined"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
          />
        </div>

        <ActionButton
          text={loading ? "Guardando..." : "Guardar"}
          left
          onClick={() => saveForm()}
          disabled={loading}
          helperText={updated ? "Información actualizada" : null}
          error={error}
        />
      </section>
    </FormStyled>
  )
}

const FormStyled = styled.section`
  .row-title {
    font-weight: 200;
  }

  .input-row {
    display: flex;
    margin: 0 0 20px 0;
    flex-wrap: wrap;
  }

  .input {
    margin-right: 10px;
    margin-top: 10px;
  }

  .numero {
    width: 100px;
  }

  .country {
    width: 200px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 968px) {
    .numero {
      width: 80px;
    }
  }
`

export default TaxesForm
