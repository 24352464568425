import React from "react";
import { colors } from "../../components/layout/constants";

const MenuIcon = (props) => {
  const { color, className } = props;

  return (
    <svg className={className} viewBox="0 0 36 36">
      <path
        d="M8 18C8 17.69 8 17.535 8.03407 17.4078C8.12654 17.0628 8.39609 16.7932 8.74118 16.7007C8.86835 16.6667 9.02334 16.6667 9.33333 16.6667H24.6667C24.9767 16.6667 25.1317 16.6667 25.2588 16.7007C25.6039 16.7932 25.8735 17.0628 25.9659 17.4078C26 17.535 26 17.69 26 18C26 18.31 26 18.465 25.9659 18.5922C25.8735 18.9372 25.6039 19.2068 25.2588 19.2993C25.1317 19.3333 24.9767 19.3333 24.6667 19.3333H9.33333C9.02334 19.3333 8.86835 19.3333 8.74118 19.2993C8.39609 19.2068 8.12654 18.9372 8.03407 18.5922C8 18.465 8 18.31 8 18ZM8 11.3333C8 11.0233 8 10.8683 8.03407 10.7412C8.12654 10.3961 8.39609 10.1265 8.74118 10.0341C8.86835 10 9.02334 10 9.33333 10H30.6667C30.9767 10 31.1317 10 31.2588 10.0341C31.6039 10.1265 31.8735 10.3961 31.9659 10.7412C32 10.8683 32 11.0233 32 11.3333C32 11.6433 32 11.7983 31.9659 11.9255C31.8735 12.2706 31.6039 12.5401 31.2588 12.6326C31.1317 12.6667 30.9767 12.6667 30.6667 12.6667H9.33333C9.02334 12.6667 8.86835 12.6667 8.74118 12.6326C8.39609 12.5401 8.12654 12.2706 8.03407 11.9255C8 11.7983 8 11.6433 8 11.3333ZM8 24.6667C8 24.9767 8 25.1317 8.03407 25.2588C8.12654 25.6039 8.39609 25.8735 8.74118 25.9659C8.86835 26 9.02334 26 9.33333 26H17.5192C17.8292 26 17.9842 26 18.1113 25.9659C18.4564 25.8735 18.726 25.6039 18.8184 25.2588C18.8525 25.1317 18.8525 24.9767 18.8525 24.6667C18.8525 24.3567 18.8525 24.2017 18.8184 24.0745C18.726 23.7294 18.4564 23.4599 18.1113 23.3674C17.9842 23.3333 17.8292 23.3333 17.5192 23.3333H9.33333C9.02334 23.3333 8.86835 23.3333 8.74118 23.3674C8.39609 23.4599 8.12654 23.7294 8.03407 24.0745C8 24.2017 8 24.3567 8 24.6667Z"
        fill={color ? color : colors.black}
      />
    </svg>
  );
};

export default MenuIcon;
