import React from "react"
// layout
import DefaultLayout from "../layout"
import Wrapper from "../layout/wrapper"

const Terms = () => {
  return (
    <DefaultLayout lightLogo dashboard>
      <Wrapper>
        <section>
          <p>
            Los Términos y Condiciones que se presentan a continuación, están
            dirigidos única y exclusivamente para los miembros de la comunidad
            Urvita, que son personas plenamente identificadas por Urvita y con
            las cuales mantiene una relación contractual. Por lo anterior, se
            hace constar que los presentes Términos y Condiciones no están
            dirigidos al público en general.
          </p>
          <h1>TÉRMINOS Y CONDICIONES PARA USUARIOS</h1>
          <p>
            <span className="bold">URVITA, S.A.P.I. DE C.V.</span> (en adelante{" "}
            <span className="bold">Urvita</span>) es una Sociedad Anónima
            Promotora de Inversión de capital variable, constituida de
            conformidad con las leyes mexicanas; como consta en la Escritura
            Pública número 3,161 de fecha 02 de marzo del año 2015, pasada bajo
            la fe del Lic. Norberto Jesús de la Rosa Buenrostro, Notario Público
            No. 61 de la Ciudad de Monterrey, Nuevo León, inscrita en el
            Registro Público de la Propiedad y del Comercio de la Ciudad de
            Monterrey, Nuevo León, bajo el número folio mercantil electrónico
            151020 con fecha 04 de marzo de 2015.
          </p>
          <p>
            A continuación se presentan los Términos y Condiciones que regularán
            la navegación y uso del Sitio Web{" "}
            <a href="www.urvitaeverywhere.com">www.urvitaeverywhere.com</a> y
            sus sitios derivados, propiedad de{" "}
            <span className="bold">URVITA S.A.P.I. DE C.V.</span>
          </p>
        </section>
        <section>
          <h2>DEFINICIONES</h2>
          <p>
            A continuación se definen los conceptos aplicables a estos Términos
            y Condiciones y que podrán usarse en singular o plural, en masculino
            o femenino, en mayúsculas o minúsculas y/o en letra negrita, o no:
          </p>
          <p>
            <span className="bold">Token:</span> Es equivalente a una acción del
            capital social de la SAPI (como se define más adelante) y es la
            unidad mínima de inversión que se puede hacer dentro de la misma ,
            representa aproximadamente una fracción de 1m2 (un metro cuadrado
            rentable) indeterminado del Edificio que corresponda. Cada Token
            otorga al Socio, sobre dicha fracción, el derecho sobre la Ganancia
            resultante de la explotación que Urvita o una de sus empresas
            filiales o subsidiarias efectúe del Edificio al que pertenezca dicha
            fracción.
          </p>
          <p>
            <span className="bold">Solicitud de Venta de tokens:</span>{" "}
            Significa la solicitud de venta de tokens de una SAPI que un Socio
            puede realizar a través de la Plataforma. La mecánica y condiciones
            para efectuarlo se explican más adelante en este documento.
          </p>
          <p>
            <span className="bold">CFDI:</span> Comprobante Fiscal Digital por
            Internet. Es un archivo XML que cumple con la especificación
            proporcionada por el Servicio de Administración Tributaria.
          </p>
          <p>
            <span className="bold">Cuenta:</span> Se refiere a la cuenta virtual
            creada de manera personal por el Usuario en la Plataforma para
            reservar o adquirir Tokens.
          </p>
          <p>
            <span className="bold">Datos Personales:</span> Aquella información
            que los Usuarios o Socios proporcionen a Urvita y que se relaciona
            con su persona, que los identifica o los hace identificable, tal
            como nombre, domicilio, clave de RFC, CURP, entre otros.
          </p>
          <p>
            <span className="bold">Edificio:</span> Se refiere al bien inmueble
            que ha sido adquirido en propiedad por la SAPI de acuerdo a cada
            proyecto.
          </p>
          <p>
            <span className="bold">Ficha Técnica:</span> Documento creado por
            Urvita en el que se detalla la descripción y características del
            Edificio y/o la SAPI, para proporcionar información al Usuario sobre
            los mismos.{" "}
          </p>
          <p>
            <span className="bold">Ganancia:</span> Se denomina así a los
            beneficios económicos que pagará Urvita o una de sus empresas
            filiales o subsidiarias a favor del Socio resultantes del Usufructo.
          </p>
          <p>
            <span className="bold">Número de Orden:</span> Clave numérica que se
            genera cuando el Usuario reserva los Tokens en la Plataforma previo
            a realizar el pago de los mismos. Esta clave identifica la
            transacción exacta a realizarse y es ligada directamente al Usuario.
          </p>
          <p>
            <span className="bold">Pago:</span> Cantidad de dinero depositada
            por el Socio mediante medios electrónicos seguros y protegidos que
            estarán destinados a la compra de Tokens en la Plataforma.
          </p>
          <p>
            <span className="bold">Plataforma:</span> Sitio web
            www.urvitaeverywhere.com, propiedad de Urvita.
          </p>
          <p>
            <span className="bold">SAPI:</span> Se refiere a las Sociedades
            Anónimas Promotoras de Inversión constituidas como soporte de la
            participación inmobiliaria de los Socios.
          </p>
          <p>
            <span className="bold">Servicios:</span> Significa la compra, venta
            o cesión de Tokens.
          </p>
          <p>
            <span className="bold">Socio:</span> Persona física o moral que
            adquiera uno o varios Tokens a través de la Plataforma.
          </p>
          <p>
            <span className="bold">Usuario:</span> Persona física o moral que
            cuente con un registro en la Plataforma, le dé uso y/o solicite
            cualquier Servicio a través de la misma.
          </p>
          <p>
            <span className="bold">Usufructo:</span> Significa el derecho real y
            temporal que el titular de cada Token le otorga a Urvita o a una de
            sus empresas filiales o subsidiarias, para que, en ejercicio del
            mismo, dar en arrendamiento el Edificio a terceros o a el Socio
            mismo si así lo desea, con la finalidad de que se obtenga la
            Ganancia.
          </p>
        </section>
        <section>
          <h2>SERVICIOS DE LA PLATAFORMA</h2>
          <p>
            El Usuario podrá solicitar la compra, venta, y/o cesión de Tokens
            mediante el uso de la Plataforma, una vez que se registre en la
            misma. El Usuario y/o el Socio reconocen que al solicitar cualquier
            Servicio dentro de la Plataforma conocen y aceptan los presentes
            Términos y Condiciones y la Ficha Técnica aplicable.
          </p>
        </section>
        <section>
          <h2>REGISTRO DE USUARIOS</h2>
          <p>
            Para poder acceder a los Servicios, el Usuario tendrá que
            registrarse y crear una Cuenta en la Plataforma. Para realizar su
            registro y crear una cuenta, el Usuario deberá aprobar de manera
            personal y privada, el proceso de selección de posibles Socios de
            Urvita. Una vez aprobado este proceso, se le indicará al usuario el
            procedimiento para llevar a cabo la creación de su cuenta. Tal
            proceso podría incluir que el Usuario deba introducir su correo
            electrónico y crear una contraseña. También podrá registrarse a
            través del inicio de sesión en su cuenta personal de cualquier red
            social autorizada por Urvita. Su cuenta será el medio de
            identificación y autenticación al hacer uso de la Plataforma. El
            Usuario deberá completar su registro, llenando los formularios de
            identificación con sus Datos Personales e información complementaria
            que el personal de Urvita le solicite.
          </p>
          <p>
            Urvita realizará la identificación plena de los Usuarios a través
            del llenado y firma del formulario de datos del Usuario, posterior a
            esto, Urvita realizará la validación de la información y si cumple
            con los parámetros establecidos para tal efecto, se procederá a la
            creación de la Cuenta.
          </p>
          <p>
            El Usuario se obliga a proporcionar información verídica. A los
            Datos Personales que proporcione a Urvita, se les dará el
            tratamiento y la protección establecidos en el Aviso de Privacidad
            que se encuentra expuesto en la Plataforma y que el Usuario acepta
            de conformidad al ingresar a la Plataforma.
          </p>
          <p>
            Urvita podrá solicitar el llenado de formularios, comprobantes, y/o
            datos adicionales a efecto de corroborar los Datos Personales. El
            Socio reconoce que la información proporcionada es verídica y que el
            dinero que usará para adquirir los Tokens no ha sido obtenido de
            actividades ilícitas ni ha sido el resultado de la inversión en
            actividades prohibidas o derivadas del narcotráfico, lavado de
            dinero, financiación del terrorismo, actos de corrupción o cualquier
            otro delito contemplado por las leyes mexicanas. De conocer lo
            contrario, Urvita dará aviso inmediato a las autoridades competentes
            y pondrá a disposición de estas últimas cualquier cantidad pagada
            por el Socio y con fundamento en los acuerdos celebrados, terminará
            la relación jurídica existente entre Urvita y el Socio. Urvita podrá
            suspender temporal o definitivamente al Usuario cuyos datos no hayan
            podido ser confirmados o que los mismos hayan resultado falsos. En
            dicho supuesto, el Usuario no podrá hacer uso de la Plataforma y se
            le podrá dar de baja de los posibles Servicios que haya solicitado.
          </p>
          <p>
            La Plataforma deberá ser utilizada de manera personal, privada y
            particular por el Usuario; por lo que la Cuenta no podrá de manera
            alguna ser transferida, cedida, copiada, modificada, publicada,
            reproducida o utilizada con fines comerciales.
          </p>
        </section>
        <section>
          <h2>RECUPERACIÓN DE CONTRASEÑA</h2>
          <p>
            Urvita, siguiendo las mejores prácticas de seguridad e identidad, no
            guarda ni conoce las contraseñas de los Usuarios; por lo que ninguno
            de sus empleados, funcionarios, accionistas o directores tiene
            acceso a dicha información. Para recuperar el acceso a su cuenta, el
            Usuario deberá ingresar al apartado dentro de la Plataforma
            “Recuperar Contraseña” y escribir el correo electrónico con el cual
            realizó su registro, si este coincide con el registrado en la base
            de datos de Urvita, se le enviará por correo electrónico una liga
            mediante la cual podrá crear una nueva contraseña.
          </p>
        </section>
        <section>
          <h2>PAGOS Y RETIROS </h2>
          <p>
            El Usuario podrá solicitar la compra de Tokens utilizando la
            Plataforma y, una vez autorizado por Urvita y, siempre que en la
            SAPI en la que el Usuario deseé participar no existan más de 99
            (noventa y nueve) Socios, podrá realizar el pago de los mismos
            siguiendo las instrucciones que para tal efecto le proporcione
            Urvita. Una vez que Urvita reciba el pago correctamente, notificará
            al Usuario que ahora es Socio y se le enviará una notificación de
            pago recibido por correo electrónico y una liga al contrato o
            contratos correspondientes.
          </p>
          <p>
            En la Plataforma, el Socio tendrá la posibilidad de llevar a cabo
            los pagos a favor de Urvita mediante tarjeta de crédito y/o tarjeta
            de débito. La información de pago para el cobro a los clientes es
            procesada por terceros legalmente autorizados para prestar dichos
            servicios, es decir, Urvita no conserva en sus bases de datos
            información relacionada con números de tarjeta de crédito, fechas de
            vencimiento o códigos de seguridad de las mismas. En este sentido,
            Urvita informa que no se hace responsable por la pérdida de datos
            que pudieran ocurrir en las plataformas de pago de terceros; por lo
            que, en caso de que dicho supuesto ocurriera, el Socio deberá
            dirigirse directamente con la plataforma de pagos utilizada, cuyos
            datos le serán proporcionados por Urvita, previa solicitud del
            cliente.
          </p>
          <p>
            De la misma manera, de conformidad con la instrucción del cliente,
            Urvita proporcionará a este último la posibilidad de realizar pagos
            en una sola exhibición o bien, mediante cargos recurrentes de forma
            mensual. Los pagos recurrentes se realizarán en estricto
            cumplimiento a los montos que el Socio indique y los mismos podrán
            suspenderse o cancelarse previa instrucción del Socio a Urvita en
            ese sentido.
          </p>
          <p>
            Desde la Plataforma, el Socio podrá instruir a Urvita que desea
            retirar mensualmente la Ganancia de sus Tokens a la cuenta bancaria
            que registre para tal efecto, misma que, por seguridad, deberá estar
            a su nombre. Urvita solicitará el CFDI correspondiente para el pago
            de la Ganancia los primeros 15 días naturales de cada mes y
            realizará la transferencia bancaria a más tardar 5 días hábiles
            posteriores a la recepción del CFDI.
          </p>
          <p>
            El Socio reconoce que el dinero que usará para pagar los Tokens no
            ha sido obtenido de actividades ilícitas ni ha sido el resultado de
            la inversión en actividades prohibidas o derivadas del narcotráfico,
            lavado de dinero, financiación del terrorismo, actos de corrupción o
            cualquier otro delito contemplado por las leyes mexicanas. De
            conocer lo contrario, Urvita dará aviso inmediato a las autoridades
            competentes y pondrá a disposición de estas últimas cualquier
            cantidad pagada por el Socio y con fundamento en los acuerdos
            celebrados, terminará la relación jurídica existente entre Urvita y
            el Socio.
          </p>
        </section>
        <section>
          <h2>VENTA DE TOKENS</h2>
          <p>
            El Socio puede solicitar a Urvita su deseo de vender sus Tokens a
            través de la Plataforma a partir de que el edificio inicie
            operaciones, si las compró en preventa y, en cualquier momento, si
            las compró con el edificio operando. Posterior a esto, Urvita
            publicará la disponibilidad de esos Tokens en la Plataforma para que
            un tercero pueda adquirirlos. El Socio podrá realizar las
            operaciones señaladas en este párrafo sujetándose a las regulaciones
            sobre este tema estipuladas en los estatutos sociales de cada SAPI,
            en los documentos que suscriba y en las leyes aplicables.
          </p>
          <p>
            Los Tokens se venderán conforme al orden en que se hayan hecho las
            solicitudes por los Socios. Normalmente el tiempo y el costo de
            venta de un Token es menor al de venta de un inmueble tradicional,
            sin embargo, Urvita no puede garantizar un tiempo determinado, pues
            varía dependiendo de la demanda en cada momento.
          </p>
        </section>
        <section>
          <h2>COMPRAVENTA DE TOKENS</h2>
          <p>
            El Usuario y/o el Socio acepta que posterior al pago de la compra,
            venta y/o cesión de los Tokens firmará con su firma digital o firma
            autógrafa el contrato correspondiente y/o formatos solicitados, y
            Urvita emitirá el certificado que ampare el pago realizado.
          </p>
          <p>
            <span className="bold">
              El Usuario y/o el Socio reconocen que Urvita no podrá garantizar
              la disponibilidad de Tokens en todo momento. Los presentes
              Términos y Condiciones y los contratos que celebre el Socio con
              Urvita, no tienen la finalidad de transmitir la propiedad del
              Edificio o de ningún bien raíz al Socio; sino que únicamente darán
              derecho a participar como socio en una SAPI que a su vez es
              propietaria del Edificio que le corresponda. En este sentido, los
              Tokens otorgan al Socio el derecho a otorgar el Usufructo de la
              fracción del Edificio que el Token representa y a percibir la
              Ganancia.
            </span>
          </p>
          <p>
            <span className="bold">
              Urvita no realiza la venta al público de viviendas destinadas a
              casa habitación, ni tampoco otorga el derecho de usar inmuebles
              mediante el sistema de tiempo compartido.
            </span>
          </p>
          <p>
            <span className="bold">
              Urvita no es una entidad financiera ni proporciona servicios
              financieros, de banca ni servicios análogos a sus usuarios
              registrados. El contenido de esta página web no debe ser
              considerado de ninguna forma como recomendaciones, invitaciones o
              solicitudes de comprar o vender activos financieros o de
              participar en actividades de trading, estrategias de inversión o
              cualquier otra.
            </span>
          </p>
        </section>
        <section>
          <h2>ANTICIPOS PARA NUEVOS TOKENS</h2>
          <p>
            Desde la plataforma, el Socio podrá instruir a Urvita a usar la
            Ganancia generada de sus Tokens como anticipos para la compra de
            nuevos Tokens y, una vez que con la Ganancia adquirida el Socio
            complete el monto de un nuevo Token, la compraventa y operación del
            mismo se sujetará a las reglas establecidas en los estatutos de la
            SAPI, el contrato que firme el Socio con Urvita y/o en cualquier
            otro documento aplicable.
          </p>
        </section>
        <section>
          <h2>IMPUESTOS</h2>
          <p>
            Los Socios serán responsables del cumplimiento de las obligaciones
            fiscales que les correspondan derivadas de la compra, venta y/o
            cesión de los Tokens en los términos legales aplicables.{" "}
          </p>
          <p>
            <span className="bold">
              Urvita le sugiere que antes de cualquier movimiento traslativo de
              dominio de su Token, se asesore debidamente con su especialista en
              materia fiscal.
            </span>
          </p>
          <p>
            El comprobante fiscal que emita el Socio deberá contar con las
            retenciones de impuestos establecidas en la legislación aplicable.
          </p>
          <p>
            En el dashboard de la Plataforma, el Socio podrá conocer la cantidad
            de Tokens con los que cuenta, su valor comercial, la Ganancia
            generada del mes y un resumen de cada uno de los movimientos que
            haya realizado (compra, venta, pagos, retiros).
          </p>
        </section>
        <section>
          <h2>USO DE FIRMA ELECTRÓNICA</h2>
          <p>
            Urvita le informa y usted reconoce que la contratación y utilización
            de los Servicios a través de la Plataforma, se llevará a cabo con el
            uso de una firma digital en conexión por un proveedor autorizado y
            certificado de firma digital, señalado por Urvita.{" "}
          </p>
          <p>
            En este sentido, el Socio reconoce que el uso de la firma digital
            con el proveedor señalado por Urvita, produce los mismos efectos
            jurídicos que la firma autógrafa. Para la autentificación y uso de
            la firma digital, la Plataforma se conectará con el proveedo de
            servicios de firma digital señalado por Urvita.
          </p>
          <p>
            Entre otras, algunas de las características de la firma digital a
            utilizar en la Plataforma son las siguientes: 1) Orden de firmantes:
            se establece un orden de prioridad para la firma de documentos; 2)
            Tecnología de cifrado en una blockchain privada, que garantiza la
            inalterabilidad y la confidencialidad de los documentos; 3)
            Trazabilidad: permite conocer el estatus exacto del proceso de firma
            digital en tiempo real.
          </p>
          <p>
            Para el uso de la firma digital, el Socio reconoce que deberá seguir
            las instrucciones que el proveedor de firma digital señalado por
            Urvita le indique para completar la misma, entre los que se pueden
            encontrar, entre otros: a) realizar un garabato digital que
            represente su firma autógrafa a través de su dispositivo móvil; b)
            capturar una fotografía de su identificación oficial vinculada al
            documento a firmar y; c) capturar una fotografía de su propio
            rostro, con el objeto de confirmar su identidad y su consentimiento
            en tiempo real.
          </p>
          <p>
            Todas las operaciones que realice el Socio con su firma digital en
            la Plataforma o en el sitio del proveedor autorizado que Urvita
            designe tendrán plenos efectos legales. El Socio confirma que el uso
            de su firma digital en la plataforma garantiza: (i) que el Socio es
            quien dice ser; (ii) que la autenticación del contenido permite dar
            certeza que ha sido emitido por el Socio de manera tal que su
            contenido le es atribuible; (iii) la integridad del contenido; es
            decir, la firma digital da plena seguridad que el mensaje de datos
            del Socio ha permanecido completo e inalterado desde su firma, así
            como para su ulterior consulta y que no ha sido manipulado al tener
            que coincidir el resumen cifrado con el mensaje; (iv) que no existe
            repudio entre las partes en origen y destino. La firma digital
            garantiza además, que el Socio no pueda negar haber enviado el
            mensaje de datos y que le es atribuible al igual que las
            consecuencias jurídicas que de ello deriven.{" "}
          </p>
        </section>
        <section>
          <h2>CANCELACIÓN DE CFDI</h2>
          <p>
            A través de los presentes Términos y Condiciones, el Socio se obliga
            a no cancelar ningún CFDI que haya otorgado a favor de Urvita, sus
            filiales o a cualquier persona que Urvita instruya. En caso de que
            el Socio no cumpla con su obligación y realice la cancelación de
            dicho CFDI será acreedor a una pena convencional equivalente al
            50%(cincuenta por ciento) del valor total del CFDI cancelado. El
            pago de la pena convencional será cobrado por Urvita con cargo a la
            Ganancia del Socio.
          </p>
        </section>
        <section>
          <h2>DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL</h2>
          <p>
            Urvita, sus logotipos y todo el material que aparece en la
            Plataforma son marcas, nombres de dominio y nombres comerciales
            propiedad de Urvita protegidos por tratados internacionales, leyes
            federales y locales en materia de propiedad intelectual y derechos
            de autor. Los derechos de autor y propiedad industrial sobre el
            contenido, organización, recopilación, información, logotipos,
            fotografías, imágenes, videos, programas, aplicaciones, o en general
            cualquier información contenida o publicada en la Plataforma se
            encuentran debidamente protegidos de conformidad con la legislación
            aplicable en materia de propiedad intelectual.
          </p>
          <p>
            Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya
            sea en forma total o parcial, los avisos, marcas, nombres
            comerciales, señas, anuncios, logotipos o en general cualquier
            indicación que se refiere a la propiedad de información contenida en
            la Plataforma. El Usuario y/o el Socio se obligan a no modificar,
            reproducir, copiar, rediseñar, descompilar, adaptar, traducir,
            preparar trabajos derivados de la Plataforma o utilizarla para
            desarrollar cualquier software u otros materiales basados en los
            mismos.
          </p>
        </section>
        <section>
          <h2>CONFIDENCIALIDAD</h2>
          <p>
            En razón de tratarse de una plataforma privada, queda estrictamente
            prohibido al Usuario y/o al Socio divulgar cualquier información que
            tenga el carácter de confidencial. Se entiende por información
            confidencial toda la información contenida dentro de la Plataforma,
            como lo es, sin limitar: la información referente a inmuebles,
            inquilinos, valor renta de contratos, actores de las compraventas,
            estatus de pago y mora de inquilino, así como cualquier otra
            información a la que el Usuario tenga acceso dentro de la
            Plataforma.
          </p>
          <p>
            En ningún caso el Socio podrá utilizar la información confidencial a
            la que tendrá acceso a través de la Plataforma para su beneficio
            personal, ni para beneficio de terceras personas. El Socio en ningún
            caso utilizará la información confidencial, si esta implica un
            conflicto de intereses.
          </p>
          <p>
            En caso de que exista un incumplimiento del Socio, Urvita, sus
            filiales y cualquier persona afectada podrá reclamar al Socio por la
            vía civil o penal el cumplimiento de esta cláusula más el pago de
            daños y perjuicios.
          </p>
        </section>
        <section>
          <h2>MODIFICACIONES A LA PLATAFORMA</h2>
          <p>
            Urvita podrá en cualquier momento y cuando lo considere conveniente,
            sin necesidad de notificar al Usuario, realizar correcciones,
            adiciones, mejoras o modificaciones al contenido, presentación,
            información, servicios, áreas, bases de datos y demás elementos de
            la Plataforma, sin que ello dé derecho a ninguna o indemnización, y
            sin que esto implique el reconocimiento de responsabilidad alguna a
            favor del Usuario.
          </p>
        </section>
        <section>
          <h2>MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES</h2>
          <p>
            Urvita se reserva el derecho de modificar los presentes Términos y
            Condiciones en cualquier momento, siendo efectivas dichas
            modificaciones de forma inmediata por medio de la publicación en la
            Plataforma de los Términos y Condiciones modificados. De esta forma,
            el Usuario está de acuerdo en revisar los Términos y Condiciones
            periódicamente con la finalidad de mantenerse al tanto de dichas
            modificaciones. Cada vez que el Usuario acceda a la Plataforma se
            considerará como una aceptación absoluta a las modificaciones de los
            Términos y Condiciones vigentes en ese momento.
          </p>
        </section>
        <section>
          <h2>TÉRMINOS ADICIONALES</h2>
          <p>
            Ocasionalmente, Urvita podrá agregar a los Términos y Condiciones
            provisiones relativas a áreas específicas o nuevos servicios que se
            proporcionen en o a través de la Plataforma, los cuales serán
            publicados para su lectura y aceptación. En el evento en que alguna
            disposición de los Términos y Condiciones llegare a ser
            contradictoria con alguna una disposición establecida en otro
            contrato o documento relacionado con los servicios prestados por
            Urvita, prevalecerán los términos establecidos en dicho contrato o
            documento.
          </p>
        </section>
        <section>
          <h2>RENUNCIA DE GARANTÍA</h2>
          <p>
            Urvita realiza sus mejores esfuerzos para proporcionar una
            Plataforma segura, sin embargo, no garantiza que la misma funcionará
            libre de errores y/o que su servidor estará en todo momento libre de
            virus informáticos u otros mecanismos dañinos. Si por el uso de la
            Plataforma, el Usuario tiene que acudir al servicio técnico o
            reponer el equipo o datos, Urvita no será responsable de estos
            gastos. Urvita recomienda a los Usuarios mantener copias de
            seguridad de su contenido. En la medida en que lo permita la ley, en
            ningún caso Urvita será responsable por el borrado, pérdida o
            modificación no autorizadas de cualquier contenido de los Usuarios.
          </p>
        </section>
        <section>
          <h2>LÍMITE DE RESPONSABILIDAD</h2>
          <p>
            Urvita, sus empresas subsidiarias o filiales, empleados, directivos,
            representantes legales, apoderados, accionistas o miembros del
            consejo de administración, no serán responsables en ningún caso,
            dentro de los límites legales aplicables, de los daños personales,
            directos o indirectos incluidos a título enunciativo y no
            limitativo, por pérdida de datos, por la imposibilidad de transmitir
            o recibir instrucciones a través de la Plataforma durante algún
            acontecimiento de caso fortuito o fuerza mayor, o bien de cualquier
            otro tipo de acontecimiento que cause daños o pérdidas comerciales
            resultantes o relacionadas con el uso o el mal uso de la Plataforma,
            incluso si se le informó a Urvita de la posibilidad de tales daños.
          </p>
        </section>
        <section>
          <h2>LEY APLICABLE Y JURISDICCIÓN</h2>
          <p>
            Los presentes Términos y Condiciones se regirán e interpretarán de
            conformidad con las leyes de los Estados Unidos Mexicanos,
            independientemente de las disposiciones relacionadas con conflictos
            de leyes. Cualquier procedimiento de carácter legal que se derive o
            se relacione con estos Términos y Condiciones será dirimido en los
            tribunales competentes de Monterrey, Nuevo León. El Usuario y/o el
            Socio expresamente renuncian a cualquier otra jurisdicción que
            pudiera corresponderles debido a su domicilio presente o futuro o
            por cualquier otra razón.
          </p>
        </section>
        <section>
          <h2>AVISO LEGAL</h2>
          <p>
            Las compras de partes sociales de una sociedad son operaciones que
            intrínsecamente representan cierto riesgo. Antes de realizar
            cualquier operación similar, es importante considerar la naturaleza,
            la complejidad y el riesgo de la misma. No realice erogaciones o
            compromisos económicos mayores a lo que puede gastar en relación a
            su patrimonio y sus ingresos. Es importante no realizar operaciones
            con Urvita si no conoce o no entiende el riesgo y el alcance de las
            mismas. Urvita no puede regular los precios definidos por el
            mercado. Sugerimos encarecidamente buscar asesoramiento de su propio
            asesor financiero, de inversiones, fiscal y legal. Urvita siempre
            actuará de buena fe y no será responsable del uso de la Plataforma
            por parte de los Usuarios.
          </p>
          <p>
            <span className="bold">
              Urvita no es una entidad financiera ni proporciona servicios
              financieros, de banca ni servicios análogos a sus usuarios
              registrados. El contenido de esta página web no debe ser
              considerado de ninguna forma como recomendaciones, invitaciones o
              solicitudes de comprar o vender activos financieros o de
              participar en actividades de trading, estrategias de inversión o
              cualquier otra.
            </span>
          </p>
          <p>
            Urvita advierte a todas las personas que desean adquirir tokens que
            existen muchos métodos que los ciberdelincuentes usan para tratar de
            obtener datos personales para cometer varios fraudes. Los más
            importantes son:
          </p>
          <ul>
            <li>
              Phishing: en Internet, "phishing" se refiere a actividades
              delictivas que intentan obtener información confidencial de manera
              fraudulenta. Hay varias formas en que un estafador puede tratar de
              obtener información confidencial. A veces, un defraudador primero
              le enviará un correo electrónico benigno (piense en esto como
              cebo) para atraerlo a una conversación y luego seguir con un
              correo electrónico de phishing. En otras ocasiones, el estafador
              solo enviará un correo electrónico de suplantación de identidad
              (phishing) que lo dirigirá a un sitio web en el que se le pedirá
              que ingrese su información personal, como su nombre de usuario y
              contraseña.
            </li>
            <li>
              Pharming: es otra estafa en la que un defraudador instala un
              código malicioso en una computadora personal o servidor. Este
              código luego redirige los clics que hace en un sitio web a otro
              sitio web fraudulento sin su consentimiento o conocimiento.
            </li>
            <li>
              Vishing: los correos electrónicos de phishing no son la única
              forma en que las personas pueden intentar engañarte para que
              proporciones información personal en un esfuerzo por robar tu
              identidad o cometer fraude. Los estafadores también usan el
              teléfono para solicitar su información personal.
            </li>
            <li>
              Smishing: al igual que el phishing, smishing utiliza mensajes de
              texto en el teléfono celular para atraer a los consumidores. A
              menudo, el texto contendrá una URL o número de teléfono. El número
              de teléfono a menudo tiene un sistema automático de respuesta de
              voz. Y de nuevo, al igual que el phishing, el mensaje de smishing
              normalmente solicita su atención inmediata.
            </li>
          </ul>
          <p>
            A menos que la ley exija lo contrario, en ningún caso los
            propietarios o contribuyentes de la Plataforma serán responsables de
            los daños de ningún tipo, incluidos, entre otros, la pérdida de uso,
            la pérdida de beneficios o la pérdida de datos que surjan. o de
            cualquier forma relacionada con el uso de la Plataforma.
          </p>
        </section>
      </Wrapper>
    </DefaultLayout>
  )
}

export default Terms
