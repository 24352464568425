import React from "react"
import styled from "styled-components"
// components
import HistoricTable from "./historicTable"
// layout
import { colors } from "../../layout/constants"

const MovementsStyled = styled.section`
  margin-top: 2em;
  .grid {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;
  }

  .breadcumber {
    color: ${colors.darkText};

    a {
      color: ${colors.darkText};
    }
  }
`

const MovementsView = ({ userReport, handleModalClick }) => {
  return (
    <MovementsStyled>
      <h2>Movimientos</h2>
      <p>Aquí podrás consultar todos tus movimientos</p>

      <div className="grid">
        <HistoricTable
          transactions={userReport.transactions.rows}
          handleModalClick={handleModalClick}
        />
      </div>
    </MovementsStyled>
  )
}

export default MovementsView
