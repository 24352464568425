async function sendNewPassword(email) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/reset_password`,
    {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ email }),
    }
  );

  const resp = await response.json();
  return resp;
}

async function changePassword(userToken, userId, newPassword) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const password = {
    password: newPassword,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
    {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(password),
    }
  );

  const resp = await response.json();
  return resp;
}

async function sendVerificationMail(email) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/send_verification_mail`,
    {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ email }),
    }
  );

  const resp = await response.json();
  return resp;
}

export { sendNewPassword, changePassword, sendVerificationMail };
