export async function createContract(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/contract`,
    {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ userId })
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

export async function getDocuments(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/documents/user/${userId}`,
    {
      method: "GET",
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

export async function getWeetrustDocuments(userToken, documentId) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/documents/${documentId}`,
    {
      method: "GET",
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const { document } = await response.json()
  return document.responseData
}
