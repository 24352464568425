import React from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
// layout
import { colors } from "../../layout/constants"
import Tooltip from "../../layout/shared/tooltip"
import DetailButton from "../../layout/shared/detailButton"

const ReinvestStatus = ({ balance, tokenPrice }) => {
  const tokenPercentage = () => {
    const percentage = (balance / tokenPrice) * 100
    return Math.trunc(percentage)
  }

  return (
    <ReinvestStyled tokenPercentage={tokenPercentage()}>
      <div className="text title">
        Balance disponible para tu siguente m<sup>2</sup>:{" "}
        <NumberFormat
          value={Math.trunc(balance)}
          prefix="$ "
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        <Tooltip
          dark
          content={
            "Es la suma de tus abonos recurrente, abonos unicos y ganancias disponibles para comprar tu siguiente token."
          }
        />
      </div>

      <div className="bar">
        <span className="filled-bar"></span>
      </div>

      <p className="text">
        {(balance / tokenPrice).toFixed(1)} m<sup>2</sup>
      </p>

      <DetailButton text="Vender m2" />
    </ReinvestStyled>
  )
}

const ReinvestStyled = styled.article`
  .bar {
    background-color: ${colors.gridGray};
    height: 8px;
    margin: 1em 0;
    position: relative;
    width: 100%;
  }

  .filled-bar {
    background-color: ${colors.secondaryColor};
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: ${(props) =>
      props.tokenPercentage >= "100" ? "100%" : props.tokenPercentage + "%"};
  }

  .text {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }

  .title {
    font-size: 1.2rem;
  }

  .helper-number {
    color: ${colors.helperGray};
  }

  @media (max-width: 968px) {
  }
`

export default ReinvestStatus
