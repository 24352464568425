import React, { useState, useEffect } from "react"
import Layout from "../layout"
import styled from "styled-components"
import MetricCard from "../home/MetricCard"
import CopyToClipboard from "react-copy-to-clipboard"
import { useSelector } from "react-redux"
import { getAffiliate, getCommissions } from "../../services/rewardful"
import { getTransactionsByUser } from "../../services/transactions"
import moment from "moment"
import currency from "currency.js"

export default function Ambassador() {
  const user = useSelector((state) => state.user)
  const [isCopied, setIsCopied] = useState(false)
  const [isAmbassador, setIsAmbassador] = useState(false)
  const [affiliate, setAffiliate] = useState({})
  const [commissions, setCommissions] = useState({})
  const [transactions, setTransactions] = useState([])
  const [counter, setCounter] = useState(0)

  const renderPageButtons = () => {
    const pageButtons = []
    for (let page = 1; page <= commissions?.pagination?.total_pages; page++) {
      pageButtons.push(
        <button
          key={page}
          // onClick={() => loadPage(page)}
          className={
            page === commissions?.pagination?.current_page
              ? "current"
              : undefined
          }
        >
          {page}
        </button>
      )
    }
    return pageButtons
  }

  const totalCommission = transactions.reduce(
    (acc, currentValue) => acc + currentValue.net_amount,
    0
  )

  useEffect(() => {
    if (!isAmbassador) {
      const updateCounter = () => {
        setCounter((prevCounter) => (prevCounter + 1) % 3)
        setTimeout(updateCounter, 5000)
      }
      updateCounter()
    }
  }, [])

  useEffect(() => {
    const retrieveAffiliate = async () => {
      const { campaign, links } = await getAffiliate(user.token, user.id)
      setIsAmbassador(campaign.name === "Campaña Embajadores " ? true : false)
      setAffiliate(links[0])
    }

    const retrieveCommissions = async () => {
      const response = await getCommissions(user.token, user.id)
      if (response.error) {
        return setCommissions({
          data: []
        })
      }
      setCommissions(response)
    }

    const retrieveTransactions = async () => {
      const { data: response } = await getTransactionsByUser(
        user.token,
        user.report.userRfc
      )
      const filtered = response.filter(
        (transaction) =>
          transaction.type === "CXP_REINVERTIR_POR_COMISION" &&
          transaction.owner !== null
      )
      setTransactions(filtered)
    }

    retrieveCommissions()
    retrieveAffiliate()
    retrieveTransactions()
  }, [user])

  return (
    <Layout dashboard lightLogo>
      <Main>
        <FirstSection>
          <h2>
            {isAmbassador
              ? "Embajador Urvita"
              : "¡Invita a tus amigos y gana una comisión!"}
          </h2>
          <div className="container-image">
            <img src="/hero-image-ambassador.webp" alt="hero" />
            <p>
              Por cada referido se te abonará el{" "}
              {isAmbassador
                ? "2% de sus compras durante 1 año."
                : "1% de su primera compra."}
            </p>
          </div>
          <div className="container">
            <p className={`link ${isCopied ? "link-copied" : undefined}`}>
              {affiliate?.url}
            </p>
            <CopyToClipboard text={affiliate?.url}>
              <button
                className={isCopied ? "copied" : undefined}
                onClick={() => setIsCopied(true)}
              >
                {isCopied ? "Copiado 📃!" : "Compartir mi link"}
              </button>
            </CopyToClipboard>
          </div>
        </FirstSection>
        <SecondSection>
          <div className="container">
            <h2>Referidos</h2>
            {Object.keys(commissions).length === 0 && (
              <div className="loader"></div>
            )}
            {transactions.length === 0 && (
              <p className="no-referrals">No tienes referidos aún.</p>
            )}
            {transactions.length > 0 && (
              <>
                <section className="grid-metrics">
                  <MetricCard
                    icon="/referrals.png"
                    number={affiliate?.visitors}
                    description="Clicks a tu link."
                    small={true}
                  />
                  <MetricCard
                    icon="/referrals-earned.png"
                    number={affiliate?.conversions}
                    description="Referidos ganados."
                    small={true}
                  />
                  <MetricCard
                    icon="/commissions-paid.png"
                    number={transactions?.length}
                    description="Comisiones pagadas."
                    small={true}
                  />
                  <MetricCard
                    icon="/total-commissions.png"
                    number={`${currency(totalCommission, {
                      precision: 2
                    }).format()}`}
                    description="Comisiones totales."
                    small={true}
                  />
                </section>
                <section className="container-list">
                  <ul>
                    <li>Referido</li>
                    <li>Campaña</li>
                    <li>Fecha</li>
                    <li>Comisión</li>
                    <li>Status</li>
                  </ul>
                  {transactions.map((item, i) => (
                    <div key={i} className="info">
                      <p>*******</p>
                      <p>Referidos</p>
                      <p>
                        {moment(item.transaction_date).format("DD-MM-YYYY")}
                      </p>
                      <p>
                        MXN{" "}
                        {currency(item.net_amount, { precision: 2 }).format()}
                      </p>
                      <p
                        className={`status  ${
                          item.status !== null ? "paid" : "unpaid"
                        }`}
                      >
                        {item.status !== null ? "Pagada" : "Por pagar"}
                      </p>
                    </div>
                  ))}
                </section>
                <section className="container-pagination">
                  <button
                    disabled={commissions?.pagination?.previous_page === null}
                  >
                    Back
                  </button>
                  <div>{renderPageButtons()}</div>
                  <button
                    disabled={commissions?.pagination?.next_page === null}
                  >
                    Next
                  </button>
                </section>
              </>
            )}
          </div>
        </SecondSection>
        <ThirdSection className={isAmbassador ? undefined : "isAmbassador"}>
          {isAmbassador ? (
            <>
              <div className="container-text ambassador">
                <h2>Embajador Urvita</h2>
                <p>
                  Urvita es una comunidad de dedicada al buen vivir, buscamos
                  conectar personas y espacios que mejoren la experiencia de
                  vida en la ciudad. Como embajador serás parte activa de esta
                  comunidad y podrás experimentar de primera mano el propósito
                  de Urvita.
                </p>
              </div>
              <div className="container-buttons">
                {/* <button> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://urvita.notion.site/Manual-de-Embajadores-ffbf66f43b7e488689a3ad152deffb2a"
                >
                  Manual de embajador
                </a>
                {/* </button> */}
                <button>Contactar asesor</button>
                <button>Calendario de eventos</button>
              </div>
            </>
          ) : (
            <>
              <div className="slider">
                <img className="image" src="/slidePNG.png" alt="slide" />

                <div className="container-info">
                  {counter === 0 && (
                    <>
                      <h3>Comisiones</h3>
                      <p>Comisión del 2% en primera compra.</p>
                    </>
                  )}

                  {counter === 1 && (
                    <>
                      <h3>Descuentos</h3>
                      <p>Descuentos en hospedajes Urvita.</p>
                    </>
                  )}

                  {counter === 2 && (
                    <>
                      <h3>Eventos gratis</h3>
                      <p>Acceso gratis a eventos Urvita.</p>
                    </>
                  )}
                </div>
              </div>

              <div className="container-text">
                <h2>Conviértete en embajador Urvita</h2>
                <p>
                  Urvita es una comunidad de dedicada al buen vivir, buscamos
                  conectar personas y espacios que mejoren la experiencia de
                  vida en la ciudad. Como embajador serás parte activa de esta
                  comunidad y podrás experimentar de primera mano el propósito
                  de Urvita
                </p>
                <button>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://my.demio.com/ref/sclMZDwsmtLwDCjm"
                  >
                    Registrarme
                  </a>
                </button>
              </div>
            </>
          )}
        </ThirdSection>
      </Main>
    </Layout>
  )
}

const Main = styled.main`
  box-sizing: border-box;
  background-color: #fff8f3;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    font-family: "calibre";
    font-size: 1.7rem;
    font-weight: 400;
  }

  button {
    cursor: pointer;
    border: none;
    font-family: "calibre";
    border-radius: 5px;
  }
`
const FirstSection = styled.section`
  box-sizing: border-box;
  display: flex;
  padding: 30px 10px 0px;
  flex-direction: column;
  gap: 1rem;

  .container-image {
    position: relative;
    height: 150px;

    img {
      border-radius: 5px;
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      /* filter: brightness(60%); */
    }
    p {
      position: absolute;
      z-index: 20;
      bottom: 0;
      background-color: #fffc;
      border-radius: 5px;
      color: #000;
      padding: 5px 10px;
      margin-top: 0;
      bottom: -5px;
      font-size: 1.3rem;
      width: 70%;
      display: inline-block;
    }
  }

  .container {
    display: flex;
    gap: 1rem;

    p {
      width: 60%;
      margin: 0;
      padding: 5px 0px;
      border: 1px solid #f79f4d;
      color: #f79f4d;
      text-align: center;
      border-radius: 5px;
      font-size: 1.1rem;

      &.link-copied {
        border-color: #a4c9a3;
        color: #a4c9a3;
      }
    }

    button {
      color: white;
      background-color: #f79f4d;
      flex: 1 0 0;
      font-size: 1.1rem;

      &.copied {
        background-color: #a4c9a3;
      }
    }
  }

  @media (min-width: 768px) {
    width: 600px;
    margin: 0 auto;

    .container-image {
      p {
        left: 5%;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 800px;
    margin: 0 auto;
    .container-image {
      height: 200px;
      p {
        width: 50%;
        left: 2%;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
`
const SecondSection = styled.section`
  box-sizing: border-box;
  background-color: white;
  padding: 2rem 10px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 20vh;

    .loader {
      width: 50px;
      align-self: center;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 8px solid;
      border-color: #435b49 #0000;
      animation: l1 1s infinite;
    }
    @keyframes l1 {
      to {
        transform: rotate(0.5turn);
      }
    }
  }

  .no-referrals {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 0;
  }

  .grid-metrics {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 1rem;
  }

  .container-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ul {
      /* display: flex; */
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      list-style: none;
      padding: 0;
      margin: 0;
      justify-content: space-between;

      li {
        font-size: 1.1rem;
        font-weight: 400;
        text-align: center;
      }

      li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4) {
        text-align: left;
      }
    }

    .info {
      /* display: flex; */
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        text-align: left;
      }

      .status {
        text-align: center;
        padding: 3px 0px;
        border-radius: 5px;
        width: 100%;
      }

      .paid {
        background-color: #a4c9a3;
        color: white;
      }

      .unpaid {
        background-color: #f79f4d;
        color: white;
      }
    }
  }

  .container-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: transparent;
      border: 1px solid #6f8775;
      text-transform: uppercase;
      padding: 5px 10px;
      font-size: 1rem;
      color: #6f8775;

      &:disabled {
        border-color: #ccc;
        color: #ccc;
        cursor: not-allowed;
      }

      &.current {
        background-color: #6f8775;
        color: white;
      }
    }

    div {
      display: flex;
      gap: 10px;
    }
  }

  @media (min-width: 768px) {
    .container {
      width: 600px;
      margin: 0 auto;
    }

    .container-pagination {
      margin-top: 1rem;
    }
  }

  @media (min-width: 1024px) {
    .container {
      width: 800px;
      margin: 0 auto;
      gap: 2rem;
    }
    .grid-metrics {
      display: grid;
      gap: 1rem;
      grid-template-columns: 150px 150px 225px 225px;
      /* grid-template-rows: 1fr 1fr; */
    }
  }
`
const ThirdSection = styled.section`
  padding: 0px 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 40vh;

  .container-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button,
    a {
      padding: 10px;
      background-color: transparent;
      border: 1px solid #435b49;
      color: #435b49;
      text-transform: uppercase;
      font-size: 1rem;
    }

    a {
      border-radius: 5px;
      text-align: center;
      background-color: #435b49;
      color: white;
    }
  }

  .slider {
    /* text-align: center; */
    box-shadow: 4px 2px 6px 0px #0000001f;
    border-radius: 5px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: end;
    /* max-width: 400px;
    margin: 0 auto; */

    .image {
      position: absolute;
      height: 180px;
      /* width: 100px; */
      object-fit: cover;
      /* background-color: #ccc; */
      left: -15%;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    h3 {
      font-family: "calibre";
      margin: 0;
      font-size: 1.6rem;
    }

    p {
      margin: 0;
      margin-top: 7px;
      font-size: 1.2rem;
    }

    .container-info {
      width: 230px;
    }
  }

  .container-text {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    &.ambassador {
      margin: 0;
      text-align: left;
    }
    p {
      margin: 0;
      font-size: 1.1rem;
    }

    button {
      width: 200px;
      margin: 0 auto;
      padding: 10px;
      text-transform: uppercase;
      background: #435b49;
      color: white;
      font-size: 1.1rem;
      margin-top: 20px;

      a {
        color: white;
      }
    }
  }

  @media (min-width: 768px) {
    width: 600px;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .slider {
      width: 400px;
      .image {
        height: 300px;
        /* width: 150px; */
        left: -25%;
      }

      .container-info {
        width: 262px;
      }
    }

    &.isAmbassador {
      flex-direction: column;
    }

    .container-text {
      width: 65%;
    }

    .container-buttons {
      flex: 1;
    }
  }

  @media (min-width: 1024px) {
    /* .container { */
    width: 800px;
    margin: 0 auto;
    /* } */

    .slider {
      width: 450px;
      .image {
        /* height: 150px; */
        /* width: 150px; */
        left: -20%;
      }

      .container-info {
        width: 300px;
      }
    }
  }
`
