import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { Switch, Route, Redirect, Link, useLocation } from "react-router-dom"
// components
import ProfileForm from "./porfileForm"
import DepositForm from "./depositForm"
import TaxesForm from "./taxesForm"
import SecurityForm from "./securityForm"
import Documents from "./documents"
// layout
import { colors } from "../../layout/constants"
import BreadCrumb from "../../layout/shared/breadCrumb"
// services
import { getUser } from "../../../services/users"

const ProfileView = () => {
  let location = useLocation()
  // Redux hooks
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  const userId = useSelector((state) => state.user.id)
  const userStep = useSelector((state) => state.user.step)
  // state
  const [userInfo, setUserInfo] = useState()

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userData = await getUser(userToken, userId)
        setUserInfo(userData)
        updateStep(userData)
      } catch (error) {
        console.error(error)
      }
    }

    const updateStep = async (userInfo) => {
      dispatch({
        type: "SET_USER_STEP",
        payload: userInfo.trail_step
      })
    }

    getUserInfo()
  }, [dispatch, userId, userToken])

  return (
    <ProfileStyled>
      <BreadCrumb section="Dashboard" active="Configuración" />
      <div className="grid">
        <aside>
          <h2 className="section-title">Configuración</h2>
          <ul className="settings-nav">
            <Link to="/tablero/perfil/personal">
              <li
                className={
                  location.pathname === "/tablero/perfil/personal"
                    ? "active"
                    : null
                }
              >
                Información personal
              </li>
            </Link>
            {/* <Link to="/tablero/perfil/pago">
              <li
                className={
                  location.pathname === "/tablero/perfil/pago" ? "active" : null
                }
              >
                Método de pago
              </li>
            </Link> */}
            <Link to="/tablero/perfil/fiscales">
              <li
                className={
                  location.pathname === "/tablero/perfil/fiscales"
                    ? "active"
                    : null
                }
              >
                Datos fiscales
              </li>
            </Link>

            <Link to="/tablero/perfil/retiro">
              <li
                className={
                  location.pathname === "/tablero/perfil/retiro"
                    ? "active"
                    : null
                }
              >
                Cuenta para retiros
              </li>
            </Link>

            <Link to="/tablero/perfil/documentos">
              <li
                className={
                  location.pathname === "/tablero/perfil/documentos"
                    ? "active"
                    : null
                }
              >
                Documentos
              </li>
            </Link>

            <Link to="/tablero/perfil/seguridad">
              <li
                className={
                  location.pathname === "/tablero/perfil/seguridad"
                    ? "active"
                    : null
                }
              >
                Seguridad
              </li>
            </Link>
          </ul>
        </aside>

        {userInfo ? (
          <Switch>
            <Route exact path="/tablero/perfil">
              <Redirect to="/tablero/perfil/personal" />
            </Route>
            <Route exact path="/tablero/perfil/personal">
              <ProfileForm userInfo={userInfo} userStep={userStep} />
            </Route>
            <Route exact path="/tablero/perfil/retiro">
              <DepositForm userInfo={userInfo} userStep={userStep} />
            </Route>
            <Route path="/tablero/perfil/fiscales">
              <TaxesForm userInfo={userInfo} userStep={userStep} />
            </Route>
            <Route path="/tablero/perfil/documentos">
              <Documents userInfo={userInfo} />
            </Route>
            <Route path="/tablero/perfil/seguridad">
              <SecurityForm userInfo={userInfo} userStep={userStep} />
            </Route>
          </Switch>
        ) : null}

        <div></div>
      </div>
    </ProfileStyled>
  )
}

const ProfileStyled = styled.section`
  padding: 2em 0;
  min-height: 70vh;

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 1.25fr 0.25fr;
    grid-column-gap: 4em;
  }

  .section-title {
    font-size: 2em;
    font-weight: 200;
    margin-top: 0;
  }

  .settings-nav {
    list-style: none;
    padding: 0;

    a {
      color: ${colors.darkText};
    }

    li {
      cursor: pointer;
      padding: 1em 0;

      &.active {
        color: ${colors.highlightColor};
      }

      :hover {
        color: ${colors.secondaryColor};
      }
    }
  }

  @media (max-width: 968px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`

export default ProfileView
