import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
// layout
import { colors } from "../../layout/constants";
import ActionButton from "../../layout/shared/actionButton";
// services
import { editUser } from "../../../services/users";

const SecurityForm = ({ userInfo }) => {
  // Redux hooks
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.token);
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  // inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const validateForm = () => {
    let isValid = true;
    if (password === "") {
      setPasswordError(true);
      setPasswordErrorMessage("No puede quedar vacio");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Las contraseñas no coinciden");
      isValid = false;
    }
    return isValid;
  };

  const saveForm = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const newData = {
      password,
    };

    setLoading(true);
    try {
      const editedUser = await editUser(userToken, userInfo.id, newData);
      setLoading(false);
      if (editedUser.error) {
        setError(true);
        return;
      }
      await updateStep();
      setUpdated(true);
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  const updateStep = async () => {
    dispatch({
      type: "SET_USER_STEP",
      payload: userInfo.trail_step == 1 ? 2 : userInfo.trail_step,
    });
  };

  return (
    <FormStyled>
      <h2 className="section-title">Seguridad</h2>
      <section>
        <h3 className="row-title">Actualizar contraseña</h3>
        <div className="input-row">
          <TextField
            className="input"
            label="Contraseña"
            type="password"
            variant="outlined"
            error={passwordError}
            helperText={passwordErrorMessage}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <TextField
            className="input"
            label="Confirmar contraseña"
            type="password"
            variant="outlined"
            error={passwordError}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </div>

        <div className="rules">
          <span className="title">Tu contraseña no puede tener:</span>
          <span>- Tu nombre o fecha de nacimiento</span>
          <span>- La frase Urvita o conceptos relacionados</span>
          <span>- Más de 3 caracteres identicos de forma consecutiva</span>
          <span>
            - Más de 3 caracteres numericos y/o alfabeticos de forma secuencial
          </span>
          <span></span>
          <span></span>
        </div>

        <ActionButton
          text={loading ? "Guardando..." : "Guardar"}
          left
          onClick={() => saveForm()}
          disabled={loading}
          helperText={updated ? "Información actualizada" : null}
          error={error}
        />
      </section>
    </FormStyled>
  );
};

const FormStyled = styled.section`
  .row-title {
    font-weight: 200;
  }

  .input-row {
    display: flex;
    margin: 0 0 20px 0;
  }

  .input {
    margin-right: 10px;
  }

  .numero {
    width: 100px;
  }

  .country {
    width: 150px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  .rules {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    & > .title {
      font-size: 18px;
      padding: 15px 0;
    }
  }
`;

export default SecurityForm;
