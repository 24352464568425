import React from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
// Material UI
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
// layout
import { colors } from "../layout/constants"
import ActionButton from "../layout/shared/actionButton"
// services
import { editUser } from "../../services/users"
import { setUserData } from "../../state/actions"

const TaxForm = ({ setStep }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const userData = useSelector((state) => state.userData)

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm()
  const watchIdType = watch("id_type", false)

  const onSubmit = async (data) => {
    try {
      const response = await editUser(user.token, user.id, data)
      if (response?.originalError?.name === "SequelizeUniqueConstraintError") {
        alert("El RFC que ingresaste ya está en uso")
        return
      }

      setUserData(dispatch, data)
      setStep(3)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormWrapper>
      {userData?.tax_regime?.includes("FISICA") && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Datos de identificación</h3>

          <div className="input-row">
            <TextField
              fullWidth
              select
              label="Tipo de ID"
              variant="outlined"
              error={errors.id_type ? true : false}
              helperText={
                errors.id_type?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("id_type", { required: true }) }}
              defaultValue={userData?.id_type ? userData?.id_type : ""}
            >
              <MenuItem value="INE">INE</MenuItem>
              <MenuItem value="PASSPORT">Pasaporte</MenuItem>
            </TextField>
          </div>

          <div className="input-row">
            <TextField
              fullWidth
              label={`Número de ${
                !watchIdType
                  ? "ID"
                  : watchIdType === "PASSPORT"
                  ? "pasaporte"
                  : "INE"
              }`}
              variant="outlined"
              error={errors.id_number ? true : false}
              helperText={
                errors.id_number?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("id_number", { required: true }) }}
              defaultValue={userData?.id_number ? userData?.id_number : ""}
            />
          </div>

          <div className="input-row">
            <TextField
              fullWidth
              label="RFC"
              variant="outlined"
              error={errors.rfc ? true : false}
              helperText={
                errors.rfc?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("rfc", { required: true }) }}
              defaultValue={userData?.rfc ? userData?.rfc : ""}
            />
          </div>
          <ActionButton text="Siguiente" type="submit" />
        </form>
      )}

      {userData?.tax_regime?.includes("MORAL") && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Información del representante legal</h3>
          <div className="input-row">
            <TextField
              fullWidth
              label="Nombre(s)"
              variant="outlined"
              error={errors.name ? true : false}
              helperText={
                errors.name?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("name", { required: true }) }}
              defaultValue={userData?.name ? userData?.name : ""}
            />
            <TextField
              fullWidth
              label="Apellidos"
              variant="outlined"
              error={errors.last_name ? true : false}
              helperText={
                errors.last_name?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("last_name", { required: true }) }}
              defaultValue={userData?.last_name ? userData?.last_name : ""}
            />
          </div>

          <div className="input-row">
            <TextField
              fullWidth
              select
              label="Tipo de ID"
              variant="outlined"
              error={errors.id_type ? true : false}
              helperText={
                errors.id_type?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("id_type", { required: true }) }}
              defaultValue={userData?.id_type ? userData?.id_type : ""}
            >
              <MenuItem value="INE">INE</MenuItem>
              <MenuItem value="PASSPORT">Pasaporte</MenuItem>
            </TextField>
          </div>

          {/* <div className='input-row'>
            <TextField
              fullWidth
              label={`Número de ${
                !watchIdType
                  ? "ID"
                  : watchIdType === "PASSPORT"
                  ? "pasaporte"
                  : "INE"
              }`}
              variant='outlined'
              error={errors.id_number ? true : false}
              helperText={
                errors.id_number?.type === "required" && "El campo es requerido"
              }
              inputProps={{ ...register("id_number", { required: true }) }}
              defaultValue={userData?.id_number ? userData?.id_number : ""}
            />
          </div> */}

          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            type="tel"
            error={errors.phone ? true : false}
            helperText={
              errors.phone?.type === "required" && "El campo es requerido"
            }
            inputProps={{ ...register("phone", { required: true }) }}
            defaultValue={userData?.phone}
          />
          <ActionButton text="Siguiente" type="submit" />
        </form>
      )}
    </FormWrapper>
  )
}

const FormWrapper = styled.section`
  .input-row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlightColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.highlightColor};
  }

  @media (max-width: 600px) {
    .input-row {
      flex-direction: column;
    }
  }
`

export default TaxForm
