import React from "react"
import styled from "styled-components"
import Map from "./maps"
// assets
import LocationIcon from "../../../assets/iconography/Location.svg"
import MapIcon from "../../../assets/iconography/Map.svg"
import HomeIcon from "../../../assets/iconography/Home.svg"
import ForwardArrow from "../../../assets/iconography/Forward-arrow.svg"
import ActionButton from "../../layout/shared/actionButton"
import { buildingInfo } from "../../../utils/format"

const BuildingDescription = ({ building }) => {
  return (
    <>
      <Header>
        <h2>Sobre el edificio</h2>
        <li>
          <a href={buildingInfo(building.name)} target="_black">
            Ficha de edificio
          </a>
        </li>
      </Header>
      <DescriptionContainer>
        <p>{building.description}</p>
        <DescriptionList>
          <li>
            <img className="icon" src={MapIcon} alt="profile icon" />
            <span>{building.rent_meters}</span>
          </li>
          <li>
            <img className="icon" src={HomeIcon} alt="profile icon" />
            <span>{building.units}</span>
          </li>
          <li>
            <img className="icon" src={ForwardArrow} alt="profile icon" />
            <span>{building.floors}</span>
          </li>
          <li>
            <img className="icon" src={ForwardArrow} alt="profile icon" />
            <span>{building.awards}</span>
          </li>
        </DescriptionList>

        {building.name !== "HERNAN" && (
          <MapContainer>
            <Map building={building} />
          </MapContainer>
        )}

        <div>
          <h2>Sobre el vecindario</h2>
          <p>{building.neighborhood_description}</p>
        </div>

        <DescriptionList>
          <li>
            <img className="icon" src={LocationIcon} alt="profile icon" />
            <span>{building.neighborhood}</span>
          </li>
          <li>
            <img className="icon" src={LocationIcon} alt="profile icon" />
            <span>{building.address_indications}</span>
          </li>
        </DescriptionList>
      </DescriptionContainer>
    </>
  )
}

const Header = styled.header`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 2rem;
  align-items: center;
  /* grid-row-gap: 1rem; */
  /* padding: 1rem 0; */

  li {
    list-style: none;
  }

  a {
    background-color: #fbcfa4;
    color: #000;
    padding: 5px 15px;
    border-radius: 5px;
  }
`

const DescriptionContainer = styled.section`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  padding: 1rem 0;

  .mapa {
    background-color: black;
    height: 200px;
    width: 100%;
  }
`

const DescriptionList = styled.ul`
  padding: 0;
  list-style: none;
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1em;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  a {
    background-color: #fbcfa4;
    color: #000;
    padding: 5px 15px;
    border-radius: 5px;
  }

  .icon {
    width: 15px;
  }
`

const MapContainer = styled.div`
  grid-column: 1/3;
  position: relative;
  height: 250px;
  width: 100%;
`

export default BuildingDescription
