import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
// assets
import GainsIcon from "../../../assets/icons/gainsIcon"
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import EditIcon from "@mui/icons-material/Edit"
// layout
import Wrapper from "../../layout/wrapper"
import { colors, dateFormat } from "../../layout/constants"
import DetailButton from "../../layout/shared/detailButton"
import Tooltip from "../../layout/shared/tooltip"
// services
import { getUserSubscription } from "../../../services/subscriptions"
import PauseSuscriptionModal from "./pauseSuscrptionModal"
import { listPaymentMethods } from "../../../services/stripe"

const ReportBar = ({ userReport, handleModalClick }) => {
  const userToken = useSelector((state) => state.user.token)
  const userData = useSelector((state) => state.userData)
  const [subscription, setSubscription] = useState()
  const [paymentMethods, setPaymentMethods] = useState()
  const [openModal, setOpenModal] = useState(false)

  const onHandleModal = () => setOpenModal(!openModal)

  useEffect(() => {
    const getSubs = async () => {
      const { data: userSub } = await getUserSubscription(
        userToken,
        userData.id
      )
      const paymentData = await listPaymentMethods(userToken, userData.id)

      if (userSub.error) {
        return
      }

      setSubscription(userSub)
      setPaymentMethods(paymentData)
    }

    if (userData?.id) {
      getSubs()
    }
  }, [userData, userToken])

  const totalTokens = () => {
    const tokenFraction =
      userReport.userBalance / userReport.lastBuyBuilding.tokenValue
    return Math.floor((userReport.totalTokens + tokenFraction) * 10) / 10
  }

  // Total Rents of all buildigns with and without tax calculation.
  const totalRent = () => {
    let netRent = 0
    let decimals = 0

    if (userReport.buildings) {
      const tokenRentList = userReport.transactions.rows.filter(
        (transaction) => {
          if (
            transaction.type === "CXP_REINVERTIR" ||
            transaction.type === "CXP_RECIBIR"
          ) {
            return true
          } else {
            return false
          }
        }
      )

      const balanceRentList = userReport.transactions.rows.filter(
        (transaction) => {
          if (
            transaction.type === "CXP_REINVERTIR_DE_BALANCE" ||
            transaction.type === "CXP_RECIBIR_DE_BALANCE"
          ) {
            return true
          } else {
            return false
          }
        }
      )
      const tokenRent =
        tokenRentList.length > 0
          ? tokenRentList[tokenRentList.length - 1]
          : { subtotal: 0, net_amount: 0 }
      const balanceRent =
        balanceRentList.length > 0
          ? balanceRentList[balanceRentList.length - 1]
          : { subtotal: 0, net_amount: 0 }

      netRent = Number(tokenRent.net_amount) + Number(balanceRent.net_amount)
      decimals = (netRent % 1) * 100
    }

    return {
      net: netRent,
      cents: decimals
    }
  }

  // Sum of all buildings tokens value. (Total user capital)
  const totalTokensValue = (buildings) => {
    let totalValue = 0
    if (buildings) {
      buildings.forEach((building) => {
        totalValue += building.userTokensValue
      })
    }
    // TODO remove user balance and sum token decimals

    return Number(totalValue) + Number(userReport.userBalance)
  }

  const getDate = () => {
    if (moment().format("dd") > subscription.subscription_charge_date) {
      return dateFormat(moment().add(1, "M"), "month")
    }
    return dateFormat(moment(), "month")
  }

  return (
    <BarStyled>
      <Wrapper>
        <div className="grid">
          <article>
            <h3 className="title">
              <span>
                Ganancias{" "}
                <Tooltip content="Ganancias generadas el último mes, Elige retirar o reinvertir tus ganancias de los siguientes meses." />
              </span>
              <span className="highlight">
                {dateFormat(userReport.reportPeriod, "month")}
              </span>
            </h3>
            {userReport.userType === "investor" && totalRent().net === 0 ? (
              <p className="helper-message">
                A partir del próximo mes podrás ver tus ganancias en este
                espacio
              </p>
            ) : (
              <section className="">
                <NumberFormat
                  className="priority-number"
                  value={Math.trunc(totalRent().net)}
                  prefix="$ "
                  displayType={"text"}
                  thousandSeparator={true}
                />
                <div className="container-button">
                  <DetailButton text="Retirar ganancias" />
                </div>
              </section>
            )}
          </article>

          <article>
            <h3 className="title">
              <span>
                Valor de tu patrimonio{" "}
                <Tooltip content="Es el valor total que han alcanzado tus m2 en este momento. Este valor irá creciendo siempre gracias a la plusvalía de los inmuebles." />
              </span>
              <span className="highlight">
                <NumberFormat
                  value={totalTokens()}
                  suffix=" m2"
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </span>
            </h3>
            <NumberFormat
              className="priority-number"
              value={Math.trunc(totalTokensValue(userReport.buildings))}
              prefix="$ "
              displayType={"text"}
              thousandSeparator={true}
            />
            <div className="margin-top">
              <Link to="/selecciona-fracciones">
                <DetailButton text="Comprar más m2" />
              </Link>
              <Link to="/resumen?initial=1000&monthly=0&usd=false">
                <DetailButton text="Abono único" />
              </Link>
            </div>
          </article>

          <article className="flex-section">
            <h3 className="title">
              <span>
                Compra mensual{" "}
                <Tooltip content="Podrás registrar una tarjeta de crédito o programar un SPEI recurrente para abonar mensualmente en automático el monto que tu quieras. Este monto lo puede modificar o suspender en el momento que quieras." />
              </span>
            </h3>

            {subscription ? (
              <NumberFormat
                className={`priority-number`}
                value={subscription.subscription_amount}
                prefix="$ "
                displayType={"text"}
                thousandSeparator={true}
              />
            ) : (
              <p className="helper-message">
                Registra una tarjeta de débito o crédito para invertir
                mensualmente
              </p>
            )}

            {subscription ? (
              <article className="card-container">
                <GainsIcon className="menu-icon" color="white" />
                <p>
                  **** **** ****{" "}
                  {subscription.stripe_customer_id
                    ? paymentMethods.card.last4
                    : subscription.subscription_card_last_numbers}
                </p>
                {/* <span onClick={() => onHandleModal()}> */}
                <span>
                  {subscription.subscription_active ? (
                    <PauseCircleOutlineIcon />
                  ) : (
                    <PlayCircleOutlineIcon />
                  )}
                </span>
                <Link to="/configurar-suscripcion">
                  <EditIcon />
                </Link>
                {/* <Tooltip
                  content={
                    "Si quieres pausar, modificar el monto de compra mensual o cambiar de tarjeta da click aquí"
                  }
                /> */}
              </article>
            ) : (
              <div>
                <Link to="/simulador?subscription=true">
                  <DetailButton text="Activar suscripción" />
                </Link>
              </div>
            )}

            {subscription && (
              <>
                <div>
                  {subscription.subscription_active ? (
                    <p className="helper-message">
                      El próximo cargo a tu tarjeta será el día{" "}
                      {subscription.subscription_charge_date} de {getDate()}
                    </p>
                  ) : (
                    <p className="helper-message">
                      Tu suscripción está pausada. Actívala para seguir
                      aportando a tu patrimonio de manera automática.
                    </p>
                  )}
                </div>
              </>
            )}

            <div></div>
          </article>
        </div>
      </Wrapper>
      <PauseSuscriptionModal
        handleModal={onHandleModal}
        openModal={openModal}
      />
    </BarStyled>
  )
}

const BarStyled = styled.section`
  background-color: ${colors.primaryDark};
  width: 100%;

  .flex-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: flex-start;

    p {
      margin: 0;
    }
  }

  .margin-top {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .menu-icon {
    width: 40px;
  }

  .container-button {
    margin-top: 10px;
  }

  .card-container {
    border: 2px solid ${colors.white};
    padding: 0.2rem 1rem;
    border-radius: 25px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    gap: 1rem;

    p {
      margin: 0;
    }

    a p {
      color: ${colors.secondaryColor};
    }
  }

  .tail-label {
    color: ${colors.secondaryColor};
    font-weight: 200;
    margin: 0;
    padding: 1em 0 0 0;
  }

  .grid {
    display: grid;
    grid-column-gap: 4em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    padding: 2em 0;
  }

  .button-container {
    display: flex;
  }

  .title {
    color: ${colors.white};
    display: flex;
    font-size: 1em;
    font-weight: 400;
    margin: 0 0 0.5em 0;
    justify-content: space-between;

    .highlight {
      color: ${colors.secondaryColor};
      font-size: 1em;
      font-weight: 300;
    }
  }

  .number-input {
    background-color: transparent;
    border: none;
    color: ${colors.secondaryColor};
    font-size: 1em;
    font-weight: 200;
    letter-spacing: 1px;
    /* text-decoration: underline; */

    :focus {
      outline: 0;
    }
  }

  input.number-input {
    border-bottom: 1px solid ${colors.secondaryColor};
    max-width: 175px;

    :focus {
      border-bottom: 1px solid ${colors.highlightColor};
    }
  }

  .proyection-controler {
    background-color: transparent;
    border: none;
    color: ${colors.highlightColor};
    cursor: pointer;
    font-size: 1.2em;
    vertical-align: middle;

    :focus {
      outline: 0;
    }
  }

  .priority-number {
    color: ${colors.secondaryColor};
    font-size: 2.5em;
    font-weight: 300;
    letter-spacing: 2px;

    &.inactive {
      color: #ccc;
      text-decoration: line-through dashed #ccc;
    }
  }

  .helper-message {
    color: ${colors.secondaryColor};
  }

  .bar-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  @media (max-width: 968px) {
    .grid {
      grid-template-columns: 1fr;
      grid-gap: 2em;
    }

    .priority-number {
      font-size: 3em;
    }
  }
`

export default ReportBar
