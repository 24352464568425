import React, { useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
// assets
import icon from "../../assets/icons/candado.svg"
// layout
import Modal from "../layout/shared/modal"
import ActionButton from "../layout/shared/actionButton"
// components
import StepDisplay from "./stepDisplay"
import PersonalForm from "./personalStep"
import TaxForm from "./taxStep"
import AddressForm from "./addressStep"
import Summary from "./summaryStep"

const Kyc = ({ setModal }) => {
  const userData = useSelector((state) => state.userData)
  const [step, setStep] = useState(0)

  const showStepForm = () => {
    switch (step) {
      case 1:
        return <PersonalForm setStep={setStep} />
      case 2:
        return <TaxForm setStep={setStep} />
      case 3:
        return <AddressForm setStep={setStep} />
      case 4:
        return <Summary setModal={setModal} />
      default:
        return (
          <KycStyled>
            <img src={icon} alt=""></img>
            <h2>Se requiere verificación de identidad</h2>
            <p>
              Deberás verificar tu identidad y firmar tu contrato para comprar
              tu primer token y poder gestionar tu ganancias. Estos datos nos
              serviran para verificar tu identidad y aumentar la seguridad de tu
              cuenta.
            </p>
            <ActionButton text="Comenzar" onClick={() => setStep(1)} />
          </KycStyled>
        )
    }
  }

  return (
    <Modal height="60" setModal={setModal} width="500">
      <>
        {!userData.contract_status && (
          <StepDisplay step={step} totalSteps={4} setStep={setStep} />
        )}
        {showStepForm()}
      </>
    </Modal>
  )
}

const KycStyled = styled.section`
  img {
    display: block;
    margin: 0 auto;
  }
`

export default Kyc
