import React, { useState } from "react";
import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";

// components
import PersonForm from "./personForm";
import BusinessForm from "./businessForm";
// layout
import { colors } from "../layout/constants";

const PersonalInputs = ({ setStep }) => {
  const [taxRegime, setTaxRegime] = useState("");

  return (
    <section>
      {!taxRegime && (
        <TabContainer>
          <RegimenRadio onClick={() => setTaxRegime("P_FISICA")}>
            <PersonIcon />
            <p>Soy persona Física</p>
          </RegimenRadio>
          <RegimenRadio onClick={() => setTaxRegime("P_MORAL")}>
            <BusinessIcon />
            <p>Soy persona Moral (empresa)</p>
          </RegimenRadio>
        </TabContainer>
      )}

      {taxRegime === "P_FISICA" && <PersonForm setStep={setStep} />}

      {taxRegime === "P_MORAL" && <BusinessForm setStep={setStep} />}
    </section>
  );
};

const TabContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

const RegimenRadio = styled.div`
  align-items: center;
  box-shadow: 1px 2px 6px 2px lightgrey;
  color: ${colors.darkText};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 1.2rem;
  height: 250px;
  padding: 0.5rem 1rem;
  transition: all 0.4s;
  text-align: center;

  &:hover {
    box-shadow: 1px 2px 2px 4px lightgrey;
    background-color: ${colors.secondaryLight};
  }
`;

export default PersonalInputs;
