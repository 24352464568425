import React from "react"
// layout
import DefaultLayout from "../layout"
import Wrapper from "../layout/wrapper"

const Privacy = () => {
  return (
    <DefaultLayout lightLogo dashboard>
      <Wrapper>
        <section>
          <br />
          <br />
          <br />
          <h1>AVISO DE PRIVACIDAD.</h1>
          <p>
            <span className="bold">
              URVITA SAPI DE CV con domicilio en MIGUEL HIDALGO 122, COLONIA
              LOMAS DE TAMPIQUITO, SAN PEDRO GARZA GARCIA, NUEVO LEÓN, MÉXICO
              66240,
            </span>{" "}
            es el responsable del uso y protección de sus datos personales, y al
            respecto le informamos lo siguiente:
          </p>
        </section>
        <section>
          <h2>¿Para qué fines utilizaremos sus datos personales?</h2>
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para
            las siguientes finalidades que son necesarias para el servicio que
            solicita:
          </p>
          <ul>
            <li>Información y Prestación de Servicios.</li>
            <li>Actualización de la Base de Datos.</li>
            <li>
              Cualquier finalidad análoga o compatible con las anteriores.
            </li>
          </ul>
          <p>
            Le informamos que sus datos personales no serán compartidos dentro y
            fuera del país con persona, empresas, organizaciones y autoridades
            distintas a nosotros, para los siguientes fines:
          </p>
          <i>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que para
            ciertos fines, la revocación de su consentimiento implicará que no
            le podamos seguir prestando el servicio que nos solicitó, o la
            conclusión de su relación con nosotros.
          </i>
          <i>
            Para revocar su consentimiento deberá presentar su solicitud por
            correo electrónico a info@urvita.mx.
          </i>
          <i>
            Para conocer el procedimiento y requisitos para la revocación del
            consentimiento, usted podrá llamar al siguiente número telefónico
            +52 814 160 5441.
          </i>
          <p>
            La negativa para el uso de sus datos personales para estas
            finalidades no podrá ser un motivo para que le neguemos los
            servicios y productos que solicita o contrata con nosotros.
          </p>
        </section>
        <section>
          <h2>¿Qué datos personales utilizaremos para estos fines?</h2>
          <p>
            Para llevar a cabo las finalidades descritas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales:
          </p>
          <ul>
            <li>Nombre(s)</li>
            <li>Apellidos</li>
            <li>Fecha de Nacimiento</li>
            <li>Estado Civil</li>
            <li>Domicilio</li>
            <li>CURP</li>
            <li>RFC</li>
            <li>Email de contacto</li>
            <li>Teléfono de contacto</li>
          </ul>
        </section>
        <section>
          <h2>
            ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
            oponerse a su uso?
          </h2>
          <p>
            Usted tiene derecho a conocer qué datos personales tenemos de usted,
            para qué los utilizamos y las condiciones del uso que les damos
            (Acceso). Asimismo, es su derecho solicitar la corrección de su
            información personal en caso de que esté desactualizada, sea
            inexacta o incompleta (Rectificación); que la eliminemos de nuestros
            registros o bases de datos cuando considere que la misma no está
            siendo utilizada conforme a los principios, deberes y obligaciones
            previstas en la normativa (Cancelación); así como oponerse al uso de
            sus datos personales para fines específicos (Oposición). Estos
            derechos se conocen como derechos ARCO.
          </p>
          <p>
            Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
            presentar la solicitud respectiva en el siguiente correo
            electrónico:{" "}
          </p>
          <ul>
            <li>info@urvita.mx</li>
          </ul>
          <p>
            Para conocer el procedimiento y requisitos para el ejercicio de los
            derechos ARCO, usted podrá llamar al siguiente número telefónico +52
            814 160 5441 y ponerse en contacto con nuestro Departamento de
            Privacidad, que dará trámite a las solicitudes para el ejercicio de
            estos derechos, y atenderá cualquier duda que pudiera tener respecto
            al tratamiento de su información. Los datos de contacto del
            Departamento de Privacidad son los siguientes:{" "}
          </p>
          <p>
            <b>Domicilio:</b> URVITA SAPI DE CV con domicilio en JUSTO SIERRA
            180, COLONIA TAMPIQUITO, SAN PEDRO GARZA GARCIA, NUEVO LEÓN, MÉXICO
            66240
          </p>
          <p>
            <b>Correo electrónico:</b> info@urvita.mx
          </p>
        </section>
        <section>
          <h2>
            ¿Cómo puede revocar su consentimiento para el uso de sus datos
            personales?
          </h2>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que para
            ciertos fines, la revocación de su consentimiento implicará que no
            le podamos seguir prestando el servicio que nos solicitó, o la
            conclusión de su relación con nosotros.
          </p>
          <p>
            Para revocar su consentimiento deberá presentar su solicitud al
            siguiente dirección de correo electrónico:
          </p>
          <ul>
            <li>info@urvita.mx</li>
          </ul>
          <p>
            Para conocer el procedimiento y requisitos para la revocación del
            consentimiento, usted podrá ponerse en contacto con nuestro nosotros
            al número telefónico +52 814 160 5441.
          </p>
        </section>
        <section>
          <h2>
            ¿Cómo puede limitar el uso o divulgación de su información personal?
          </h2>
          <p>
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios:
          </p>
          <ul>
            <li>
              Su inscripción en el Registro Público para Evitar Publicidad, que
              está a cargo de la Procuraduría Federal del Consumidor, con la
              finalidad de que sus datos personales no sean utilizados para
              recibir publicidad o promociones de empresas de bienes o
              servicios. Para mayor información sobre este registro, usted puede
              consultar el portal de Internet de la PROFECO, o bien ponerse en
              contacto directo con ésta.
            </li>
            <li>
              Su registro en el listado de exclusión “Listado de exclusion de
              datos personales”, a fin de que sus datos personales no sean
              tratados para fines mercadotécnicos, publicitarios o de
              prospección comercial por nuestra parte. Para mayor información
              llamar al número telefónico +52 814 160 5441, o bien, enviar un
              correo electrónico a la siguiente dirección info@urvita.mx.
            </li>
          </ul>
        </section>
        <section>
          <h2>¿Cómo puede conocer los cambios a este aviso de privacidad?</h2>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de nuestras prácticas de privacidad; de cambios en
            nuestro modelo de negocio, o por otras causas.
          </p>
          <p>
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda
            sufrir el presente aviso de privacidad, a través de{" "}
            <b>correo electrónico.</b>
          </p>
          <p>
            El procedimiento a través del cual se llevarán a cabo las
            notificaciones sobre cambios o actualizaciones al presente aviso de
            privacidad es el siguiente: <b>mensaje de correo electrónico.</b>
          </p>
        </section>
      </Wrapper>
    </DefaultLayout>
  )
}

export default Privacy
