import React from "react";
import { colors } from "../../components/layout/constants";

const QuestionIcon = ({ color, className, onMouseEnter, onMouseLeave }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 512 512"
      onMouseEnter={onMouseEnter ? () => onMouseEnter() : null}
      onMouseLeave={onMouseLeave ? () => onMouseLeave() : null}
    >
      <path
        d="m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"
        fill={color ? color : colors.black}
      />
      <path
        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"
        fill={color ? color : colors.black}
      />
      <path
        d="m256 314.667969c-8.832031 0-16-7.167969-16-16v-21.546875c0-20.308594 12.886719-38.507813 32.042969-45.269532 25.492187-8.980468 42.625-36.140624 42.625-55.851562 0-32.363281-26.304688-58.667969-58.667969-58.667969s-58.667969 26.304688-58.667969 58.667969c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16c0-49.984375 40.664063-90.667969 90.667969-90.667969s90.667969 40.683594 90.667969 90.667969c0 35.585938-28.097657 73.367188-63.980469 86.039062-6.398438 2.238282-10.6875 8.316407-10.6875 15.101563v21.527344c0 8.832031-7.167969 16-16 16zm0 0"
        fill={color ? color : colors.black}
      />
    </svg>
  );
};

export default QuestionIcon;
