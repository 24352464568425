export const rates = [
  [5.07, 6.14, 11.2],
  [5.35, 6.48, 11.83],
  [5.65, 6.85, 12.5],
  [5.98, 7.25, 13.23],
  [6.34, 7.67, 14.01],
  [6.72, 8.13, 14.85],
  [7.12, 8.63, 15.75],
  [7.57, 9.16, 16.73],
  [8.04, 9.74, 17.78],
  [8.55, 10.36, 18.91],
  [9.11, 11.03, 20.14],
  [9.71, 11.75, 21.46],
  [10.35, 12.54, 22.89],
  [11.05, 13.38, 24.44],
  [11.81, 14.3, 26.11],
  [12.63, 15.29, 27.92],
  [13.51, 16.37, 29.88],
  [14.47, 17.53, 32.0],
  [15.52, 18.79, 34.3],
  [16.64, 20.16, 36.8]
]
