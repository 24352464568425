import { Modal } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import DetailButton from '../../../layout/shared/detailButton';
import { Close } from '@mui/icons-material';
import { getUserSubscription, updateSubscription } from '../../../../services/subscriptions'

export default function PauseSuscriptionModal({ handleModal, openModal }) {
  // Redux hooks
  const userId = useSelector((state) => state.user.id)
  const userToken = useSelector((state) => state.user.token)
  const [subscription, setSubscription] = useState({})

  useEffect(() => {
    const getSubscription = async () => {
      const { data: sub} = await getUserSubscription(userToken, userId)
      setSubscription(sub)
    }
    getSubscription()

  }, [userId, userToken])

  const handleModalClick = async () => {
    await updateSubscription(userToken, subscription.id, {
      subscription_active: !subscription.subscription_active
    }) 
    handleModal()
  }
  return (
    <Modal
      open={openModal}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <div onClick={handleModal} className='close'>
          <Close />
        </div>
        <h2>{subscription.subscription_active ? 'Pausar Suscripción' : 'Activas Suscripción'}</h2>

        {subscription.subscription_active ? (
          <>
            <p>
              !Lo has hecho muy bien hasta ahora!
              <span>
                Pero entendemos que a veces queremos un descanso.
              </span>
            </p>

            <div className='container-select'>
              <p>Tiempo de Pausa</p>
              <select>
                <option>1 mes</option>
                <option>2 meses</option>
                <option>3 meses</option>
                <option>4 meses</option>
                <option>Indefinido</option>
              </select>
            </div>
          </>
        ) : (
          <p>Vuelve a activar sus sucripción para seguir gananado</p>
        )}  

        <div className='container-button'>
          <DetailButton onClick={handleModalClick} text='Confirmar' />
        </div>
      </Box>
    </Modal >
  )
}


const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23rem;
  background-color: #546a5b;
  color: white;
  padding: 2rem 1rem;
  border-radius: 10px;
  text-align: center;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  
  h2 {
    font-size: 1.8rem;
  }

  p {
    margin: 0;
    font-size: 1.3rem;
    span {
      display: block;
    }
  }
  
  .container-select {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    
    select {
      cursor: pointer;
      padding: 6px 10px;
      border-radius: 20px;
      font-size: 1rem;
    }
  }

  .container-button {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    width: 30rem;
  }

`