import React, { useState, useEffect } from "react"
import styled from "styled-components"
// utils
import { colors } from "../../layout/constants"
import { getBuildingTokens } from "../../../services/tokens"

const BuildingTokens = ({ building, userToken }) => {
  const [tokens, setTokens] = useState([])
  const [availableTokens, setAvailableTokens] = useState([])
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    const getTokens = async () => {
      const buildingTokens = await getBuildingTokens(
        userToken,
        building.phase_id
      )
      setTokens(sortTokenList(buildingTokens))
      countAvailableTokens(buildingTokens)
    }

    getTokens()
  }, [building.phase_id, userToken])

  // Sort tokens by id
  const sortTokenList = (buildingTokens) => {
    return buildingTokens.sort((a, b) => {
      if (a.token_id > b.token_id) {
        return 1
      } else if (a.token_id < b.token_id) {
        return -1
      }
      return 0
    })
  }

  // Filter available tokens to create dots
  const filterTokens = (token) => {
    if (token.type === "TOKEN-B" || !token.available) {
      return <TokenB key={token.id} />
    } else {
      return <TokenA key={token.id} />
    }
  }

  // Get total not buyed tokens to dispay
  const countAvailableTokens = (buildingTokens) => {
    const count = buildingTokens.filter(
      (token) => token.type === "TOKEN-A" && token.available
    )
    setAvailableTokens(count)
  }

  return (
    <TokensContainer>
      <h4>
        m<sup>2</sup> Disponibles
      </h4>
      <div>
        <span className="tokens-count">
          {availableTokens.length} <span>/{tokens.length}</span>
        </span>

        {/* <div className="token-signals">
          <span>
            <TokenA />
            m<sup>2</sup> Disponibles
          </span>
          <span>
            <TokenB />
            m<sup>2</sup> Ocupados
          </span>
        </div>

        <div className={`tokens-dots ${showMore ? "show" : null}`}>
          {tokens.map((token) => filterTokens(token))}
  </div> */}
      </div>

      {/* {showMore ? null : (
        <button className="show-all" onClick={() => setShowMore(true)}>
          Mostrar más
        </button>
      )} */}
    </TokensContainer>
  )
}

const TokensContainer = styled.ul`
  padding: 0;
  list-style: none;

  h4 {
    margin: 1rem 0;
  }

  .tokens-count {
    font-size: 4rem;

    span {
      color: ${colors.helperGray};
    }
  }

  .tokens-dots {
    margin: 1rem 0;
    max-height: 430px;
    overflow: hidden;

    &.show {
      max-height: initial;
    }
  }

  .token-signals {
    span {
      display: flex;
      align-items: center;
    }
  }

  .show-all {
    background: none;
    border: 1px solid ${colors.secondaryColor};
    border-radius: 8px;
    color: ${colors.secondaryColor};
    cursor: pointer;
    margin: 0 auto;
    display: block;
    padding: 10px 5px;

    &:hover {
      background-color: ${colors.secondaryColor};
      color: ${colors.white};
    }
  }
`

const TokenB = styled.div`
  background-color: ${colors.helperGray};
  display: inline-block;
  border-radius: 100%;
  height: 0.8rem;
  margin: 0.2rem;
  width: 0.8rem;
`

const TokenA = styled.div`
  display: inline-block;
  background-color: ${colors.secondaryColor};
  display: inline-block;
  border-radius: 100%;
  height: 0.8rem;
  margin: 0.2rem;
  width: 0.8rem;
`

export default BuildingTokens
