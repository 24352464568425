import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function MapContainer({ google, building }) {
  const [coordates, setCoordenates] = useState("");

  useEffect(() => {
    const getCoordinated = async () => {
      try {
        const resp = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=$${building.address_street}+'%20'+${building.address_street_number}+'%20'+${building.address_city}+'%20'+${building.zip_code}&key=${process.env.REACT_APP_API_KEY_MAP}`
        );
        const response = await resp.json();
        return setCoordenates(response.results[0].geometry.location);
      } catch (err) {
        console.error(err);
      }
    };

    getCoordinated();
  }, [
    building.address_city,
    building.address_street,
    building.address_street_number,
    building.zip_code,
  ]);

  return (
    coordates && (
      <Map
        google={google}
        zoom={14}
        // style={mapStyles}
        initialCenter={coordates}
        disableDefaultUI={true}
        mapId="518b1ae4c96fbfe"
      >
        <Marker
          title={building.name}
          name={building.name}
          position={coordates}
        />
      </Map>
    )
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY_MAP,
})(MapContainer);
