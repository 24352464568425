import React from "react"
import styled from "styled-components"
// layout
import { colors } from "../constants"

const ActionButton = ({
  id,
  text,
  onClick,
  simple,
  fullWidth,
  left,
  disabled,
  helperText,
  error,
  errorMessage,
  type
}) => {
  return (
    <ButtonStyled
      simple={simple}
      fullWidth={fullWidth}
      left={left}
      disabled={disabled}
    >
      <button
        id={id}
        type={type ? type : "button"}
        onClick={(event) => (onClick ? onClick(event) : event)}
        disabled={disabled}
      >
        <span className="action-text">{text}</span>
      </button>
      {helperText ? <p className="helper-text">{helperText}</p> : null}
      {error ? <p className="error-text">{errorMessage}</p> : null}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.article`
  margin: 0.5rem;
  button {
    border-width: ${(props) => (props.simple ? 0 : "1px")};
    border-color: ${colors.secondaryColor};
    border-radius: 8px;
    border-style: solid;
    background-color: ${(props) =>
      props.simple ? "transparent" : colors.secondaryColor};
    color: ${colors.darkText};
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    font-size: ${(props) => (props.simple ? "0.8rem" : "1rem")};
    display: flex;
    justify-content: center;
    margin: 0 ${(props) => (props.left ? "auto 0 0" : "auto")};
    min-width: ${(props) => (props.fullWidth ? "100%" : "200px")};
    padding: ${(props) => (props.simple ? 0 : "0.8rem")};
    transition: all 0.4s;

    :hover {
      background-color: ${(props) =>
        props.simple ? "transparent" : colors.highlightColor};
      border-color: ${(props) =>
        props.simple ? "transparent" : colors.highlightColor};
      text-decoration: ${(props) => (props.simple ? "underline" : "none")};
    }

    :focus {
      outline: none;
    }

    :active {
      transform: translateY(1px);
    }

    :disabled {
      border-color: ${colors.disabled};
      background-color: ${colors.disabled};
    }
  }

  .action-text {
    color: ${colors.darkText};
    font-weight: 500;
  }

  .helper-text {
    margin: 0 10px;
    color: ${colors.primaryLight};
  }

  .error-text {
    margin: 5px 10px;
    color: red;
    text-align: center;
  }
`

export default ActionButton
