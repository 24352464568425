//images
import juanito from "../assets/img/buildings/juanito.jpg"
import micaela from "../assets/img/buildings/micaela.jpg"
import eva from "../assets/img/buildings/eva.jpg"
import pascual from "../assets/img/buildings/pascual.png"
import noe from "../assets/img/buildings/noe.jpg"
import victoria from "../assets/img/buildings/victoria.jpg"
import hernan from "../assets/img/buildings/hernan.jpeg"
import generic from "../assets/img/buildings/default-building.png"
import currency from "currency.js"

export const capitalName = (name) => {
  const firstLetter = name[0].toUpperCase()
  const lastLetters = name
    .split("")
    .splice(1, name.length - 1)
    .join("")
    .toLowerCase()
  return firstLetter + lastLetters
}

export const buildingImage = (nameLower) => {
  let image = ""

  switch (nameLower) {
    case "MICAELA":
      image = micaela
      break
    case "NOE ":
      image = noe
      break
    case "PASCUAL":
      image = pascual
      break
    case "VICTORIA":
      image = victoria
      break
    case "HERNAN":
      image = hernan
      break
    case "EVA":
      image = eva
      break
    case "JUANITO":
      image = juanito
      break
    default:
      image = generic
      break
  }

  return image
}

export const buildingInfo = (nameLower) => {
  let href = ""

  switch (nameLower) {
    case "MICAELA":
      href =
        "https://urvita.notion.site/Ficha-Urvita-Micaela-9ac43b38243a4723bd8ed5abae945869"
      break
    case "VICTORIA":
      href =
        "https://urvita.notion.site/Ficha-Urvita-Victoria-bb02418702c44ec7b6c1a1a4809872a7"
      break

    case "JUANITO":
      href =
        "https://urvita.notion.site/Ficha-Urvita-Juanito-42e0a817c99540728cbb6cafdd9cb3d0"
      break
    default:
      href = "#"
      break
  }

  return href
}

export const statusTranslate = (status) => {
  switch (status) {
    case "OPERATING":
      return "Operación"
    case "CONSTRUCTION":
      return "Pre Venta"
    case "PRECONSTRUCTION":
      return "Lanzamiento"
    default:
      return status
  }
}

export const formatThousandNumbers = (num) => {
  return currency(num, { separator: "," }).format()
}
