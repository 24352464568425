import React from "react"
import styled from "styled-components"

export default function MetricCard({ icon, number, description, small }) {
  return (
    <Container small={small}>
      <div className="container-icon">
        <img src={icon} alt={description} />
      </div>
      <div className="container-info">
        <p className="quantity">{number}</p>
        <span>{description}</span>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: ${(props) => (props.small ? "1rem" : "2rem")};
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 0px 8px 0px #00000024;
  border-radius: 10px;
  align-items: center;

  .container-icon {
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }

  .container-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    .quantity {
      margin: 0;
      font-size: ${(props) => (props.small ? "1.5rem" : "2rem")};
      color: #f79f4d;
      font-weight: 600;
    }

    span {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 1024px) {
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
    .container-info {
      span {
        font-size: 17px;
      }
    }
  }
`
