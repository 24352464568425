// User actions

import { dispatch } from "d3"

// Login
const setUserId = (dispatch, user_id) => {
  dispatch({
    type: "SET_USER_ID",
    payload: user_id
  })
}
// Login
const setUserToken = (dispatch, token) => {
  dispatch({
    type: "SET_USER_TOKEN",
    payload: token
  })
}

// ------------

const setUserRfc = (dispatch, user_rfc) => {
  dispatch({
    type: "SET_USER_RFC",
    payload: user_rfc
  })
}

const setUserStep = (dispatch, step) => {
  dispatch({
    type: "SET_USER_STEP",
    payload: step
  })
}

const setReport = (dispatch, report) => {
  dispatch({
    type: "SET_USER_REPORT",
    payload: report
  })
}

const setSimulationState = (dispatch, simulationValues) => {
  dispatch({
    type: "SET_SIMULATION",
    payload: simulationValues
  })
}

const setSimulationTime = (dispatch, simulationYear) => {
  dispatch({
    type: "SET_SIMULATION_YEAR",
    payload: simulationYear
  })
}

const setOrder = (dispatch, order) => {
  dispatch({
    type: "SET_ORDER",
    payload: order
  })
}

const setUserData = (dispatch, userData) => {
  dispatch({
    type: "SET_USER_DATA",
    payload: userData
  })
}

const setAddToCart = (dispatch, orders) => {
  dispatch({
    type: "SET_ADD_TO_CART",
    payload: orders
  })
}

export {
  setUserId,
  setUserRfc,
  setReport,
  setUserStep,
  setUserToken,
  setSimulationState,
  setSimulationTime,
  setOrder,
  setUserData,
  setAddToCart
}
