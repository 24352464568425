export async function getUSDRate(userToken) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/currencylayer`,
      {
        method: "GET",
        headers: myHeaders
      }
    )

    const resp = await response.json()
    return resp.USDMXN
  } catch (error) {
    console.error(error)
    return error
  }
}
