async function getBuildings(userToken) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/building_phases`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    );
    const resp = await response.json();
    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

async function getPeriodValues(userToken, period) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/token_values/period/${period}`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    );
    const resp = await response.json();
    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

export { getBuildings, getPeriodValues };
