import React from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
// utils
import { statusTranslate } from "../../../utils/format"
// assets
import ProfileIcon from "../../../assets/iconography/Profile.svg"
import SettingsIcon from "../../../assets/iconography/Settings.svg"
import HomeIcon from "../../../assets/iconography/Home.svg"
import CardIcon from "../../../assets/iconography/Card.svg"
import Tooltip from "../../layout/shared/tooltip"

const BuildingInfo = ({ building, values, getYearAverage }) => {
  return (
    <InfoList>
      <InfoRow>
        <img className="icon" src={SettingsIcon} alt="profile icon" />
        <div className="row-content">
          <span>Estatus de edificio</span>
          <span className="token-status">
            {statusTranslate(building.phase_status)}
          </span>
        </div>
      </InfoRow>
      {building.phase_status === "OPERATING" ? (
        <InfoRow>
          <img className="icon" src={ProfileIcon} alt="profile icon" />
          <div className="row-content">
            <span>
              Ocupación promedio{" "}
              <Tooltip
                dark
                content="La ocupación promedio es es el porcentaje de noches ocupadas que ha tenido el edificio por mes en los últimos 12 meses."
              />
            </span>
            <span className="green">
              <NumberFormat
                className="green"
                value={Number(getYearAverage().averageOccupancy)}
                displayType={"text"}
                suffix="%"
                thousandSeparator={true}
              />
            </span>
          </div>
        </InfoRow>
      ) : null}
      <InfoRow>
        <img className="icon" src={HomeIcon} alt="profile icon" />
        <div className="row-content">
          <span>
            Valor actual x m2{" "}
            <Tooltip
              dark
              content="Valor estimado en base a rentas históricas del edificio."
            />
          </span>

          <NumberFormat
            className="green"
            value={Number(values?.value_a).toFixed(2)}
            displayType={"text"}
            prefix="$"
            thousandSeparator={true}
          />
        </div>
      </InfoRow>
      {building.phase_status === "OPERATING" ? (
        <InfoRow>
          <img className="icon" src={CardIcon} alt="profile icon" />
          <div className="row-content">
            <span>
              Ganancias mens. x m2 promedio{" "}
              <Tooltip
                dark
                content="Promedio de las ganancias mensuales generadas por renta de las unidades del edificio correspondiente a cada token de los últimos 12 meses."
              />
            </span>
            <NumberFormat
              className="green"
              value={Number(getYearAverage().averageNoi).toFixed(2)}
              displayType={"text"}
              prefix="$"
              thousandSeparator={true}
            />
          </div>
        </InfoRow>
      ) : null}
      {/* <InfoRow>
        <img className="icon" src={ChartIcon} alt="profile icon" />
        <div className="row-content">
          <span>Rendimiento anual</span>
          <NumberFormat
            className="green"
            value={Number(values?.period_scale).toFixed(2) * 100}
            displayType={"text"}
            suffix="%"
            thousandSeparator={true}
          />
        </div>
      </InfoRow> */}
      {/* <InfoRow>
        <img className="icon" src={HomeIcon} alt="profile icon" />
        <div className="row-content">
          <span>Ranking 1º - Edificio con mayor rendimiento</span>
          <span></span>
        </div>
      </InfoRow> */}
    </InfoList>
  )
}

const InfoList = styled.ul`
  padding: 0;
  list-style: none;
`

const InfoRow = styled.li`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 1rem 0;

  .row-content {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    position: relative;
    vertical-align: middle;
    margin: 0 0.5rem 0 0;
    width: 1rem;
  }

  .token-status {
    background-color: #e9f6fc;
    border-radius: 16px;
    color: #1775a2;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 4px 10px;
  }

  .green {
    font-weight: 500;
  }

  .icon {
    width: 15px;
  }
`

export default BuildingInfo
