import React, { useRef, useEffect, useState } from "react"
import {
  select,
  line,
  scaleLinear,
  transition,
  easeSin,
  axisBottom,
  axisRight,
  scaleTime
} from "d3"
import moment from "moment"
import styled from "styled-components"
import { useSelector } from "react-redux"
// layout
import { colors } from "../../layout/constants"
import Button from "../../styled/forms/button"

const TokenGraph = ({ records, balance }) => {
  const svgRef = useRef()
  const simulationTime = useSelector((state) => state.simulationYear)

  const [formattedValues, setFormattedValues] = useState([])
  const [filteredValues, setFilteredValues] = useState([])
  const [filterRange, setFilterRange] = useState("all")

  useEffect(() => {
    const joinBuildingsTotalValue = (list) => {
      const initialValue = [
        {
          period: list[0].period,
          tokenValue: 0
        }
      ]

      const joinedValues = list?.reduce((result, current) => {
        let hasPeriod = false
        result.forEach((item, index) => {
          // Add building value to existing period
          if (item.period === current.period) {
            result[index].tokenValue += current.totalValue
            hasPeriod = true
          }
        })

        // Add new period
        if (!hasPeriod) {
          result.push({
            period: current.period,
            tokenValue: current.totalValue
          })
        }

        return result
      }, initialValue)

      return joinedValues.map((value, index) => {
        const utcPeriod = moment(value.period).utc()

        // Add Balance if current month value
        const totalValue =
          index === joinedValues.length - 1
            ? Number(value.tokenValue)
            : value.tokenValue

        return {
          period: new Date(utcPeriod),
          tokenValue: value.tokenValue ? totalValue : balance
        }
      })
    }
    setFormattedValues(joinBuildingsTotalValue(records))
  }, [balance, records, simulationTime])

  useEffect(() => {
    if (formattedValues[0]) {
      setFilteredValues(filterByTime(filterRange, formattedValues))
    }
  }, [filterRange, formattedValues])

  useEffect(() => {
    const graphWidth = svgRef.current.clientWidth
    const graphHeight = svgRef.current.clientHeight

    const generateGraph = (graphValues, graphWidth, graphHeight) => {
      // set date scale
      const startDate = graphValues[0] ? graphValues[0].period : new Date()
      const endDate = graphValues.length
        ? graphValues[graphValues.length - 1].period
        : new Date()
      // Set money sacales
      const startValue = graphValues[0] ? graphValues[0].tokenValue : 0
      const endValue = graphValues.length
        ? graphValues[graphValues.length - 1].tokenValue
        : 100000

      const xScale = scaleTime()
        .domain([startDate, endDate])
        .range([0, graphWidth])

      const yScale = scaleLinear()
        .domain([startValue, endValue])
        .range([graphHeight, 0])

      // Create graph space
      const svg = select(svgRef.current)

      // Remove old values
      svg.selectAll("line").remove()
      svg.select("path#line").remove()
      svg.selectAll("circle").remove()

      // Create line
      const lineValues = line()
        .x((value, index) => xScale(value.period))
        .y((value) => yScale(value.tokenValue))

      //Paint graph Line
      svg
        .selectAll("line")
        .data([graphValues])
        .join("path")
        .attr("id", "line")
        .attr("d", (value) => lineValues(value))
        .attr("fill", "none")
        .attr("stroke", colors.secondaryColor)
        .attr("stroke-width", 3)

      // Paint graph circles
      svg
        .selectAll("circle")
        .data(graphValues)
        .enter()
        .append("circle")
        .attr("fill", colors.highlightColor)
        .attr("stroke", "none")
        .attr("cx", (value, index) => xScale(value.period))
        .attr("cy", (value) => yScale(value.tokenValue))
        .attr("r", (value, index) => (simulationTime === index ? 6 : 3))
        .on("mouseover", (value, index, circles) => {
          showTolltip(index, value, circles)
        })
        .on("mouseleave", (value, index, circles) => {
          tooltip.classed("active", false)

          select(circles[index]).attr("r", 3)
        })

      // Create tooltip
      const tooltip = svg
        .append("foreignObject")
        .attr("width", 150)
        .attr("height", 60)
        .classed("tooltip", true)
      const showTolltip = (index, value, circles) => {
        tooltip
          .attr("x", () =>
            xScale(value.period) + 10 >= graphWidth - 150
              ? xScale(value.period) - 160 + "px"
              : xScale(value.period) + 10 + "px"
          )
          .attr("y", () =>
            yScale(value.tokenValue) >= graphHeight - 50
              ? yScale(value.tokenValue) - 50 + "px"
              : yScale(value.tokenValue) + "px"
          )
          .html(
            `<div>
            <span class="tooltip-row tooltip-date">
              ${dateFormat(value.period)}
            </span>
            <span class="tooltip-row tooltip-value">
              $ ${formatMoney(value.tokenValue)}
            <span>
          </div>
            `
          )
          .classed("active", true)

        select(circles[index]).attr("r", 7)
      }
      appendAxis(graphWidth, graphHeight, xScale, yScale)
    }

    if (filteredValues.length > 0) {
      generateGraph(filteredValues, graphWidth, graphHeight)
      animateGraph()
    }
  }, [filteredValues, simulationTime])

  const filterByTime = (filter, values) => {
    if (filter === "all") {
      return values
    } else {
      return values.slice(Math.max(values.length - filter, 0))
    }
  }

  const appendAxis = (graphWidth, graphHeight, xScale, yScale) => {
    const svg = select(svgRef.current)
    // Axis
    // Remove old axis
    svg.selectAll("g#x-axis").remove()
    svg.selectAll("g#y-axis").remove()
    // Create axis
    svg
      .append("g")
      .attr("id", "x-axis")
      .attr("transform", `translate(0, ${graphHeight + 20})`) // This controls the vertical position of the Axis
      .call(axisBottom(xScale).ticks(2, "%B-%Y"))

    svg
      .append("g")
      .classed("axis-y", true)
      .attr("id", "y-axis")
      .attr("transform", `translate(-20, 0)`) // This controls the vertical position of the Axis
      .call(axisRight(yScale).ticks(5, `.2s`))
  }

  const animateGraph = () => {
    const svg = select(svgRef.current)
    // Stoke animation
    const pathLength = svg.select("path#line").node().getTotalLength()

    const transitionPath = transition().ease(easeSin).duration(1000).delay(300)

    svg
      .select("path#line")
      .attr("stroke-dashoffset", pathLength)
      .attr("stroke-dasharray", pathLength)
      .transition(transitionPath)
      .attr("stroke-dashoffset", 0)
  }

  const formatMoney = (
    amount,
    decimalCount = 0,
    decimal = ".",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? "-" : ""

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      )
    } catch (e) {
      console.error(e)
    }
  }

  const dateFormat = (period) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ]
    const monthNumber = moment(period).format("M")
    const yearNumber = moment(period).format("YYYY")
    return months[monthNumber - 1] + " " + yearNumber
  }

  return (
    <TokenGraphStyles>
      <svg ref={svgRef}></svg>

      <div className="cta-container">
        <Button
          text="6 meses"
          active={filterRange === 6 ? true : false}
          square
          textColor={colors.black}
          activeColor={colors.secondaryColor}
          secondaryColor={colors.secondaryColor}
          onClick={() => setFilterRange(6)}
        />
        <Button
          text="1 año"
          active={filterRange === 12 ? true : false}
          square
          textColor={colors.black}
          activeColor={colors.secondaryColor}
          secondaryColor={colors.secondaryColor}
          onClick={() => setFilterRange(12)}
        />
        <Button
          text="Histórico"
          active={filterRange === "all" ? true : false}
          square
          textColor={colors.black}
          activeColor={colors.secondaryColor}
          secondaryColor={colors.secondaryColor}
          onClick={() => setFilterRange("all")}
        />
      </div>
    </TokenGraphStyles>
  )
}

const TokenGraphStyles = styled.section`
  padding: 20px 0;

  svg {
    height: 250px;
    overflow: visible;
    width: 100%;

    circle {
      cursor: pointer;
      transition: all 0.4s;
    }
  }

  .axis-y {
    font-family: calibre;
    font-size: 1em;
  }

  .cta-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    padding: 10px 0 0 0;
    margin: 2rem 0;
  }

  .tooltip {
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    div {
      align-items: center;
      background-color: ${colors.secondaryColor};
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      height: 60px;
      justify-content: center;
      position: absolute;
      transition: opacity 0.4s, visibility 0.4s;
      width: 150px;
    }
  }

  .tooltip-row {
    display: block;
    font-size: 16px;
    text-align: center;
    span {
      font-weight: 500;
    }
  }

  .tooltip-value {
    font-size: 20px;
  }

  @media (max-width: 968px) {
    .cta-container {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;

      div:last-child {
        grid-column: 1/3;
      }
    }
  }
`

export default TokenGraph
