async function getUser(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    )
    const resp = await response.json()
    resp.data.hash = resp.user_hash
    return resp.data
  } catch (err) {
    console.error(err)
  }
}

async function login({ email, password }) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  const user = {
    email,
    password,
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/login`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(user),
      }
    )
    const resp = await response.json()
    return resp.error ? resp : resp.data
  } catch (err) {
    console.error(err)
  }
}

async function verifyUserToken(token) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/verify/${token}`,
    {
      method: "GET",
      headers: myHeaders,
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function createUser(newData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/signup`,
    {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(newData),
    }
  )

  const data = await response.json()
  return data
}

async function editUser(userToken, userId, newData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
    {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(newData),
    }
  )

  const data = await response.json()
  return data
}

async function validateMail(userId) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/validate/${userId}`,
    {
      method: "PUT",
      headers: myHeaders,
    }
  )

  const data = await response.json()
  return data
}

const generateWeetrustContract = async (userToken, userId) => {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/weetrust/contract`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({ userId }),
      }
    )
    const resp = await response.json()
    return resp.error ? resp : resp.data
  } catch (err) {
    console.error(err)
  }
}

export {
  getUser,
  login,
  verifyUserToken,
  createUser,
  editUser,
  validateMail,
  generateWeetrustContract,
}
